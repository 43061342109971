import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-embedded-icon',
	template: `
		<ng-template [ngIf]="!active">
			<svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g id="icon/embedded" clip-path="url(#clip0_5867_46672)">
					<path
						id="Vector"
						d="M12.6663 3.33325L14.6663 1.33325M1.33301 14.6666L3.33301 12.6666M4.99967 8.99992L6.66634 7.33325M6.99967 10.9999L8.66634 9.33325M4.19967 13.5333C4.34832 13.6824 4.52495 13.8008 4.71943 13.8815C4.91391 13.9623 5.12243 14.0038 5.33301 14.0038C5.54359 14.0038 5.7521 13.9623 5.94658 13.8815C6.14107 13.8008 6.3177 13.6824 6.46634 13.5333L7.99967 11.9999L3.99967 7.99992L2.46634 9.53325C2.31718 9.6819 2.19883 9.85853 2.11807 10.053C2.03732 10.2475 1.99575 10.456 1.99575 10.6666C1.99575 10.8772 2.03732 11.0857 2.11807 11.2802C2.19883 11.4746 2.31718 11.6513 2.46634 11.7999L4.19967 13.5333ZM7.99967 3.99992L11.9997 7.99992L13.533 6.46658C13.6822 6.31794 13.8005 6.14131 13.8813 5.94683C13.962 5.75235 14.0036 5.54383 14.0036 5.33325C14.0036 5.12267 13.962 4.91416 13.8813 4.71967C13.8005 4.52519 13.6822 4.34856 13.533 4.19992L11.7997 2.46659C11.651 2.31742 11.4744 2.19907 11.2799 2.11832C11.0854 2.03756 10.8769 1.99599 10.6663 1.99599C10.4558 1.99599 10.2472 2.03756 10.0528 2.11832C9.85828 2.19907 9.68165 2.31742 9.53301 2.46659L7.99967 3.99992Z"
						stroke="#CECFD3"
						stroke-width="1.2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</g>
				<defs>
					<clipPath id="clip0_5867_46672">
						<rect width="18" height="18" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</ng-template>
		<ng-template [ngIf]="active">
			<svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g id="icon/embedded-hover" clip-path="url(#clip0_5867_46618)">
					<path
						id="Vector"
						d="M12.6663 3.33325L14.6663 1.33325M1.33301 14.6666L3.33301 12.6666M4.99967 8.99992L6.66634 7.33325M6.99967 10.9999L8.66634 9.33325M4.19967 13.5333C4.34832 13.6824 4.52495 13.8008 4.71943 13.8815C4.91391 13.9623 5.12243 14.0038 5.33301 14.0038C5.54359 14.0038 5.7521 13.9623 5.94658 13.8815C6.14107 13.8008 6.3177 13.6824 6.46634 13.5333L7.99967 11.9999L3.99967 7.99992L2.46634 9.53325C2.31718 9.6819 2.19883 9.85853 2.11807 10.053C2.03732 10.2475 1.99575 10.456 1.99575 10.6666C1.99575 10.8772 2.03732 11.0857 2.11807 11.2802C2.19883 11.4746 2.31718 11.6513 2.46634 11.7999L4.19967 13.5333ZM7.99967 3.99992L11.9997 7.99992L13.533 6.46658C13.6822 6.31794 13.8005 6.14131 13.8813 5.94683C13.962 5.75235 14.0036 5.54383 14.0036 5.33325C14.0036 5.12267 13.962 4.91416 13.8813 4.71967C13.8005 4.52519 13.6822 4.34856 13.533 4.19992L11.7997 2.46659C11.651 2.31742 11.4744 2.19907 11.2799 2.11832C11.0854 2.03756 10.8769 1.99599 10.6663 1.99599C10.4558 1.99599 10.2472 2.03756 10.0528 2.11832C9.85828 2.19907 9.68165 2.31742 9.53301 2.46659L7.99967 3.99992Z"
						stroke="#1B54F8"
						stroke-width="1.2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</g>
				<defs>
					<clipPath id="clip0_5867_46618">
						<rect width="18" height="18" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</ng-template>
	`,
	styles: [],
})
export class EmbeddedIconComponent {
	@Input('active') active: boolean = false;
}
