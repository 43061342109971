import { Component, OnInit } from '@angular/core';
import { ConfirmationService, ConfirmEventType, MenuItem, MessageService } from 'primeng/api';
import { E2ETaxonomyService } from '../../service/e2e-taxonomy.service';
import { UseCaseService } from '../../service/use-case.service';
import { ActivatedRoute } from '@angular/router';
import { LayoutService } from '../../../layout/service/app.layout.service';
import { AuthService } from '../../service/auth.service';
import { DbService } from '../../service/db.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DiagramComponent } from '../diagram/diagram.component';
import { AIOpportunity, Poc, PocInput } from './types';
import { DesignNexusService } from './design-nexus.service';
import { DesignNexusModalComponent } from './components/modal/design-nexus-modal.component';
import { Menu } from 'primeng/menu';

@Component({
	templateUrl: './design-nexus.component.html',
	styleUrls: ['./design-nexus.component.scss'],
	providers: [ConfirmationService, MessageService],
})
export class DesignNexusComponent implements OnInit {
	constructor(
		public confirmationService: ConfirmationService,
		public messageService: MessageService,
		public dialogService: DialogService,
		public layoutService: LayoutService,
		public authService: AuthService,
		public e2ETaxonomyService: E2ETaxonomyService,
		public useCaseService: UseCaseService,
		public route: ActivatedRoute,
		public dbService: DbService,
		public service: DesignNexusService,
	) {}

	loading: boolean = true;

	async ngOnInit() {
		this.layoutService.updateTitle('Hubble Design');

		await this.getData();

		this.loading = false;

		this.authService.organizationSource$.subscribe(() => {
			setTimeout(() => {
				this.getData();
			}, 150);
		});

		this.dbService.dbOrganizationUpdateSource$.subscribe(() => {
			setTimeout(() => {
				this.getData();
			}, 150);
		});
	}

	openModal(rowData: any): DynamicDialogRef {
		const useCase = this.service.useCases.find((u) => u.id === rowData.useCaseId);
		const opportunity = useCase?.opportunities.find((op) => op.id === rowData.opportunityId);
		const pocInput: PocInput = {
			...(useCase?.opportunities.find((op) => op.id === rowData.opportunityId)?.poc as Poc),
			useCaseId: rowData.useCaseId,
			aiOpportunityId: rowData.opportunityId,
		};
		return this.dialogService.open(DesignNexusModalComponent, {
			data: { pocInput, opportunity },
			header: pocInput.title || 'Use Case',
			width: '95%',
			height: '100%',
			contentStyle: { overflow: 'auto' },
			showHeader: true,
			baseZIndex: 1,
			maximizable: false,
			closeOnEscape: false,
			styleClass: 'design-nexus-modal',
		});
	}

	loading_data: boolean = false;

	async getData() {
		this.loading_data = true;

		await this.service.onLoad();

		this.loading_data = false;
	}

	async openDiagram(useCaseId: string, diagramId: string): Promise<DynamicDialogRef> {
		document.body.style.cursor = 'wait';
		await this.useCaseService.setCurrentUseCase(useCaseId);
		const ref: DynamicDialogRef = this.dialogService.open(DiagramComponent, {
			data: { diagramId },
			header: 'Diagram',
			width: '100%',
			height: '100%',
			styleClass: 'diagram-modal',
			contentStyle: { overflow: 'auto' },
			showHeader: false,
			baseZIndex: 100,
			maximizable: false,
			closeOnEscape: false,
		});
		setTimeout(() => {
			document.body.style.cursor = 'default';
		}, 150);

		ref.onClose.subscribe(() => {
			this.service.getData();
		});
		return ref;
	}

	currentRowData:
		| {
				pocId: string;
				useCaseId: string;
				opportunityId: string;
				status: 'complete' | 'in-progress' | 'not-started';
				result: 'pass' | 'fail';
		  }
		| undefined = undefined;

	openMenuSR(
		evt: any,
		menu: Menu,
		rowData: {
			pocId: string;
			useCaseId: string;
			opportunityId: string;
			status: 'complete' | 'in-progress' | 'not-started';
			result: 'pass' | 'fail';
		},
	) {
		this.currentRowData = rowData;
		if (menu.visible) {
			menu.hide();
		} else {
			menu.show(evt);
		}
	}

	updateRowData(updateData: { status?: 'complete' | 'in-progress' | 'not-started'; result?: 'pass' | 'fail' }) {
		const useCaseNode = this.service.data.find((useCaseNode) => useCaseNode.key === this.currentRowData?.useCaseId);
		if (useCaseNode && useCaseNode.children) {
			const opportunityNode = useCaseNode.children.find(
				(opportunityNode) => opportunityNode.key === this.currentRowData?.opportunityId,
			);

			if (opportunityNode) {
				opportunityNode.data = { ...opportunityNode.data, ...updateData };
			}
		}
	}

	updatePoc(updateData: { status?: 'complete' | 'in-progress' | 'not-started'; result?: 'pass' | 'fail' }) {
		const useCase = this.service.useCases.find((useCase) => useCase.id === this.currentRowData?.useCaseId);

		if (useCase) {
			const opportunity = useCase.opportunities.find(
				(opportunity) => opportunity.id === this.currentRowData?.opportunityId,
			);

			if (opportunity && opportunity.poc) {
				opportunity.poc.generalInformation = { ...opportunity.poc.generalInformation, ...updateData };

				this.service
					.savePoc({
						...opportunity.poc,
						useCaseId: useCase.id,
						aiOpportunityId: opportunity.id,
					})
					.then(() => {
						this.updateRowData(updateData);
					});
			}
		}
	}

	getLabelSR(
		rowData: {
			pocId: string;
			useCaseId: string;
			opportunityId: string;
			status: 'complete' | 'in-progress' | 'not-started';
			result: 'pass' | 'fail';
		},
		label: 'status' | 'result',
	) {
		if (label === 'status') {
			if (rowData.status === 'complete') {
				return 'Complete';
			} else if (rowData.status === 'in-progress') {
				return 'In Progress';
			} else if (rowData.status === 'not-started') {
				return 'Not Started';
			}
		} else {
			if (rowData.result === 'pass') {
				return 'Pass';
			} else if (rowData.result === 'fail') {
				return 'Fail';
			}
		}
		return '---';
	}

	menuStatus: MenuItem[] = [
		{
			label: 'Complete',
			command: () => {
				this.updateStatus('complete');
			},
		},
		{
			label: 'In Progress',
			command: () => {
				this.updateStatus('in-progress');
			},
		},
		{
			label: 'Not Started',
			command: () => {
				this.updateStatus('not-started');
			},
		},
	];

	updateStatus(status: 'complete' | 'in-progress' | 'not-started') {
		if (this.currentRowData && this.currentRowData.status !== status) {
			this.confirmationService.confirm({
				header: 'Update status',
				message: 'Are you sure you want to change the status?',
				accept: () => {
					this.updatePoc({ status });
				},
			});
		}
	}

	menuResult: MenuItem[] = [
		{
			label: 'Pass',
			command: () => {
				this.updateResult('pass');
			},
		},
		{
			label: 'Fail',
			command: () => {
				this.updateResult('fail');
			},
		},
	];

	updateResult(result: 'pass' | 'fail') {
		if (this.currentRowData && this.currentRowData.result !== result) {
			this.confirmationService.confirm({
				header: 'Update result',
				message: 'Are you sure you want to change the result?',
				accept: () => {
					this.updatePoc({ result });
				},
			});
		}
	}
}
