import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
	selector: 'app-loading',
	standalone: true,
	imports: [CommonModule, ProgressSpinnerModule],
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.css'],
})
export class LoadingComponent {
	constructor() {}
}
