<div class="layout-topbar">
	<div class="grid">
		<div
			class="col-3 layout-topbar-logo mobile lg:hidden xl:hidden flex justify-content-start gap-1 lg:gap-5 xl:gap-5 shadow"
		>
			<a routerLink="/">
				<img src="assets/icons/ISO_aiExplorer_color.svg " alt="logo" />
			</a>
		</div>

		<div
			class="col layout-topbar-title flex justify-content-center lg:justify-content-start xl:justify-content-start gap-1 lg:gap-5 xl:gap-5"
		>
			<a routerLink="/">
				<img
					src="assets/icons/ISO_aiExplorer_color.svg "
					alt="logo"
					class="hidden hidden lg:block xl:block shadow"
				/>
			</a>
			<span class="text-white" style="font-size: 20px">{{ title }}</span>
		</div>

		<div class="col-3 layout-topbar-menu justify-content-end align-items-center">
			<p-button
			  class="p-button-text ml-1 no-background"
			  icon="pi pi-building"
			  (click)="simulationService.enterpriseContext ? openEnterpriseContext() : null"
			  [loading]="!this.simulationService.enterpriseContext"
			  [disabled]="!this.simulationService.enterpriseContext"
			></p-button>
			<p-dropdown
				*ngIf="!loadOrganization"
				[options]="organizationItems"
				[(ngModel)]="organizationSelected"
				optionLabel="name"
				placeholder="Select an organization"
				class="dropdown-organization"
				[filter]="organizationItems.length > 10"
				filterBy="name"
				(onChange)="onChangeOrganization()"
				appendTo="body"
			>
				<ng-template pTemplate="selectedItem">
					<div
						class="flex justify-content-between align-items-center align-content-center gap-2"
						*ngIf="organizationSelected"
					>
						<span class="pi pi-globe align-self-end"></span>
						<div>{{ organizationSelected.name }}</div>
					</div>
				</ng-template>
			</p-dropdown>
			<button class="p-link layout-topbar-button" (click)="op?.toggle($event)">
				<i class="pi pi-user"></i>
			</button>
			<button
				#menubutton
				class="p-link layout-topbar-button lg:hidden xl:hidden"
				(click)="layoutService.onMenuToggle()"
			>
				<i class="pi pi-bars"></i>
			</button>
		</div>
	</div>
</div>

<p-toast position="top-center"></p-toast>
<p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>

<p-overlayPanel #op styleClass="menu-topbar">
	<p-menu [model]="menuItems1" styleClass="pb-0"></p-menu>

	<div class="p-menu p-component py-0">
		<ul role="menu" class="p-menu-list p-reset">
			<li role="none" class="p-element p-menuitem">
				<a
					role="menuitem"
					class="p-menuitem-link flex justify-content-between align-items-center"
					target="undefined"
					tabindex="0"
				>
				<label for="showAlerts" class="p-menuitem-text ng-star-inserted">Show alerts</label>
				<p-inputSwitch
				  class="change-org-switch"
				  inputId="showAlerts"
				  [(ngModel)]="this.layoutService.warnings"
				></p-inputSwitch>
				</a>
			</li>
		</ul>
	</div>

	<p-menu [model]="menuItems2" styleClass="pt-0"></p-menu>
</p-overlayPanel>

<p-dialog header="Video" [(visible)]="visible_video" [modal]="true" [draggable]="false" [style]="{ width: '50vw' }">
	<p>
		Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
		magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
		consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
		pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
		laborum.
	</p>
</p-dialog>
