<p-toast></p-toast>
<p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>

<div class="card card-content-simulation">
	<div class="flex flex-column w-full gap-5">
		<div class="flex flex-wrap justify-content-start align-content-start grid-main overflow-auto">
			<div class="flex flex-wrap justify-content-start align-content-start sticky-header w-full">
				<div class="col-12 col-header p-main">
					<div class="flex justify-content-between gap-3 align-items-center">
						<p-tabView [(activeIndex)]="selectionView">
							<p-tabPanel>
								<div class="flex justify-content-start align-items-center gap-3 w-full">
									<span class="text-lg m-0"> Dashboard </span>
									<p-multiSelect
										#filterFunction
										class="min-w-20rem"
										styleClass="w-full"
										[(ngModel)]="selectedTaxonomy"
										[options]="useCasesService.functionsTree"
										display="chip"
										placeholder="Select Functions"
										appendTo="body"
										[filter]="true"
										(onPanelHide)="updateSelectedFilters()"
										(onChange)="updateSelectedFilters(false)"
									></p-multiSelect>
								</div>
							</p-tabPanel>
							<p-tabPanel>
								<p class="text-lg m-0">M1 Preparation</p>
							</p-tabPanel>
							<p-tabPanel>
								<div class="flex flex-wrap justify-content-start gap-2">
									<p class="text-lg m-0">
										<span>Use Cases</span>
										<p-badge
											[value]="
												(simulationService.filteredUseCases.length
													? simulationService.filteredUseCases.length
													: simulationService.counters.aiUseCases
												).toString()
											"
											class="ml-2"
										>
										</p-badge>
									</p>
									<p-toggleButton
										onIcon="pi pi-angle-down"
										offIcon="pi pi-angle-up"
										[style]="{ width: '2rem' }"
										styleClass="button-filter"
										(onChange)="useCaseFilter = useCaseFilter ? 0 : -1"
									></p-toggleButton>
								</div>
							</p-tabPanel>
							<p-tabPanel>
								<p class="text-lg m-0">Prompt Studio</p>
							</p-tabPanel>
						</p-tabView>

						<div class="flex justify-content-end gap-3 align-items-center">
							<p-tabView [(activeIndex)]="selectionView">
								<p-tabPanel>
									<div class="flex justify-content-end gap-3 align-items-center">
										<div class="button-container">
											<p-button
												icon="pi pi-file-export"
												styleClass="p-button-text ml-3"
											></p-button>
											<div *ngIf="selectedFilterCount > 0" class="custom-badge">
												{{ selectedFilterCount }}
											</div>
										</div>
										<!-- <p-button
											[styleClass]="'button-header' + (filterIndex === 2 ? ' active' : '')"
											icon="pi pi-filter"
											(onClick)="changeFilter(2)"
										></p-button> -->
										<!-- Hide Filter -->
										<!--										<p-button

											label="Add simulation"
											icon="pi pi-plus-circle"
											[loading]="!simulationService.addProcessLoaded"
											(onClick)="addProcessDiagram()"
										></p-button>
										-->
									</div>
								</p-tabPanel>
								<p-tabPanel>
									<!-- <div class="flex justify-content-end gap-3 align-items-center">
										<p-button
											label="Open M1 Prep"
											icon="pi pi-plus-circle"
											(onClick)="openM1Prep()"
										></p-button>
									</div> -->
									<div class="flex justify-content-end gap-3 align-items-center">
										<p-button
											label="Open M1 Prep"
											icon="pi pi-plus-circle"
											(onClick)="openM1Prep()"
											[disabled]="!simulationService.loaded && !this.useCasesService.init"
											loadingIcon="pi pi-spin pi-spinner"
											[loading]="!simulationService.loaded && !this.useCasesService.init"
										></p-button>
									</div>
								</p-tabPanel>
								<p-tabPanel></p-tabPanel>
								<p-tabPanel>
									<div class="flex justify-content-end gap-3 align-items-center">
										<p-button
											label="Open Simulation"
											icon="pi pi-plus-circle"
											(onClick)="openSimulation()"
											[disabled]="!simulationService.loaded && !this.useCasesService.init"
											loadingIcon="pi pi-spin pi-spinner"
											[loading]="!simulationService.loaded && !this.useCasesService.init"
										></p-button>
									</div>
								</p-tabPanel>
							</p-tabView>

							<p-selectButton
								[options]="selectionViewOptions"
								[(ngModel)]="selectionView"
								styleClass="filter-view"
								(onChange)="changeSelectionView()"
							>
								<ng-template let-item>
									<ng-template [ngIf]="item.value === 0">
										<div class="flex justify-content-center align-items-center gap-3">
											<span class="pi pi-home"></span>
										</div>
									</ng-template>
									<ng-template [ngIf]="item.value === 1">
										<div class="flex justify-content-center align-items-center gap-3">
											<span>M1 Prep</span>
										</div>
									</ng-template>
									<ng-template [ngIf]="item.value === 2">
										<div class="flex justify-content-center align-items-center gap-3">
											<span>Use Case Library</span>
										</div>
									</ng-template>
									<ng-template [ngIf]="item.value === 3">
										<div class="flex justify-content-center align-items-center gap-3">
											<span>Prompt Studio</span>
										</div>
									</ng-template>
								</ng-template>
							</p-selectButton>
						</div>
					</div>
				</div>
				<div *ngIf="filterIndex === 2" class="col-12 col-filter">
					<div class="grid p-main">
						<div class="col-4">
							<div class="flex justify-content-start align-items-center gap-3">
								<label for="aiEnablers" class="text-primary font-bold uppercase label-filter"
									>AI Enablers</label
								>
								<p-multiSelect
									class="element-filter"
									styleClass="filter-content min-token"
									[(ngModel)]="aiEnablersTreeSelections"
									[options]="aiEnablersTreeOptions"
									display="chip"
									id="aiEnablers"
									inputId="aiEnablers"
									placeholder="Select AI Enablers"
									appendTo="body"
									[filter]="false"
									[showHeader]="false"
								></p-multiSelect>
							</div>
						</div>
						<div class="col-4">
							<div class="flex justify-content-start align-items-center gap-3">
								<label for="scope" class="text-primary font-bold uppercase label-filter">Scope</label>
								<p-multiSelect
									class="element-filter"
									styleClass="filter-content min-token"
									[(ngModel)]="scopeTreeSelections"
									[options]="scopeTreeOptions"
									display="chip"
									id="scope"
									inputId="scope"
									placeholder="Select scope"
									appendTo="body"
									[filter]="false"
									[showHeader]="false"
								></p-multiSelect>
							</div>
						</div>
						<div class="col-4">
							<div class="flex justify-content-start align-items-center gap-3">
								<label for="impact" class="text-primary font-bold uppercase label-filter">Impact</label>
								<p-multiSelect
									class="element-filter"
									styleClass="filter-content min-token"
									[(ngModel)]="impactTreeSelections"
									[options]="impactTreeOptions"
									display="chip"
									id="impact"
									inputId="impact"
									placeholder="Select impact"
									appendTo="body"
									[filter]="false"
									[showHeader]="false"
								></p-multiSelect>
							</div>
						</div>
						<div class="col-4">
							<div class="flex justify-content-start align-items-center gap-3">
								<label for="technology" class="text-primary font-bold uppercase label-filter"
									>Technology</label
								>
								<p-multiSelect
									class="element-filter"
									styleClass="filter-content min-token"
									[(ngModel)]="technologyTreeSelections"
									[options]="technologyTreeOptions"
									display="chip"
									id="technology"
									inputId="technology"
									placeholder="Select technology"
									appendTo="body"
									[filter]="false"
									[showHeader]="false"
								></p-multiSelect>
							</div>
						</div>
						<div class="col-4">
							<div class="flex justify-content-start align-items-center gap-3">
								<label for="benefits" class="text-primary font-bold uppercase label-filter"
									>Benefits</label
								>
								<p-multiSelect
									class="element-filter"
									styleClass="filter-content min-token"
									[(ngModel)]="benefitsTreeSelections"
									[options]="benefitsTreeOptions"
									display="chip"
									id="benefits"
									inputId="benefits"
									placeholder="Select benefits"
									appendTo="body"
									[filter]="false"
									[showHeader]="false"
								></p-multiSelect>
							</div>
						</div>
					</div>
				</div>
				<p-accordion #accordion [activeIndex]="useCaseFilter" class="accordion mt-2">
					<p-accordionTab header="">
						<div class="background-filter">
							<div class="grid grid my-2 mx-3">
								<div class="col-12 lg:col-6 xl:col-6 pb-0">
									<div class="flex flex-column gap-2">
										<div class="flex justify-content-start align-items-center">
											<label for="search" class="text-primary font-bold uppercase label-filter"
												>Search</label
											>
											<span class="p-input-icon-left element-filter filter-content">
												<i class="pi pi-search"></i>
												<input
													type="text"
													pInputText
													placeholder="Search"
													class="filter-content"
													id="search"
													[(ngModel)]="simulationService.searchText"
													(ngModelChange)="simulationService.changeSelection()"
												/>
											</span>
										</div>
										<!-- <div class="flex justify-content-start align-items-center">
											<label
												for="technologies"
												class="text-primary font-bold uppercase label-filter"
											>
												Search
											</label>
											<span class="p-input-icon-left element-filter filter-content">
												<i class="pi pi-search"></i>
												<input
													type="text"
													pInputText
													placeholder="Search"
													class="filter-content"
													id="search"
													[(ngModel)]="useCasesService.searchText"
													(keyup.enter)="useCasesService.changeSearchText()"
												/>
											</span>
										</div> -->
										<div class="flex justify-content-start align-items-center">
											<label
												for="technologies"
												class="text-primary font-bold uppercase label-filter"
												>AI Enabler</label
											>
											<p-multiSelect
												class="element-filter"
												styleClass="filter-content"
												[(ngModel)]="simulationService.technologiesTreeSelection"
												[options]="simulationService.technologiesTree"
												display="chip"
												id="technologies"
												inputId="technologies"
												placeholder="Select filter"
												appendTo="body"
												[filter]="false"
												[showHeader]="false"
												(onChange)="simulationService.changeSelection()"
											></p-multiSelect>
										</div>
										<!-- <div class="flex justify-content-start align-items-center">
											<label
												for="technologies"
												class="text-primary font-bold uppercase label-filter"
												>Benefits</label
											>
											<p-multiSelect
												class="element-filter"
												styleClass="filter-content"
												[(ngModel)]="useCasesService.benefitsTreeSelection"
												[options]="useCasesService.benefitsTree"
												display="chip"
												id="aiDrivers1"
												inputId="aiDrivers1"
												placeholder="Select filter"
												appendTo="body"
												[filter]="false"
												[showHeader]="false"
												(onChange)="useCasesService.changeSelection()"
											></p-multiSelect>
										</div>
										<div class="flex justify-content-start align-items-center">
											<label
												for="technologies"
												class="text-primary font-bold uppercase label-filter"
												>Effort to Implement</label
											>
											<p-multiSelect
												class="element-filter"
												styleClass="filter-content"
												[(ngModel)]="useCasesService.effortToImplementTreeSelection"
												[options]="useCasesService.effortToImplementTree"
												display="chip"
												id="aiDrivers2"
												inputId="aiDrivers2"
												placeholder="Select filter"
												appendTo="body"
												[filter]="false"
												[showHeader]="false"
												(onChange)="useCasesService.changeSelection()"
											></p-multiSelect>
										</div> -->
									</div>
								</div>
								<div class="col-12 lg:col-6 xl:col-6 pb-0">
									<div class="flex flex-column gap-2">
										<div class="flex justify-content-start align-items-center">
											<label for="functions" class="text-primary font-bold uppercase label-filter"
												>Functions</label
											>
											<p-multiSelect
												class="element-filter"
												styleClass="filter-content"
												[(ngModel)]="simulationService.functionsTreeSelection"
												[options]="useCasesService.functionsTree"
												display="chip"
												id="functions"
												inputId="functions"
												placeholder="Select filter"
												appendTo="body"
												[filter]="true"
												(onChange)="simulationService.changeSelection()"
											></p-multiSelect>
										</div>
										<div class="flex justify-content-start align-items-center">
											<label for="tools" class="text-primary font-bold uppercase label-filter"
												>Impact</label
											>
											<p-multiSelect
												class="element-filter"
												styleClass="filter-content"
												[(ngModel)]="simulationService.impactTreeSelection"
												[options]="simulationService.impactTree"
												display="chip"
												id="tools"
												inputId="tools"
												placeholder="Select filter"
												appendTo="body"
												[filter]="true"
												(onChange)="simulationService.changeSelection()"
											></p-multiSelect>
										</div>
										<!-- <div class="flex justify-content-start align-items-center">
											<label
												for="technologies"
												class="text-primary font-bold uppercase label-filter"
												>Time to Implement</label
											>
											<p-multiSelect
												class="element-filter"
												styleClass="filter-content"
												[(ngModel)]="useCasesService.timeToImplementTreeSelection"
												[options]="useCasesService.timeToImplementTree"
												display="chip"
												id="aiDrivers3"
												inputId="aiDrivers3"
												placeholder="Select filter"
												appendTo="body"
												[filter]="false"
												[showHeader]="false"
												(onChange)="useCasesService.changeSelection()"
											></p-multiSelect>
										</div>
										<div class="flex justify-content-start align-items-center">
											<label
												for="technologies"
												class="text-primary font-bold uppercase label-filter"
												>Technology</label
											>
											<p-multiSelect
												class="element-filter"
												styleClass="filter-content"
												[(ngModel)]="useCasesService.technologyTreeSelection"
												[options]="useCasesService.technologyTree"
												display="chip"
												id="aiDrivers4"
												inputId="aiDrivers4"
												placeholder="Select filter"
												appendTo="body"
												[filter]="false"
												[showHeader]="false"
												(onChange)="useCasesService.changeSelection()"
											></p-multiSelect>
										</div> -->
									</div>
								</div>
							</div>
						</div>
					</p-accordionTab>
				</p-accordion>
			</div>

			<div class="col-12 col-content w-full overflow-auto mt-3">
				<p-tabView [(activeIndex)]="selectionView">
					<p-tabPanel>
						<div class="flex flex-wrap">
							<div class="flex flex-column gap-4 col-12">
								<app-taxonomy-context *ngIf="simulationService.loaded"></app-taxonomy-context>
								<app-simulation-dashboard-info
									*ngIf="simulationService.loaded"
									(navigateToPromptStudio)="goToPromptStudio()"
								></app-simulation-dashboard-info>

								<!-- BLOCK NO DATA STATUS-->

								<!--<div
									*ngIf="simulationService.loaded && !simulationService.simulations.length"
									class="flex justify-content-center align-items-center h-25rem text-color-normal"
								>
									<div
										class="flex flex-column gap-3 align-content-center justify-content-center text-center"
									>
										<div class="w-full text-center">
											<img src="assets/rtp/img/simulations-empty.png" alt="" class="w-5rem" />
										</div>
										<span class="text-lg text-primary">No process created</span>
										<span>Select your first process to start a simulation</span>
									</div>
								</div>-->

								<!-- BLOCK LOADING-->
								<div
									*ngIf="!simulationService.loaded"
									class="flex justify-content-center align-items-center h-25rem text-color-normal"
								>
									<i class="pi pi-spin pi-spinner text-primary" style="font-size: 3.5rem"></i>
								</div>
							</div>
						</div>
					</p-tabPanel>
					<p-tabPanel>
						<app-m1-prep></app-m1-prep>
					</p-tabPanel>
					<p-tabPanel>
						<div class="grid-solutions-wrapper">
							<div class="grid-solutions pt-3 px-2">
								<ng-template [ngIf]="!simulationService.loaded">
									<p-skeleton
										height="10rem"
										width="17rem"
										styleClass="card-data"
										*ngFor="let _ of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
									></p-skeleton>
								</ng-template>

								<ng-template [ngIf]="simulationService.loaded">
									<ng-template ngFor let-solution [ngForOf]="simulationService.pagedSolutions">
										<div
											class="card-data border-1 surface-border surface-card border-round relative"
										>
											<div
												class="p-3 pb-0 flex justify-content-start align-content-center align-items-center gap-2 cursor-pointer"
												(click)="useCasesService.goToDetail(solution)"
											>
												<img
													*ngIf="solution.icon"
													[src]="solution.icon"
													alt=""
													class="icon-solution"
												/>
												<img
													*ngIf="solution.iconDefault"
													[src]="solution.iconDefault.file"
													alt=""
													class="icon-solution"
												/>
												<span
													*ngIf="!(solution.icon || solution.iconDefault)"
													class="pi pi-box text-4xl text-orange-500"
												></span>
												<span class="font-bold text-sm md:text-md lg:text-md xl:text-md pl-2">
													{{ solution.name }}
												</span>
											</div>
											<div class="description-use-case p-3 text-overflow-ellipsis">
												{{ solution.description }}
											</div>
										</div>
									</ng-template>
								</ng-template>
							</div>
						</div>
						<div class="paginator-container justify-content-center align-items-center">
							<p-paginator
								[rows]="simulationService.rows"
								[totalRecords]="simulationService.filteredUseCases.length"
								(onPageChange)="simulationService.paginate($event)"
							>
							</p-paginator>
						</div>
					</p-tabPanel>

					<p-tabPanel>
						<app-simulation-prompt-studio></app-simulation-prompt-studio>
						<div
							*ngIf="!simulationService.loaded && !this.useCasesService.init"
							class="flex justify-content-center align-items-center h-25rem text-color-normal"
						>
							<i class="pi pi-spin pi-spinner text-primary" style="font-size: 3.5rem"></i>
						</div>
					</p-tabPanel>
				</p-tabView>
			</div>
		</div>
	</div>
</div>

<p-dialog
	header="Add process to simulation"
	[(visible)]="simulationService.addProcessDialog"
	[style]="{ width: '45vw' }"
	[modal]="true"
	[draggable]="false"
	[resizable]="false"
	styleClass="simulation-add-process-modal"
>
	<p-tabView [(activeIndex)]="simulationService.addProcessTab">
		<p-tabPanel header="From Taxonomy">
			<p-tree
				*ngIf="simulationService.addProcessDialog"
				selectionMode="checkbox"
				class="w-full"
				[value]="simulationService.addProcessTaxonomyTreeSelect"
				[(selection)]="simulationService.addProcessTaxonomyTreeSelected"
				scrollHeight="50vh"
			></p-tree>
		</p-tabPanel>
		<p-tabPanel header="Free Form Simulation">
			<div class="flex flex-column flex-wrap gap-3 px-3">
				<div class="flex flex-column gap-2">
					<label for="usecase-name">Name</label>
					<input
						pInputText
						id="usecase-name"
						aria-describedby="name-help"
						[(ngModel)]="simulationService.addProcessForm.name"
					/>
				</div>
				<div class="flex flex-column gap-2">
					<label for="usecase-description">Description</label>
					<textarea
						rows="5"
						cols="30"
						pInputTextarea
						id="usecase-description"
						aria-describedby="description-help"
						[(ngModel)]="simulationService.addProcessForm.description"
						[autoResize]="true"
					></textarea>
				</div>
			</div>
		</p-tabPanel>
	</p-tabView>

	<div class="flex justify-content-between gap-3 px-1">
		<div *ngIf="simulationService.addProcessTab"></div>
		<div *ngIf="!simulationService.addProcessTab" class="flex justify-content-start align-items-center gap-3">
			<label for="add-process-scope" class="text-primary font-bold uppercase label-filter">Scope</label>
			<p-treeSelect
				class="element-filter"
				containerStyleClass="filter-content min-token"
				[(ngModel)]="simulationService.addProcessTaxonomyScope"
				[options]="simulationService.addProcessTaxonomyScopeOptions"
				id="add-process-scope"
				inputId="add-process-scope"
				placeholder="Select scope"
				[metaKeySelection]="false"
				appendTo="body"
			></p-treeSelect>
		</div>

		<div class="flex justify-content-end gap-2">
			<p-button
				[loading]="simulationService.blockedAddProcess"
				[disabled]="simulationService.blockedAddProcessGo"
				label="Add"
				(onClick)="simulationService.addProcess()"
			></p-button>
			<p-button
				*ngIf="simulationService.addProcessTab"
				[loading]="simulationService.blockedAddProcessGo"
				[disabled]="simulationService.blockedAddProcess"
				label="Add and edit"
				(onClick)="simulationService.addProcess(true)"
			></p-button>
		</div>
	</div>
</p-dialog>

<p-dialog
	header="Open Simulation"
	[(visible)]="showOpenSimulation"
	[style]="{ width: '80vw' }"
	[modal]="true"
	[draggable]="false"
	[resizable]="false"
	styleClass="dialog-add-simulation"
>
	<div *ngIf="simulationService.loaded && !simulationService.simulations.length">
		<div class="box-simulation-list-item card-data">
			<div class="flex flex-column justify-content-between h-full gap-3">
				<div class="flex flex-wrap">
					<div class="col-12 p-0 my-5">
						<span> There are no simulations saved in this organization. </span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="simulationService.loaded && simulationService.simulations.length" class="grid-simulation">
		<ng-template ngFor let-simulation [ngForOf]="simulationService.simulations">
			<div class="box-simulation-list-item card-data">
				<div class="flex flex-column justify-content-between h-full gap-3">
					<div class="flex flex-wrap">
						<div class="col-11 p-0 mt-2 cursor-pointer" (click)="selectSimulation(simulation)">
							<span class="text-primary"
								>{{ simulation.parent_label }}
								{{ simulation.parent_label ? '/' : '' }}
							</span>
							<span class="font-bold">{{ simulation.label }}</span>
						</div>

						<div class="col-1 p-0 flex justify-content-end align-items-start">
							<span
								class="p-button p-button-text pi pi-ellipsis-v px-0"
								(click)="menu.toggle($event)"
							></span>
							<p-menu
								#menu
								[model]="
									simulation.id ? this.simulationService.simulationsMenu[simulation.id] || [] : []
								"
								[popup]="true"
								appendTo="body"
							></p-menu>
						</div>
					</div>

					<div class="flex justify-content-between align-items-center">
						<div
							*ngIf="simulation.use_cases && simulation.use_cases.length"
							class="box-simulation-generated active"
						>
							{{ simulation.use_cases.length }} Uses Cases
							<span *ngIf="simulation.valids.length">- {{ simulation.valids.length }} selected</span>
						</div>
						<div
							*ngIf="!(simulation.use_cases && simulation.use_cases.length)"
							class="box-simulation-generated empty"
						>
							0 Generated
						</div>
						<!-- <span class="text-sm text-gray-600">Last Run | 07/22/24</span> -->
					</div>
				</div>
			</div>
		</ng-template>
	</div>
</p-dialog>

<p-dialog
	header="Open M1 Prep"
	[(visible)]="showOpenM1Prep"
	[style]="{ width: '80vw' }"
	[modal]="true"
	[draggable]="false"
	[resizable]="false"
	styleClass="dialog-add-simulation"
>
	<div *ngIf="simulationService.loaded && simulationService.m1Preps.length" class="grid-simulation">
		<ng-template ngFor let-m1Prep [ngForOf]="simulationService.m1Preps">
			<div class="box-simulation-list-item card-data">
				<div class="flex flex-column justify-content-between h-full gap-3">
					<div class="flex flex-wrap">
						<div class="col-11 p-0 mt-2 cursor-pointer" (click)="selectM1Prep(m1Prep)">
							<span class="font-bold">{{ m1Prep.context.info.name }}</span>
						</div>

						<div class="col-1 p-0 flex justify-content-end align-items-start">
							<span
								class="p-button p-button-text pi pi-ellipsis-v px-0"
								(click)="menu.toggle($event)"
							></span>
							<p-menu
								#menu
								[model]="m1Prep.id ? this.simulationService.m1PrepMenu[m1Prep.id] || [] : []"
								[popup]="true"
								appendTo="body"
							></p-menu>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
	</div>
</p-dialog>

<p-dialog
	header="Use Case Library"
	[(visible)]="useCasesService.useCaseLibraryItemModal"
	[modal]="true"
	[style]="{ width: '80vw' }"
	styleClass="diagram-sub-modal"
	(onHide)="useCasesService.currentUseCaseLibrary = undefined"
>
	<app-use-case-library-item
		*ngIf="useCasesService.currentUseCaseLibrary"
		[useCase]="useCasesService.currentUseCaseLibrary"
		[diagram]="undefined"
	></app-use-case-library-item>
</p-dialog>
