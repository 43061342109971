export default {
    "scalars": [
        4,
        5,
        6,
        8,
        9,
        12,
        16,
        17,
        19,
        21,
        23,
        26,
        27,
        47,
        68,
        70,
        74,
        82
    ],
    "types": {
        "Query": {
            "common": [
                1
            ],
            "e2eTaxonomy": [
                13
            ],
            "solution": [
                42
            ],
            "useCase": [
                50
            ],
            "maturity": [
                54
            ],
            "simulation": [
                63
            ],
            "commonData": [
                2
            ],
            "organization": [
                10
            ],
            "categoryFunction": [
                14
            ],
            "diagramE2e": [
                34
            ],
            "diagramProcess": [
                35
            ],
            "diagramTemplate": [
                36
            ],
            "function": [
                37
            ],
            "level1": [
                38
            ],
            "level2": [
                39
            ],
            "technology": [
                40
            ],
            "aiOpportunity": [
                41
            ],
            "assessment": [
                55
            ],
            "maturityQuestion": [
                57
            ],
            "maturitySubCategoryQuestion": [
                61
            ],
            "maturityCategoryQuestion": [
                62
            ],
            "context": [
                64
            ],
            "simulationModel": [
                66
            ],
            "useCaseSimulation": [
                71
            ],
            "useCaseLibrary": [
                72
            ],
            "m1Prep": [
                75
            ],
            "categoryQuestion": [
                43
            ],
            "subCategoryQuestion": [
                48
            ],
            "question": [
                49
            ],
            "diagram": [
                51
            ],
            "useCaseModel": [
                52
            ],
            "poc": [
                53
            ],
            "__typename": [
                4
            ]
        },
        "CommonQuery": {
            "commonData": [
                2
            ],
            "organization": [
                10
            ],
            "__typename": [
                4
            ]
        },
        "CommonDataModelQuery": {
            "fetch": [
                3,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                3,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                3,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "CommonDataModelObjectType": {
            "key": [
                4
            ],
            "data": [
                5
            ],
            "__typename": [
                4
            ]
        },
        "String": {},
        "JSONString": {},
        "GenericScalar": {},
        "PaginationType": {
            "page": [
                8
            ],
            "size": [
                8
            ],
            "__typename": [
                4
            ]
        },
        "Int": {},
        "BigInt": {},
        "OrganizationModelQuery": {
            "fetch": [
                11,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                11,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                11,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "OrganizationModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "summary": [
                4
            ],
            "taxonomy": [
                6
            ],
            "__typename": [
                4
            ]
        },
        "UUID": {},
        "E2ETaxonomyQuery": {
            "categoryFunction": [
                14
            ],
            "diagramE2e": [
                34
            ],
            "diagramProcess": [
                35
            ],
            "diagramTemplate": [
                36
            ],
            "function": [
                37
            ],
            "level1": [
                38
            ],
            "level2": [
                39
            ],
            "technology": [
                40
            ],
            "aiOpportunity": [
                41
            ],
            "__typename": [
                4
            ]
        },
        "CategoryFunctionModelQuery": {
            "fetch": [
                15,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                15,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                15,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "CategoryFunctionModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "short": [
                4
            ],
            "sort": [
                8
            ],
            "type": [
                16
            ],
            "office": [
                17
            ],
            "functions": [
                18,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "functionsIds": [
                4,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "E2ETaxonomyCategoryFunctionTypeChoices": {},
        "E2ETaxonomyCategoryFunctionOfficeChoices": {},
        "FunctionModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "short": [
                4
            ],
            "name": [
                4
            ],
            "description": [
                4
            ],
            "sort": [
                8
            ],
            "type": [
                19
            ],
            "overall": [
                8
            ],
            "category": [
                15
            ],
            "categoryId": [
                4
            ],
            "organization": [
                11
            ],
            "organizationId": [
                4
            ],
            "levels1": [
                20,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "levels1Ids": [
                4,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "diagram": [
                33
            ],
            "diagramId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "E2ETaxonomyFunctionTypeChoices": {},
        "Level1ModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "description": [
                4
            ],
            "spend": [
                8
            ],
            "sme": [
                8
            ],
            "overall": [
                8
            ],
            "type": [
                21
            ],
            "function": [
                18
            ],
            "functionId": [
                4
            ],
            "organization": [
                11
            ],
            "organizationId": [
                4
            ],
            "levels2": [
                22,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "levels2Ids": [
                4,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "diagram": [
                32
            ],
            "diagramId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "E2ETaxonomyLevel1TypeChoices": {},
        "Level2ModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "description": [
                4
            ],
            "values": [
                6
            ],
            "type": [
                23
            ],
            "level1": [
                20
            ],
            "level1Id": [
                4
            ],
            "diagram": [
                24
            ],
            "diagramId": [
                4
            ],
            "organization": [
                11
            ],
            "organizationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "E2ETaxonomyLevel2TypeChoices": {},
        "DiagramTemplateModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "data": [
                6
            ],
            "xmlData": [
                4
            ],
            "xmlImage": [
                4
            ],
            "level2": [
                22
            ],
            "level2Id": [
                4
            ],
            "opportunities": [
                25,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "opportunitiesIds": [
                4,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "AIOpportunityModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "shapeId": [
                4
            ],
            "name": [
                4
            ],
            "description": [
                4
            ],
            "checked": [
                26
            ],
            "valid": [
                26
            ],
            "type": [
                27
            ],
            "data": [
                6
            ],
            "poc": [
                28
            ],
            "pocId": [
                4
            ],
            "diagram": [
                24
            ],
            "diagramId": [
                4
            ],
            "useCase": [
                29
            ],
            "useCaseId": [
                4
            ],
            "technologies": [
                31,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "technologiesIds": [
                4,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "Boolean": {},
        "E2ETaxonomyAIOpportunityTypeChoices": {},
        "PocModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "title": [
                4
            ],
            "description": [
                4
            ],
            "approved": [
                26
            ],
            "generalInformation": [
                6
            ],
            "functionalRequirements": [
                6
            ],
            "noFunctionalRequirements": [
                6
            ],
            "pocRequirements": [
                6
            ],
            "integratedRequirements": [
                6
            ],
            "inputsDataSource": [
                6
            ],
            "genAIChecklist": [
                6
            ],
            "deploymentHandshake": [
                6
            ],
            "effortAndCostEstimation": [
                6
            ],
            "organization": [
                11
            ],
            "organizationId": [
                4
            ],
            "useCase": [
                29
            ],
            "useCaseId": [
                4
            ],
            "aiEnablers": [
                31,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "aiEnablersIds": [
                4,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "UseCaseModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "description": [
                4
            ],
            "generalData": [
                6
            ],
            "formData": [
                6
            ],
            "workPlanData": [
                6
            ],
            "aiValues": [
                6
            ],
            "hubble": [
                26
            ],
            "benefits": [
                26
            ],
            "benefitsValues": [
                6
            ],
            "benefitsOrder": [
                6
            ],
            "simulationData": [
                6
            ],
            "libraryData": [
                6
            ],
            "nexus": [
                6
            ],
            "organization": [
                11
            ],
            "organizationId": [
                4
            ],
            "diagram": [
                30
            ],
            "diagramId": [
                4
            ],
            "levels2": [
                22,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "levels2Ids": [
                4,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "opportunities": [
                25,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "opportunitiesIds": [
                4,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "technologies": [
                31,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "technologiesIds": [
                4,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "pocs": [
                28,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "pocsIds": [
                4,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "DiagramModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "data": [
                6
            ],
            "xmlData": [
                4
            ],
            "xmlImage": [
                4
            ],
            "organization": [
                11
            ],
            "organizationId": [
                4
            ],
            "useCase": [
                29
            ],
            "useCaseId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "TechnologyModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "short": [
                4
            ],
            "neto": [
                26
            ],
            "svg": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "DiagramProcessModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "data": [
                6
            ],
            "xmlData": [
                4
            ],
            "xmlImage": [
                4
            ],
            "level1": [
                20
            ],
            "level1Id": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "DiagramE2EModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "data": [
                6
            ],
            "xmlData": [
                4
            ],
            "xmlImage": [
                4
            ],
            "function": [
                18
            ],
            "functionId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "DiagramE2EModelQuery": {
            "fetch": [
                33,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                33,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                33,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "DiagramProcessModelQuery": {
            "fetch": [
                32,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                32,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                32,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "DiagramTemplateModelQuery": {
            "fetch": [
                24,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                24,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                24,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "FunctionModelQuery": {
            "fetch": [
                18,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                18,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                18,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "Level1ModelQuery": {
            "fetch": [
                20,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                20,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                20,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "Level2ModelQuery": {
            "fetch": [
                22,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                22,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                22,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "TechnologyModelQuery": {
            "fetch": [
                31,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                31,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                31,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "AIOpportunityModelQuery": {
            "fetch": [
                25,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                25,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                25,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "SolutionQuery": {
            "categoryQuestion": [
                43
            ],
            "subCategoryQuestion": [
                48
            ],
            "question": [
                49
            ],
            "__typename": [
                4
            ]
        },
        "CategoryQuestionModelQuery": {
            "fetch": [
                44,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                44,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                44,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "CategoryQuestionModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "text": [
                4
            ],
            "sort": [
                8
            ],
            "subCategories": [
                45
            ],
            "subCategoriesid": [
                4
            ],
            "questions": [
                46,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "questionsIds": [
                4,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "SubCategoryQuestionModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "text": [
                4
            ],
            "sort": [
                8
            ],
            "category": [
                44
            ],
            "categoryId": [
                4
            ],
            "questions": [
                46,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "questionsIds": [
                4,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "QuestionModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "text": [
                4
            ],
            "weight": [
                47
            ],
            "sort": [
                8
            ],
            "answers": [
                6
            ],
            "weightJson": [
                6
            ],
            "tag": [
                4
            ],
            "category": [
                44
            ],
            "categoryId": [
                4
            ],
            "subcategory": [
                45
            ],
            "subcategoryId": [
                4
            ],
            "technology": [
                31
            ],
            "technologyId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "Decimal": {},
        "SubCategoryQuestionModelQuery": {
            "fetch": [
                45,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                45,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                45,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "QuestionModelQuery": {
            "fetch": [
                46,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                46,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                46,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "UseCaseQuery": {
            "diagram": [
                51
            ],
            "useCase": [
                52
            ],
            "poc": [
                53
            ],
            "__typename": [
                4
            ]
        },
        "DiagramModelQuery": {
            "fetch": [
                30,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                30,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                30,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "UseCaseModelQuery": {
            "fetch": [
                29,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                29,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                29,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "PocModelQuery": {
            "fetch": [
                28,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                28,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                28,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "MaturityQuery": {
            "assessment": [
                55
            ],
            "question": [
                57
            ],
            "subCategoryQuestion": [
                61
            ],
            "categoryQuestion": [
                62
            ],
            "__typename": [
                4
            ]
        },
        "AssessmentMaturityModelQuery": {
            "fetch": [
                56,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                56,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                56,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "AssessmentMaturityModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "content": [
                4
            ],
            "formData": [
                6
            ],
            "commentData": [
                6
            ],
            "recommendations": [
                6
            ],
            "organization": [
                11
            ],
            "organizationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "QuestionMaturityModelQuery": {
            "fetch": [
                58,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                58,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                58,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "QuestionMaturityModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "text": [
                4
            ],
            "sort": [
                8
            ],
            "category": [
                59
            ],
            "categoryId": [
                4
            ],
            "subcategory": [
                60
            ],
            "subcategoryId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "CategoryQuestionMaturityModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "sort": [
                8
            ],
            "subCategories": [
                60,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "subCategoriesids": [
                4,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "questions": [
                58,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "questionsIds": [
                4,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "SubCategoryQuestionMaturityModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "sort": [
                8
            ],
            "category": [
                59
            ],
            "categoryId": [
                4
            ],
            "questions": [
                58,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "questionsIds": [
                4,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "SubCategoryQuestionMaturityModelQuery": {
            "fetch": [
                60,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                60,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                60,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "CategoryQuestionMaturityModelQuery": {
            "fetch": [
                59,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                59,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                59,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "SimulationQuery": {
            "context": [
                64
            ],
            "simulation": [
                66
            ],
            "useCaseSimulation": [
                71
            ],
            "useCaseLibrary": [
                72
            ],
            "m1Prep": [
                75
            ],
            "__typename": [
                4
            ]
        },
        "EnterpriseContextModelQuery": {
            "fetch": [
                65,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                65,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                65,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "EnterpriseContextModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "enterpriseContext": [
                6
            ],
            "organization": [
                11
            ],
            "organizationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "SimulationModelQuery": {
            "fetch": [
                67,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                67,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                67,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "SimulationModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "description": [
                4
            ],
            "notes": [
                4
            ],
            "search": [
                26
            ],
            "generate": [
                26
            ],
            "taxonomyId": [
                12
            ],
            "taxonomyRel": [
                68
            ],
            "enterpriseContext": [
                6
            ],
            "simulationContext": [
                6
            ],
            "useCases": [
                69,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "useCasesids": [
                4,
                {
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "organization": [
                11
            ],
            "organizationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "SimulationSimulationTaxonomyRelChoices": {},
        "UseCaseSimulationModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "description": [
                4
            ],
            "deployedId": [
                12
            ],
            "keyFeatures": [
                6
            ],
            "context": [
                6
            ],
            "data": [
                6
            ],
            "valid": [
                26
            ],
            "type": [
                70
            ],
            "organization": [
                11
            ],
            "organizationId": [
                4
            ],
            "simulation": [
                67
            ],
            "simulationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "SimulationUseCaseSimulationTypeChoices": {},
        "UseCaseSimulationModelQuery": {
            "fetch": [
                69,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                69,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                69,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "UseCaseLibraryModelQuery": {
            "fetch": [
                73,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                73,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                73,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "UseCaseLibraryModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "name": [
                4
            ],
            "description": [
                4
            ],
            "data": [
                6
            ],
            "sourceId": [
                12
            ],
            "sourceRel": [
                74
            ],
            "__typename": [
                4
            ]
        },
        "SimulationUseCaseLibrarySourceRelChoices": {},
        "M1PrepModelQuery": {
            "fetch": [
                76,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ],
                    "pagination": [
                        7
                    ]
                }
            ],
            "count": [
                8,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "get": [
                76,
                {
                    "organizationId": [
                        4
                    ],
                    "query": [
                        6
                    ],
                    "sort": [
                        4,
                        "[String]"
                    ]
                }
            ],
            "all": [
                76,
                {
                    "dtUnix": [
                        9
                    ],
                    "organizationId": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "M1PrepModelObjectType": {
            "id": [
                12
            ],
            "createdAt": [
                9
            ],
            "updatedAt": [
                9
            ],
            "deletedAt": [
                9
            ],
            "context": [
                6
            ],
            "companyAnalysis": [
                6
            ],
            "products": [
                6
            ],
            "competitiveLandscape": [
                6
            ],
            "conversation": [
                6
            ],
            "salesforce": [
                6
            ],
            "organization": [
                11
            ],
            "organizationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "Mutation": {
            "categoryFunction": [
                78
            ],
            "aiOpportunity": [
                84
            ],
            "diagramE2e": [
                88
            ],
            "diagramProcess": [
                92
            ],
            "diagramTemplate": [
                96
            ],
            "function": [
                100
            ],
            "level1": [
                104
            ],
            "level2": [
                108
            ],
            "technology": [
                112
            ],
            "assessment": [
                116
            ],
            "maturityQuestion": [
                120
            ],
            "maturitySubCategoryQuestion": [
                124
            ],
            "maturityCategoryQuestion": [
                128
            ],
            "context": [
                132
            ],
            "simulation": [
                136
            ],
            "useCaseSimulation": [
                140
            ],
            "useCaseLibrary": [
                144
            ],
            "m1Prep": [
                148
            ],
            "categoryQuestion": [
                152
            ],
            "subCategoryQuestion": [
                156
            ],
            "question": [
                160
            ],
            "diagram": [
                164
            ],
            "useCase": [
                168
            ],
            "poc": [
                172
            ],
            "__typename": [
                4
            ]
        },
        "CategoryFunctionModelMutation": {
            "put": [
                79,
                {
                    "input": [
                        81,
                        "CategoryFunctionModelPutMutationInput!"
                    ]
                }
            ],
            "delete": [
                83,
                {
                    "id": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "CategoryFunctionModelPutMutationPayload": {
            "data": [
                15
            ],
            "errors": [
                80
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "ErrorType": {
            "field": [
                4
            ],
            "messages": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "CategoryFunctionModelPutMutationInput": {
            "office": [
                4
            ],
            "short": [
                4
            ],
            "sort": [
                8
            ],
            "type": [
                4
            ],
            "name": [
                4
            ],
            "id": [
                82
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "ID": {},
        "CategoryFunctionModelDeleteMutation": {
            "data": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "AIOpportunityModelMutation": {
            "put": [
                85,
                {
                    "input": [
                        86,
                        "AIOpportunityModelPutMutationInput!"
                    ]
                }
            ],
            "delete": [
                87,
                {
                    "id": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "AIOpportunityModelPutMutationPayload": {
            "data": [
                25
            ],
            "errors": [
                80
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "AIOpportunityModelPutMutationInput": {
            "shapeId": [
                4
            ],
            "data": [
                4
            ],
            "useCase": [
                82
            ],
            "diagram": [
                82
            ],
            "type": [
                4
            ],
            "valid": [
                26
            ],
            "checked": [
                26
            ],
            "description": [
                4
            ],
            "name": [
                4
            ],
            "id": [
                82
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "AIOpportunityModelDeleteMutation": {
            "data": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "DiagramE2EModelMutation": {
            "put": [
                89,
                {
                    "input": [
                        90,
                        "DiagramE2EModelPutMutationInput!"
                    ]
                }
            ],
            "delete": [
                91,
                {
                    "id": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "DiagramE2EModelPutMutationPayload": {
            "data": [
                33
            ],
            "errors": [
                80
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "DiagramE2EModelPutMutationInput": {
            "data": [
                4
            ],
            "function": [
                82
            ],
            "id": [
                82
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "DiagramE2EModelDeleteMutation": {
            "data": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "DiagramProcessModelMutation": {
            "put": [
                93,
                {
                    "input": [
                        94,
                        "DiagramProcessModelPutMutationInput!"
                    ]
                }
            ],
            "delete": [
                95,
                {
                    "id": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "DiagramProcessModelPutMutationPayload": {
            "data": [
                32
            ],
            "errors": [
                80
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "DiagramProcessModelPutMutationInput": {
            "data": [
                4
            ],
            "level1": [
                82
            ],
            "id": [
                82
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "DiagramProcessModelDeleteMutation": {
            "data": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "DiagramTemplateModelMutation": {
            "put": [
                97,
                {
                    "input": [
                        98,
                        "DiagramTemplateModelPutMutationInput!"
                    ]
                }
            ],
            "delete": [
                99,
                {
                    "id": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "DiagramTemplateModelPutMutationPayload": {
            "data": [
                24
            ],
            "errors": [
                80
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "DiagramTemplateModelPutMutationInput": {
            "data": [
                4
            ],
            "level2": [
                82
            ],
            "id": [
                82
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "DiagramTemplateModelDeleteMutation": {
            "data": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "FunctionModelMutation": {
            "put": [
                101,
                {
                    "input": [
                        102,
                        "FunctionModelPutMutationInput!"
                    ]
                }
            ],
            "delete": [
                103,
                {
                    "id": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "FunctionModelPutMutationPayload": {
            "data": [
                18
            ],
            "errors": [
                80
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "FunctionModelPutMutationInput": {
            "short": [
                4
            ],
            "sort": [
                8
            ],
            "type": [
                4
            ],
            "description": [
                4
            ],
            "name": [
                4
            ],
            "id": [
                82
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "FunctionModelDeleteMutation": {
            "data": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "Level1ModelMutation": {
            "put": [
                105,
                {
                    "input": [
                        106,
                        "Level1ModelPutMutationInput!"
                    ]
                }
            ],
            "delete": [
                107,
                {
                    "id": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "Level1ModelPutMutationPayload": {
            "data": [
                20
            ],
            "errors": [
                80
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "Level1ModelPutMutationInput": {
            "overall": [
                8
            ],
            "sme": [
                8
            ],
            "spend": [
                8
            ],
            "type": [
                4
            ],
            "description": [
                4
            ],
            "name": [
                4
            ],
            "id": [
                82
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "Level1ModelDeleteMutation": {
            "data": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "Level2ModelMutation": {
            "put": [
                109,
                {
                    "input": [
                        110,
                        "Level2ModelPutMutationInput!"
                    ]
                }
            ],
            "delete": [
                111,
                {
                    "id": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "Level2ModelPutMutationPayload": {
            "data": [
                22
            ],
            "errors": [
                80
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "Level2ModelPutMutationInput": {
            "values": [
                4
            ],
            "type": [
                4
            ],
            "description": [
                4
            ],
            "name": [
                4
            ],
            "id": [
                82
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "Level2ModelDeleteMutation": {
            "data": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "TechnologyModelMutation": {
            "put": [
                113,
                {
                    "input": [
                        114,
                        "TechnologyModelPutMutationInput!"
                    ]
                }
            ],
            "delete": [
                115,
                {
                    "id": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "TechnologyModelPutMutationPayload": {
            "data": [
                31
            ],
            "errors": [
                80
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "TechnologyModelPutMutationInput": {
            "svg": [
                4
            ],
            "neto": [
                26
            ],
            "short": [
                4
            ],
            "name": [
                4
            ],
            "id": [
                82
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "TechnologyModelDeleteMutation": {
            "data": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "AssessmentMaturityModelMutation": {
            "put": [
                117,
                {
                    "input": [
                        118,
                        "AssessmentMaturityModelPutMutationInput!"
                    ]
                }
            ],
            "delete": [
                119,
                {
                    "id": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "AssessmentMaturityModelPutMutationPayload": {
            "data": [
                56
            ],
            "errors": [
                80
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "AssessmentMaturityModelPutMutationInput": {
            "formData": [
                4
            ],
            "commentData": [
                4
            ],
            "recommendations": [
                4
            ],
            "content": [
                4
            ],
            "id": [
                82
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "AssessmentMaturityModelDeleteMutation": {
            "data": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "QuestionMaturityModelMutation": {
            "put": [
                121,
                {
                    "input": [
                        122,
                        "QuestionMaturityModelPutMutationInput!"
                    ]
                }
            ],
            "delete": [
                123,
                {
                    "id": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "QuestionMaturityModelPutMutationPayload": {
            "data": [
                58
            ],
            "errors": [
                80
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "QuestionMaturityModelPutMutationInput": {
            "text": [
                4
            ],
            "sort": [
                8
            ],
            "id": [
                82
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "QuestionMaturityModelDeleteMutation": {
            "data": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "SubCategoryQuestionMaturityModelMutation": {
            "put": [
                125,
                {
                    "input": [
                        126,
                        "SubCategoryQuestionMaturityModelPutMutationInput!"
                    ]
                }
            ],
            "delete": [
                127,
                {
                    "id": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "SubCategoryQuestionMaturityModelPutMutationPayload": {
            "data": [
                60
            ],
            "errors": [
                80
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "SubCategoryQuestionMaturityModelPutMutationInput": {
            "name": [
                4
            ],
            "sort": [
                8
            ],
            "id": [
                82
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "SubCategoryQuestionMaturityModelDeleteMutation": {
            "data": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "CategoryQuestionMaturityModelMutation": {
            "put": [
                129,
                {
                    "input": [
                        130,
                        "CategoryQuestionMaturityModelPutMutationInput!"
                    ]
                }
            ],
            "delete": [
                131,
                {
                    "id": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "CategoryQuestionMaturityModelPutMutationPayload": {
            "data": [
                59
            ],
            "errors": [
                80
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "CategoryQuestionMaturityModelPutMutationInput": {
            "name": [
                4
            ],
            "sort": [
                8
            ],
            "id": [
                82
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "CategoryQuestionMaturityModelDeleteMutation": {
            "data": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "EnterpriseContextModelMutation": {
            "put": [
                133,
                {
                    "input": [
                        134,
                        "EnterpriseContextModelPutMutationInput!"
                    ]
                }
            ],
            "delete": [
                135,
                {
                    "id": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "EnterpriseContextModelPutMutationPayload": {
            "data": [
                65
            ],
            "errors": [
                80
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "EnterpriseContextModelPutMutationInput": {
            "enterpriseContext": [
                4
            ],
            "id": [
                82
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "EnterpriseContextModelDeleteMutation": {
            "data": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "SimulationModelMutation": {
            "put": [
                137,
                {
                    "input": [
                        138,
                        "SimulationModelPutMutationInput!"
                    ]
                }
            ],
            "delete": [
                139,
                {
                    "id": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "SimulationModelPutMutationPayload": {
            "data": [
                67
            ],
            "errors": [
                80
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "SimulationModelPutMutationInput": {
            "taxonomyId": [
                12
            ],
            "simulationContext": [
                4
            ],
            "search": [
                26
            ],
            "notes": [
                4
            ],
            "enterpriseContext": [
                4
            ],
            "taxonomyRel": [
                4
            ],
            "generate": [
                26
            ],
            "description": [
                4
            ],
            "name": [
                4
            ],
            "id": [
                82
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "SimulationModelDeleteMutation": {
            "data": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "UseCaseSimulationModelMutation": {
            "put": [
                141,
                {
                    "input": [
                        142,
                        "UseCaseSimulationModelPutMutationInput!"
                    ]
                }
            ],
            "delete": [
                143,
                {
                    "id": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "UseCaseSimulationModelPutMutationPayload": {
            "data": [
                69
            ],
            "errors": [
                80
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "UseCaseSimulationModelPutMutationInput": {
            "context": [
                4
            ],
            "data": [
                4
            ],
            "deployedId": [
                12
            ],
            "type": [
                4
            ],
            "valid": [
                26
            ],
            "keyFeatures": [
                4
            ],
            "description": [
                4
            ],
            "name": [
                4
            ],
            "id": [
                82
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "UseCaseSimulationModelDeleteMutation": {
            "data": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "UseCaseLibraryModelMutation": {
            "put": [
                145,
                {
                    "input": [
                        146,
                        "UseCaseLibraryModelPutMutationInput!"
                    ]
                }
            ],
            "delete": [
                147,
                {
                    "id": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "UseCaseLibraryModelPutMutationPayload": {
            "data": [
                73
            ],
            "errors": [
                80
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "UseCaseLibraryModelPutMutationInput": {
            "data": [
                4
            ],
            "sourceId": [
                12
            ],
            "sourceRel": [
                4
            ],
            "description": [
                4
            ],
            "name": [
                4
            ],
            "id": [
                82
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "UseCaseLibraryModelDeleteMutation": {
            "data": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "M1PrepModelMutation": {
            "put": [
                149,
                {
                    "input": [
                        150,
                        "M1PrepModelPutMutationInput!"
                    ]
                }
            ],
            "delete": [
                151,
                {
                    "id": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "M1PrepModelPutMutationPayload": {
            "data": [
                76
            ],
            "errors": [
                80
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "M1PrepModelPutMutationInput": {
            "companyAnalysis": [
                4
            ],
            "context": [
                4
            ],
            "competitiveLandscape": [
                4
            ],
            "products": [
                4
            ],
            "conversation": [
                4
            ],
            "salesforce": [
                4
            ],
            "id": [
                82
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "M1PrepModelDeleteMutation": {
            "data": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "CategoryQuestionModelMutation": {
            "put": [
                153,
                {
                    "input": [
                        154,
                        "CategoryQuestionModelPutMutationInput!"
                    ]
                }
            ],
            "delete": [
                155,
                {
                    "id": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "CategoryQuestionModelPutMutationPayload": {
            "data": [
                44
            ],
            "errors": [
                80
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "CategoryQuestionModelPutMutationInput": {
            "name": [
                4
            ],
            "text": [
                4
            ],
            "sort": [
                8
            ],
            "id": [
                82
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "CategoryQuestionModelDeleteMutation": {
            "data": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "SubCategoryQuestionModelMutation": {
            "put": [
                157,
                {
                    "input": [
                        158,
                        "SubCategoryQuestionModelPutMutationInput!"
                    ]
                }
            ],
            "delete": [
                159,
                {
                    "id": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "SubCategoryQuestionModelPutMutationPayload": {
            "data": [
                45
            ],
            "errors": [
                80
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "SubCategoryQuestionModelPutMutationInput": {
            "name": [
                4
            ],
            "text": [
                4
            ],
            "sort": [
                8
            ],
            "id": [
                82
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "SubCategoryQuestionModelDeleteMutation": {
            "data": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "QuestionModelMutation": {
            "put": [
                161,
                {
                    "input": [
                        162,
                        "QuestionModelPutMutationInput!"
                    ]
                }
            ],
            "delete": [
                163,
                {
                    "id": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "QuestionModelPutMutationPayload": {
            "data": [
                46
            ],
            "errors": [
                80
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "QuestionModelPutMutationInput": {
            "tag": [
                4
            ],
            "answers": [
                4
            ],
            "text": [
                4
            ],
            "weightJson": [
                4
            ],
            "weight": [
                47
            ],
            "sort": [
                8
            ],
            "id": [
                82
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "QuestionModelDeleteMutation": {
            "data": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "DiagramModelMutation": {
            "put": [
                165,
                {
                    "input": [
                        166,
                        "DiagramModelPutMutationInput!"
                    ]
                }
            ],
            "delete": [
                167,
                {
                    "id": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "DiagramModelPutMutationPayload": {
            "data": [
                30
            ],
            "errors": [
                80
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "DiagramModelPutMutationInput": {
            "useCase": [
                82
            ],
            "data": [
                4
            ],
            "id": [
                82
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "DiagramModelDeleteMutation": {
            "data": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "UseCaseModelMutation": {
            "put": [
                169,
                {
                    "input": [
                        170,
                        "UseCaseModelPutMutationInput!"
                    ]
                }
            ],
            "delete": [
                171,
                {
                    "id": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "UseCaseModelPutMutationPayload": {
            "data": [
                29
            ],
            "errors": [
                80
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "UseCaseModelPutMutationInput": {
            "benefitsValues": [
                4
            ],
            "benefits": [
                26
            ],
            "nexus": [
                4
            ],
            "libraryData": [
                4
            ],
            "simulationData": [
                4
            ],
            "name": [
                4
            ],
            "benefitsOrder": [
                4
            ],
            "hubble": [
                26
            ],
            "formData": [
                4
            ],
            "aiValues": [
                4
            ],
            "generalData": [
                4
            ],
            "description": [
                4
            ],
            "workPlanData": [
                4
            ],
            "id": [
                82
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "UseCaseModelDeleteMutation": {
            "data": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "PocModelMutation": {
            "put": [
                173,
                {
                    "input": [
                        174,
                        "PocModelPutMutationInput!"
                    ]
                }
            ],
            "delete": [
                175,
                {
                    "id": [
                        4
                    ]
                }
            ],
            "__typename": [
                4
            ]
        },
        "PocModelPutMutationPayload": {
            "data": [
                28
            ],
            "errors": [
                80
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "PocModelPutMutationInput": {
            "functionalRequirements": [
                4
            ],
            "integratedRequirements": [
                4
            ],
            "generalInformation": [
                4
            ],
            "genAIChecklist": [
                4
            ],
            "title": [
                4
            ],
            "inputsDataSource": [
                4
            ],
            "pocRequirements": [
                4
            ],
            "deploymentHandshake": [
                4
            ],
            "noFunctionalRequirements": [
                4
            ],
            "description": [
                4
            ],
            "id": [
                82
            ],
            "clientMutationId": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "PocModelDeleteMutation": {
            "data": [
                4
            ],
            "__typename": [
                4
            ]
        }
    }
}