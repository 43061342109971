<p-toast></p-toast>
<div class="flex justify-content-between align-items-center gap-3 my-2">
    <span class="m-0 font-bold text-lg">Deployment Handshake</span>
    <div class="flex align-items-center gap-2">
        <!-- Button to view the uploaded document if fileUrl exists -->
        <ng-container *ngIf="pocInput.deploymentHandshake?.fileUrl">
            <a [href]="pocInput.deploymentHandshake.fileUrl" target="_blank">
                <button pButton [loading]="isPdfBase64Loading" label="View Document" icon="pi pi-file-pdf"></button>
            </a>
        </ng-container>

        <!-- Buttons to generate, save, or download the document -->
        <ng-template [ngIf]="pdfBase64String">
            <button
                pButton
                label="Download Document"
                icon="pi pi-file-word"
                [loading]="isPdfBase64Loading"
                (click)="downloadWordFromTemplate()"
            ></button>
        </ng-template>
        <button
            pButton
            label="Generate Design Document"
            icon="pi pi-file-pdf"
            [loading]="isPdfBase64Loading"
            (click)="generatePdfFromTemplate()"
        ></button>
    </div>
</div>

<!-- Message when no document is available -->
<ng-template [ngIf]="!pdfBase64String && !pocInput.deploymentHandshake?.fileUrl">
    <div class="w-full custom-box py-5" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <i class="pi pi-file-word text-primary" style="font-size: 2.5rem;"></i>
        <span class="font-bold text-lg pt-5 pb-3">No document created yet</span>
        <span class="pb-5">Fill the data in the previous tabs and then click on the Generate Design Document button to create a document.</span>
    </div>
</ng-template>

<!-- Render uploaded document if fileUrl exists -->
<ng-template [ngIf]="pocInput.deploymentHandshake?.fileUrl && !pdfBase64String">
    <ngx-extended-pdf-viewer 
        *ngIf="isPdfViewerReady"
        [src]="pocInput.deploymentHandshake.fileUrl"
        [showToolbar]="false"
        (afterLoadComplete)="onPdfViewerLoad()">
    </ngx-extended-pdf-viewer>
</ng-template>

<!-- Render generated document -->
<ng-template [ngIf]="pdfBase64String">
    <ngx-extended-pdf-viewer 
        *ngIf="isPdfViewerReady"
        [src]="pdfBase64String"
        [showToolbar]="false"
        (afterLoadComplete)="onPdfViewerLoad()">
    </ngx-extended-pdf-viewer>
</ng-template>
