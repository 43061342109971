// @ts-nocheck
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Scalars = {
    String: string,
    JSONString: any,
    GenericScalar: any,
    Int: number,
    BigInt: any,
    UUID: any,
    Boolean: boolean,
    Decimal: any,
    ID: string,
}

export interface Query {
    common: (CommonQuery | null)
    e2eTaxonomy: (E2ETaxonomyQuery | null)
    solution: (SolutionQuery | null)
    useCase: (UseCaseQuery | null)
    maturity: (MaturityQuery | null)
    simulation: (SimulationQuery | null)
    commonData: (CommonDataModelQuery | null)
    organization: (OrganizationModelQuery | null)
    categoryFunction: (CategoryFunctionModelQuery | null)
    diagramE2e: (DiagramE2EModelQuery | null)
    diagramProcess: (DiagramProcessModelQuery | null)
    diagramTemplate: (DiagramTemplateModelQuery | null)
    function: (FunctionModelQuery | null)
    level1: (Level1ModelQuery | null)
    level2: (Level2ModelQuery | null)
    technology: (TechnologyModelQuery | null)
    aiOpportunity: (AIOpportunityModelQuery | null)
    assessment: (AssessmentMaturityModelQuery | null)
    maturityQuestion: (QuestionMaturityModelQuery | null)
    maturitySubCategoryQuestion: (SubCategoryQuestionMaturityModelQuery | null)
    maturityCategoryQuestion: (CategoryQuestionMaturityModelQuery | null)
    context: (EnterpriseContextModelQuery | null)
    simulationModel: (SimulationModelQuery | null)
    useCaseSimulation: (UseCaseSimulationModelQuery | null)
    useCaseLibrary: (UseCaseLibraryModelQuery | null)
    m1Prep: (M1PrepModelQuery | null)
    categoryQuestion: (CategoryQuestionModelQuery | null)
    subCategoryQuestion: (SubCategoryQuestionModelQuery | null)
    question: (QuestionModelQuery | null)
    diagram: (DiagramModelQuery | null)
    useCaseModel: (UseCaseModelQuery | null)
    poc: (PocModelQuery | null)
    __typename: 'Query'
}

export interface CommonQuery {
    commonData: (CommonDataModelQuery | null)
    organization: (OrganizationModelQuery | null)
    __typename: 'CommonQuery'
}

export interface CommonDataModelQuery {
    fetch: ((CommonDataModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (CommonDataModelObjectType | null)
    all: ((CommonDataModelObjectType | null)[] | null)
    __typename: 'CommonDataModelQuery'
}

export interface CommonDataModelObjectType {
    key: Scalars['String']
    data: Scalars['JSONString']
    __typename: 'CommonDataModelObjectType'
}

export interface OrganizationModelQuery {
    fetch: ((OrganizationModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (OrganizationModelObjectType | null)
    all: ((OrganizationModelObjectType | null)[] | null)
    __typename: 'OrganizationModelQuery'
}

export interface OrganizationModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    summary: Scalars['String']
    taxonomy: (Scalars['GenericScalar'] | null)
    __typename: 'OrganizationModelObjectType'
}

export interface E2ETaxonomyQuery {
    categoryFunction: (CategoryFunctionModelQuery | null)
    diagramE2e: (DiagramE2EModelQuery | null)
    diagramProcess: (DiagramProcessModelQuery | null)
    diagramTemplate: (DiagramTemplateModelQuery | null)
    function: (FunctionModelQuery | null)
    level1: (Level1ModelQuery | null)
    level2: (Level2ModelQuery | null)
    technology: (TechnologyModelQuery | null)
    aiOpportunity: (AIOpportunityModelQuery | null)
    __typename: 'E2ETaxonomyQuery'
}

export interface CategoryFunctionModelQuery {
    fetch: ((CategoryFunctionModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (CategoryFunctionModelObjectType | null)
    all: ((CategoryFunctionModelObjectType | null)[] | null)
    __typename: 'CategoryFunctionModelQuery'
}

export interface CategoryFunctionModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    short: (Scalars['String'] | null)
    sort: Scalars['Int']
    type: E2ETaxonomyCategoryFunctionTypeChoices
    office: E2ETaxonomyCategoryFunctionOfficeChoices
    functions: ((FunctionModelObjectType | null)[] | null)
    functionsIds: ((Scalars['String'] | null)[] | null)
    __typename: 'CategoryFunctionModelObjectType'
}


/** An enumeration. */
export type E2ETaxonomyCategoryFunctionTypeChoices = 'A_1' | 'A_2' | 'A_3'


/** An enumeration. */
export type E2ETaxonomyCategoryFunctionOfficeChoices = 'A_1' | 'A_2' | 'A_3'

export interface FunctionModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    short: (Scalars['String'] | null)
    name: Scalars['String']
    description: Scalars['String']
    sort: Scalars['Int']
    type: E2ETaxonomyFunctionTypeChoices
    overall: Scalars['Int']
    category: CategoryFunctionModelObjectType
    categoryId: Scalars['String']
    organization: OrganizationModelObjectType
    organizationId: Scalars['String']
    levels1: ((Level1ModelObjectType | null)[] | null)
    levels1Ids: ((Scalars['String'] | null)[] | null)
    diagram: (DiagramE2EModelObjectType | null)
    diagramId: (Scalars['String'] | null)
    __typename: 'FunctionModelObjectType'
}


/** An enumeration. */
export type E2ETaxonomyFunctionTypeChoices = 'A_1' | 'A_2' | 'A_3'

export interface Level1ModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    description: Scalars['String']
    spend: Scalars['Int']
    sme: Scalars['Int']
    overall: Scalars['Int']
    type: E2ETaxonomyLevel1TypeChoices
    function: FunctionModelObjectType
    functionId: Scalars['String']
    organization: OrganizationModelObjectType
    organizationId: Scalars['String']
    levels2: ((Level2ModelObjectType | null)[] | null)
    levels2Ids: ((Scalars['String'] | null)[] | null)
    diagram: (DiagramProcessModelObjectType | null)
    diagramId: (Scalars['String'] | null)
    __typename: 'Level1ModelObjectType'
}


/** An enumeration. */
export type E2ETaxonomyLevel1TypeChoices = 'A_1' | 'A_2' | 'A_3'

export interface Level2ModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    description: Scalars['String']
    values: (Scalars['GenericScalar'] | null)
    type: E2ETaxonomyLevel2TypeChoices
    level1: Level1ModelObjectType
    level1Id: Scalars['String']
    diagram: (DiagramTemplateModelObjectType | null)
    diagramId: (Scalars['String'] | null)
    organization: OrganizationModelObjectType
    organizationId: Scalars['String']
    __typename: 'Level2ModelObjectType'
}


/** An enumeration. */
export type E2ETaxonomyLevel2TypeChoices = 'A_1' | 'A_2' | 'A_3'

export interface DiagramTemplateModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    data: (Scalars['GenericScalar'] | null)
    xmlData: (Scalars['String'] | null)
    xmlImage: (Scalars['String'] | null)
    level2: Level2ModelObjectType
    level2Id: Scalars['String']
    opportunities: ((AIOpportunityModelObjectType | null)[] | null)
    opportunitiesIds: ((Scalars['String'] | null)[] | null)
    __typename: 'DiagramTemplateModelObjectType'
}

export interface AIOpportunityModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    shapeId: Scalars['String']
    name: Scalars['String']
    description: Scalars['String']
    checked: Scalars['Boolean']
    valid: Scalars['Boolean']
    type: E2ETaxonomyAIOpportunityTypeChoices
    data: (Scalars['GenericScalar'] | null)
    poc: (PocModelObjectType | null)
    pocId: (Scalars['String'] | null)
    diagram: (DiagramTemplateModelObjectType | null)
    diagramId: (Scalars['String'] | null)
    useCase: UseCaseModelObjectType
    useCaseId: Scalars['String']
    technologies: ((TechnologyModelObjectType | null)[] | null)
    technologiesIds: ((Scalars['String'] | null)[] | null)
    __typename: 'AIOpportunityModelObjectType'
}


/** An enumeration. */
export type E2ETaxonomyAIOpportunityTypeChoices = 'A_1' | 'A_2'

export interface PocModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    title: Scalars['String']
    description: (Scalars['String'] | null)
    approved: Scalars['Boolean']
    generalInformation: (Scalars['GenericScalar'] | null)
    functionalRequirements: (Scalars['GenericScalar'] | null)
    noFunctionalRequirements: (Scalars['GenericScalar'] | null)
    pocRequirements: (Scalars['GenericScalar'] | null)
    integratedRequirements: (Scalars['GenericScalar'] | null)
    inputsDataSource: (Scalars['GenericScalar'] | null)
    genAIChecklist: (Scalars['GenericScalar'] | null)
    deploymentHandshake: (Scalars['GenericScalar'] | null)
    effortAndCostEstimation: (Scalars['GenericScalar'] | null)
    organization: OrganizationModelObjectType
    organizationId: Scalars['String']
    useCase: UseCaseModelObjectType
    useCaseId: Scalars['String']
    aiEnablers: ((TechnologyModelObjectType | null)[] | null)
    aiEnablersIds: ((Scalars['String'] | null)[] | null)
    __typename: 'PocModelObjectType'
}

export interface UseCaseModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    description: Scalars['String']
    generalData: (Scalars['GenericScalar'] | null)
    formData: (Scalars['GenericScalar'] | null)
    workPlanData: (Scalars['GenericScalar'] | null)
    aiValues: (Scalars['GenericScalar'] | null)
    hubble: Scalars['Boolean']
    benefits: Scalars['Boolean']
    benefitsValues: (Scalars['GenericScalar'] | null)
    benefitsOrder: (Scalars['GenericScalar'] | null)
    simulationData: (Scalars['GenericScalar'] | null)
    libraryData: (Scalars['GenericScalar'] | null)
    nexus: (Scalars['GenericScalar'] | null)
    organization: OrganizationModelObjectType
    organizationId: Scalars['String']
    diagram: (DiagramModelObjectType | null)
    diagramId: (Scalars['String'] | null)
    levels2: ((Level2ModelObjectType | null)[] | null)
    levels2Ids: ((Scalars['String'] | null)[] | null)
    opportunities: ((AIOpportunityModelObjectType | null)[] | null)
    opportunitiesIds: ((Scalars['String'] | null)[] | null)
    technologies: ((TechnologyModelObjectType | null)[] | null)
    technologiesIds: ((Scalars['String'] | null)[] | null)
    pocs: ((PocModelObjectType | null)[] | null)
    pocsIds: ((Scalars['String'] | null)[] | null)
    __typename: 'UseCaseModelObjectType'
}

export interface DiagramModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    data: (Scalars['GenericScalar'] | null)
    xmlData: (Scalars['String'] | null)
    xmlImage: (Scalars['String'] | null)
    organization: OrganizationModelObjectType
    organizationId: Scalars['String']
    useCase: UseCaseModelObjectType
    useCaseId: Scalars['String']
    __typename: 'DiagramModelObjectType'
}

export interface TechnologyModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    short: (Scalars['String'] | null)
    neto: Scalars['Boolean']
    svg: (Scalars['String'] | null)
    __typename: 'TechnologyModelObjectType'
}

export interface DiagramProcessModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    data: (Scalars['GenericScalar'] | null)
    xmlData: (Scalars['String'] | null)
    xmlImage: (Scalars['String'] | null)
    level1: Level1ModelObjectType
    level1Id: Scalars['String']
    __typename: 'DiagramProcessModelObjectType'
}

export interface DiagramE2EModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    data: (Scalars['GenericScalar'] | null)
    xmlData: (Scalars['String'] | null)
    xmlImage: (Scalars['String'] | null)
    function: FunctionModelObjectType
    functionId: Scalars['String']
    __typename: 'DiagramE2EModelObjectType'
}

export interface DiagramE2EModelQuery {
    fetch: ((DiagramE2EModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (DiagramE2EModelObjectType | null)
    all: ((DiagramE2EModelObjectType | null)[] | null)
    __typename: 'DiagramE2EModelQuery'
}

export interface DiagramProcessModelQuery {
    fetch: ((DiagramProcessModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (DiagramProcessModelObjectType | null)
    all: ((DiagramProcessModelObjectType | null)[] | null)
    __typename: 'DiagramProcessModelQuery'
}

export interface DiagramTemplateModelQuery {
    fetch: ((DiagramTemplateModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (DiagramTemplateModelObjectType | null)
    all: ((DiagramTemplateModelObjectType | null)[] | null)
    __typename: 'DiagramTemplateModelQuery'
}

export interface FunctionModelQuery {
    fetch: ((FunctionModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (FunctionModelObjectType | null)
    all: ((FunctionModelObjectType | null)[] | null)
    __typename: 'FunctionModelQuery'
}

export interface Level1ModelQuery {
    fetch: ((Level1ModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (Level1ModelObjectType | null)
    all: ((Level1ModelObjectType | null)[] | null)
    __typename: 'Level1ModelQuery'
}

export interface Level2ModelQuery {
    fetch: ((Level2ModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (Level2ModelObjectType | null)
    all: ((Level2ModelObjectType | null)[] | null)
    __typename: 'Level2ModelQuery'
}

export interface TechnologyModelQuery {
    fetch: ((TechnologyModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (TechnologyModelObjectType | null)
    all: ((TechnologyModelObjectType | null)[] | null)
    __typename: 'TechnologyModelQuery'
}

export interface AIOpportunityModelQuery {
    fetch: ((AIOpportunityModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (AIOpportunityModelObjectType | null)
    all: ((AIOpportunityModelObjectType | null)[] | null)
    __typename: 'AIOpportunityModelQuery'
}

export interface SolutionQuery {
    categoryQuestion: (CategoryQuestionModelQuery | null)
    subCategoryQuestion: (SubCategoryQuestionModelQuery | null)
    question: (QuestionModelQuery | null)
    __typename: 'SolutionQuery'
}

export interface CategoryQuestionModelQuery {
    fetch: ((CategoryQuestionModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (CategoryQuestionModelObjectType | null)
    all: ((CategoryQuestionModelObjectType | null)[] | null)
    __typename: 'CategoryQuestionModelQuery'
}

export interface CategoryQuestionModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    text: Scalars['String']
    sort: Scalars['Int']
    subCategories: SubCategoryQuestionModelObjectType
    subCategoriesid: Scalars['String']
    questions: ((QuestionModelObjectType | null)[] | null)
    questionsIds: ((Scalars['String'] | null)[] | null)
    __typename: 'CategoryQuestionModelObjectType'
}

export interface SubCategoryQuestionModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    text: Scalars['String']
    sort: Scalars['Int']
    category: CategoryQuestionModelObjectType
    categoryId: Scalars['String']
    questions: ((QuestionModelObjectType | null)[] | null)
    questionsIds: ((Scalars['String'] | null)[] | null)
    __typename: 'SubCategoryQuestionModelObjectType'
}

export interface QuestionModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    text: Scalars['String']
    weight: Scalars['Decimal']
    sort: Scalars['Int']
    answers: (Scalars['GenericScalar'] | null)
    weightJson: (Scalars['GenericScalar'] | null)
    tag: (Scalars['String'] | null)
    category: CategoryQuestionModelObjectType
    categoryId: Scalars['String']
    subcategory: (SubCategoryQuestionModelObjectType | null)
    subcategoryId: (Scalars['String'] | null)
    technology: (TechnologyModelObjectType | null)
    technologyId: (Scalars['String'] | null)
    __typename: 'QuestionModelObjectType'
}

export interface SubCategoryQuestionModelQuery {
    fetch: ((SubCategoryQuestionModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (SubCategoryQuestionModelObjectType | null)
    all: ((SubCategoryQuestionModelObjectType | null)[] | null)
    __typename: 'SubCategoryQuestionModelQuery'
}

export interface QuestionModelQuery {
    fetch: ((QuestionModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (QuestionModelObjectType | null)
    all: ((QuestionModelObjectType | null)[] | null)
    __typename: 'QuestionModelQuery'
}

export interface UseCaseQuery {
    diagram: (DiagramModelQuery | null)
    useCase: (UseCaseModelQuery | null)
    poc: (PocModelQuery | null)
    __typename: 'UseCaseQuery'
}

export interface DiagramModelQuery {
    fetch: ((DiagramModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (DiagramModelObjectType | null)
    all: ((DiagramModelObjectType | null)[] | null)
    __typename: 'DiagramModelQuery'
}

export interface UseCaseModelQuery {
    fetch: ((UseCaseModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (UseCaseModelObjectType | null)
    all: ((UseCaseModelObjectType | null)[] | null)
    __typename: 'UseCaseModelQuery'
}

export interface PocModelQuery {
    fetch: ((PocModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (PocModelObjectType | null)
    all: ((PocModelObjectType | null)[] | null)
    __typename: 'PocModelQuery'
}

export interface MaturityQuery {
    assessment: (AssessmentMaturityModelQuery | null)
    question: (QuestionMaturityModelQuery | null)
    subCategoryQuestion: (SubCategoryQuestionMaturityModelQuery | null)
    categoryQuestion: (CategoryQuestionMaturityModelQuery | null)
    __typename: 'MaturityQuery'
}

export interface AssessmentMaturityModelQuery {
    fetch: ((AssessmentMaturityModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (AssessmentMaturityModelObjectType | null)
    all: ((AssessmentMaturityModelObjectType | null)[] | null)
    __typename: 'AssessmentMaturityModelQuery'
}

export interface AssessmentMaturityModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    content: (Scalars['String'] | null)
    formData: (Scalars['GenericScalar'] | null)
    commentData: (Scalars['GenericScalar'] | null)
    recommendations: (Scalars['GenericScalar'] | null)
    organization: OrganizationModelObjectType
    organizationId: Scalars['String']
    __typename: 'AssessmentMaturityModelObjectType'
}

export interface QuestionMaturityModelQuery {
    fetch: ((QuestionMaturityModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (QuestionMaturityModelObjectType | null)
    all: ((QuestionMaturityModelObjectType | null)[] | null)
    __typename: 'QuestionMaturityModelQuery'
}

export interface QuestionMaturityModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    text: Scalars['String']
    sort: Scalars['Int']
    category: CategoryQuestionMaturityModelObjectType
    categoryId: Scalars['String']
    subcategory: SubCategoryQuestionMaturityModelObjectType
    subcategoryId: Scalars['String']
    __typename: 'QuestionMaturityModelObjectType'
}

export interface CategoryQuestionMaturityModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    sort: Scalars['Int']
    subCategories: ((SubCategoryQuestionMaturityModelObjectType | null)[] | null)
    subCategoriesids: ((Scalars['String'] | null)[] | null)
    questions: ((QuestionMaturityModelObjectType | null)[] | null)
    questionsIds: ((Scalars['String'] | null)[] | null)
    __typename: 'CategoryQuestionMaturityModelObjectType'
}

export interface SubCategoryQuestionMaturityModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    sort: Scalars['Int']
    category: CategoryQuestionMaturityModelObjectType
    categoryId: Scalars['String']
    questions: ((QuestionMaturityModelObjectType | null)[] | null)
    questionsIds: ((Scalars['String'] | null)[] | null)
    __typename: 'SubCategoryQuestionMaturityModelObjectType'
}

export interface SubCategoryQuestionMaturityModelQuery {
    fetch: ((SubCategoryQuestionMaturityModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (SubCategoryQuestionMaturityModelObjectType | null)
    all: ((SubCategoryQuestionMaturityModelObjectType | null)[] | null)
    __typename: 'SubCategoryQuestionMaturityModelQuery'
}

export interface CategoryQuestionMaturityModelQuery {
    fetch: ((CategoryQuestionMaturityModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (CategoryQuestionMaturityModelObjectType | null)
    all: ((CategoryQuestionMaturityModelObjectType | null)[] | null)
    __typename: 'CategoryQuestionMaturityModelQuery'
}

export interface SimulationQuery {
    context: (EnterpriseContextModelQuery | null)
    simulation: (SimulationModelQuery | null)
    useCaseSimulation: (UseCaseSimulationModelQuery | null)
    useCaseLibrary: (UseCaseLibraryModelQuery | null)
    m1Prep: (M1PrepModelQuery | null)
    __typename: 'SimulationQuery'
}

export interface EnterpriseContextModelQuery {
    fetch: ((EnterpriseContextModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (EnterpriseContextModelObjectType | null)
    all: ((EnterpriseContextModelObjectType | null)[] | null)
    __typename: 'EnterpriseContextModelQuery'
}

export interface EnterpriseContextModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    enterpriseContext: (Scalars['GenericScalar'] | null)
    organization: OrganizationModelObjectType
    organizationId: Scalars['String']
    __typename: 'EnterpriseContextModelObjectType'
}

export interface SimulationModelQuery {
    fetch: ((SimulationModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (SimulationModelObjectType | null)
    all: ((SimulationModelObjectType | null)[] | null)
    __typename: 'SimulationModelQuery'
}

export interface SimulationModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    description: Scalars['String']
    notes: Scalars['String']
    search: Scalars['Boolean']
    generate: Scalars['Boolean']
    taxonomyId: (Scalars['UUID'] | null)
    taxonomyRel: SimulationSimulationTaxonomyRelChoices
    enterpriseContext: (Scalars['GenericScalar'] | null)
    simulationContext: (Scalars['GenericScalar'] | null)
    useCases: ((UseCaseSimulationModelObjectType | null)[] | null)
    useCasesids: ((Scalars['String'] | null)[] | null)
    organization: OrganizationModelObjectType
    organizationId: Scalars['String']
    __typename: 'SimulationModelObjectType'
}


/** An enumeration. */
export type SimulationSimulationTaxonomyRelChoices = 'A_1' | 'A_2' | 'A_3'

export interface UseCaseSimulationModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    description: Scalars['String']
    deployedId: (Scalars['UUID'] | null)
    keyFeatures: (Scalars['GenericScalar'] | null)
    context: (Scalars['GenericScalar'] | null)
    data: (Scalars['GenericScalar'] | null)
    valid: Scalars['Boolean']
    type: SimulationUseCaseSimulationTypeChoices
    organization: OrganizationModelObjectType
    organizationId: Scalars['String']
    simulation: SimulationModelObjectType
    simulationId: Scalars['String']
    __typename: 'UseCaseSimulationModelObjectType'
}


/** An enumeration. */
export type SimulationUseCaseSimulationTypeChoices = 'A_1' | 'A_2'

export interface UseCaseSimulationModelQuery {
    fetch: ((UseCaseSimulationModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (UseCaseSimulationModelObjectType | null)
    all: ((UseCaseSimulationModelObjectType | null)[] | null)
    __typename: 'UseCaseSimulationModelQuery'
}

export interface UseCaseLibraryModelQuery {
    fetch: ((UseCaseLibraryModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (UseCaseLibraryModelObjectType | null)
    all: ((UseCaseLibraryModelObjectType | null)[] | null)
    __typename: 'UseCaseLibraryModelQuery'
}

export interface UseCaseLibraryModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    name: Scalars['String']
    description: Scalars['String']
    data: (Scalars['GenericScalar'] | null)
    sourceId: (Scalars['UUID'] | null)
    sourceRel: SimulationUseCaseLibrarySourceRelChoices
    __typename: 'UseCaseLibraryModelObjectType'
}


/** An enumeration. */
export type SimulationUseCaseLibrarySourceRelChoices = 'A_1' | 'A_2' | 'A_3'

export interface M1PrepModelQuery {
    fetch: ((M1PrepModelObjectType | null)[] | null)
    count: (Scalars['Int'] | null)
    get: (M1PrepModelObjectType | null)
    all: ((M1PrepModelObjectType | null)[] | null)
    __typename: 'M1PrepModelQuery'
}

export interface M1PrepModelObjectType {
    id: Scalars['UUID']
    createdAt: (Scalars['BigInt'] | null)
    updatedAt: (Scalars['BigInt'] | null)
    deletedAt: (Scalars['BigInt'] | null)
    context: (Scalars['GenericScalar'] | null)
    companyAnalysis: (Scalars['GenericScalar'] | null)
    products: (Scalars['GenericScalar'] | null)
    competitiveLandscape: (Scalars['GenericScalar'] | null)
    conversation: (Scalars['GenericScalar'] | null)
    salesforce: (Scalars['GenericScalar'] | null)
    organization: OrganizationModelObjectType
    organizationId: Scalars['String']
    __typename: 'M1PrepModelObjectType'
}

export interface Mutation {
    categoryFunction: (CategoryFunctionModelMutation | null)
    aiOpportunity: (AIOpportunityModelMutation | null)
    diagramE2e: (DiagramE2EModelMutation | null)
    diagramProcess: (DiagramProcessModelMutation | null)
    diagramTemplate: (DiagramTemplateModelMutation | null)
    function: (FunctionModelMutation | null)
    level1: (Level1ModelMutation | null)
    level2: (Level2ModelMutation | null)
    technology: (TechnologyModelMutation | null)
    assessment: (AssessmentMaturityModelMutation | null)
    maturityQuestion: (QuestionMaturityModelMutation | null)
    maturitySubCategoryQuestion: (SubCategoryQuestionMaturityModelMutation | null)
    maturityCategoryQuestion: (CategoryQuestionMaturityModelMutation | null)
    context: (EnterpriseContextModelMutation | null)
    simulation: (SimulationModelMutation | null)
    useCaseSimulation: (UseCaseSimulationModelMutation | null)
    useCaseLibrary: (UseCaseLibraryModelMutation | null)
    m1Prep: (M1PrepModelMutation | null)
    categoryQuestion: (CategoryQuestionModelMutation | null)
    subCategoryQuestion: (SubCategoryQuestionModelMutation | null)
    question: (QuestionModelMutation | null)
    diagram: (DiagramModelMutation | null)
    useCase: (UseCaseModelMutation | null)
    poc: (PocModelMutation | null)
    __typename: 'Mutation'
}

export interface CategoryFunctionModelMutation {
    put: (CategoryFunctionModelPutMutationPayload | null)
    delete: (CategoryFunctionModelDeleteMutation | null)
    __typename: 'CategoryFunctionModelMutation'
}

export interface CategoryFunctionModelPutMutationPayload {
    data: (CategoryFunctionModelObjectType | null)
    errors: ErrorType[]
    clientMutationId: (Scalars['String'] | null)
    __typename: 'CategoryFunctionModelPutMutationPayload'
}

export interface ErrorType {
    field: Scalars['String']
    messages: Scalars['String'][]
    __typename: 'ErrorType'
}

export interface CategoryFunctionModelDeleteMutation {
    data: (Scalars['String'] | null)
    __typename: 'CategoryFunctionModelDeleteMutation'
}

export interface AIOpportunityModelMutation {
    put: (AIOpportunityModelPutMutationPayload | null)
    delete: (AIOpportunityModelDeleteMutation | null)
    __typename: 'AIOpportunityModelMutation'
}

export interface AIOpportunityModelPutMutationPayload {
    data: (AIOpportunityModelObjectType | null)
    errors: ErrorType[]
    clientMutationId: (Scalars['String'] | null)
    __typename: 'AIOpportunityModelPutMutationPayload'
}

export interface AIOpportunityModelDeleteMutation {
    data: (Scalars['String'] | null)
    __typename: 'AIOpportunityModelDeleteMutation'
}

export interface DiagramE2EModelMutation {
    put: (DiagramE2EModelPutMutationPayload | null)
    delete: (DiagramE2EModelDeleteMutation | null)
    __typename: 'DiagramE2EModelMutation'
}

export interface DiagramE2EModelPutMutationPayload {
    data: (DiagramE2EModelObjectType | null)
    errors: ErrorType[]
    clientMutationId: (Scalars['String'] | null)
    __typename: 'DiagramE2EModelPutMutationPayload'
}

export interface DiagramE2EModelDeleteMutation {
    data: (Scalars['String'] | null)
    __typename: 'DiagramE2EModelDeleteMutation'
}

export interface DiagramProcessModelMutation {
    put: (DiagramProcessModelPutMutationPayload | null)
    delete: (DiagramProcessModelDeleteMutation | null)
    __typename: 'DiagramProcessModelMutation'
}

export interface DiagramProcessModelPutMutationPayload {
    data: (DiagramProcessModelObjectType | null)
    errors: ErrorType[]
    clientMutationId: (Scalars['String'] | null)
    __typename: 'DiagramProcessModelPutMutationPayload'
}

export interface DiagramProcessModelDeleteMutation {
    data: (Scalars['String'] | null)
    __typename: 'DiagramProcessModelDeleteMutation'
}

export interface DiagramTemplateModelMutation {
    put: (DiagramTemplateModelPutMutationPayload | null)
    delete: (DiagramTemplateModelDeleteMutation | null)
    __typename: 'DiagramTemplateModelMutation'
}

export interface DiagramTemplateModelPutMutationPayload {
    data: (DiagramTemplateModelObjectType | null)
    errors: ErrorType[]
    clientMutationId: (Scalars['String'] | null)
    __typename: 'DiagramTemplateModelPutMutationPayload'
}

export interface DiagramTemplateModelDeleteMutation {
    data: (Scalars['String'] | null)
    __typename: 'DiagramTemplateModelDeleteMutation'
}

export interface FunctionModelMutation {
    put: (FunctionModelPutMutationPayload | null)
    delete: (FunctionModelDeleteMutation | null)
    __typename: 'FunctionModelMutation'
}

export interface FunctionModelPutMutationPayload {
    data: (FunctionModelObjectType | null)
    errors: ErrorType[]
    clientMutationId: (Scalars['String'] | null)
    __typename: 'FunctionModelPutMutationPayload'
}

export interface FunctionModelDeleteMutation {
    data: (Scalars['String'] | null)
    __typename: 'FunctionModelDeleteMutation'
}

export interface Level1ModelMutation {
    put: (Level1ModelPutMutationPayload | null)
    delete: (Level1ModelDeleteMutation | null)
    __typename: 'Level1ModelMutation'
}

export interface Level1ModelPutMutationPayload {
    data: (Level1ModelObjectType | null)
    errors: ErrorType[]
    clientMutationId: (Scalars['String'] | null)
    __typename: 'Level1ModelPutMutationPayload'
}

export interface Level1ModelDeleteMutation {
    data: (Scalars['String'] | null)
    __typename: 'Level1ModelDeleteMutation'
}

export interface Level2ModelMutation {
    put: (Level2ModelPutMutationPayload | null)
    delete: (Level2ModelDeleteMutation | null)
    __typename: 'Level2ModelMutation'
}

export interface Level2ModelPutMutationPayload {
    data: (Level2ModelObjectType | null)
    errors: ErrorType[]
    clientMutationId: (Scalars['String'] | null)
    __typename: 'Level2ModelPutMutationPayload'
}

export interface Level2ModelDeleteMutation {
    data: (Scalars['String'] | null)
    __typename: 'Level2ModelDeleteMutation'
}

export interface TechnologyModelMutation {
    put: (TechnologyModelPutMutationPayload | null)
    delete: (TechnologyModelDeleteMutation | null)
    __typename: 'TechnologyModelMutation'
}

export interface TechnologyModelPutMutationPayload {
    data: (TechnologyModelObjectType | null)
    errors: ErrorType[]
    clientMutationId: (Scalars['String'] | null)
    __typename: 'TechnologyModelPutMutationPayload'
}

export interface TechnologyModelDeleteMutation {
    data: (Scalars['String'] | null)
    __typename: 'TechnologyModelDeleteMutation'
}

export interface AssessmentMaturityModelMutation {
    put: (AssessmentMaturityModelPutMutationPayload | null)
    delete: (AssessmentMaturityModelDeleteMutation | null)
    __typename: 'AssessmentMaturityModelMutation'
}

export interface AssessmentMaturityModelPutMutationPayload {
    data: (AssessmentMaturityModelObjectType | null)
    errors: ErrorType[]
    clientMutationId: (Scalars['String'] | null)
    __typename: 'AssessmentMaturityModelPutMutationPayload'
}

export interface AssessmentMaturityModelDeleteMutation {
    data: (Scalars['String'] | null)
    __typename: 'AssessmentMaturityModelDeleteMutation'
}

export interface QuestionMaturityModelMutation {
    put: (QuestionMaturityModelPutMutationPayload | null)
    delete: (QuestionMaturityModelDeleteMutation | null)
    __typename: 'QuestionMaturityModelMutation'
}

export interface QuestionMaturityModelPutMutationPayload {
    data: (QuestionMaturityModelObjectType | null)
    errors: ErrorType[]
    clientMutationId: (Scalars['String'] | null)
    __typename: 'QuestionMaturityModelPutMutationPayload'
}

export interface QuestionMaturityModelDeleteMutation {
    data: (Scalars['String'] | null)
    __typename: 'QuestionMaturityModelDeleteMutation'
}

export interface SubCategoryQuestionMaturityModelMutation {
    put: (SubCategoryQuestionMaturityModelPutMutationPayload | null)
    delete: (SubCategoryQuestionMaturityModelDeleteMutation | null)
    __typename: 'SubCategoryQuestionMaturityModelMutation'
}

export interface SubCategoryQuestionMaturityModelPutMutationPayload {
    data: (SubCategoryQuestionMaturityModelObjectType | null)
    errors: ErrorType[]
    clientMutationId: (Scalars['String'] | null)
    __typename: 'SubCategoryQuestionMaturityModelPutMutationPayload'
}

export interface SubCategoryQuestionMaturityModelDeleteMutation {
    data: (Scalars['String'] | null)
    __typename: 'SubCategoryQuestionMaturityModelDeleteMutation'
}

export interface CategoryQuestionMaturityModelMutation {
    put: (CategoryQuestionMaturityModelPutMutationPayload | null)
    delete: (CategoryQuestionMaturityModelDeleteMutation | null)
    __typename: 'CategoryQuestionMaturityModelMutation'
}

export interface CategoryQuestionMaturityModelPutMutationPayload {
    data: (CategoryQuestionMaturityModelObjectType | null)
    errors: ErrorType[]
    clientMutationId: (Scalars['String'] | null)
    __typename: 'CategoryQuestionMaturityModelPutMutationPayload'
}

export interface CategoryQuestionMaturityModelDeleteMutation {
    data: (Scalars['String'] | null)
    __typename: 'CategoryQuestionMaturityModelDeleteMutation'
}

export interface EnterpriseContextModelMutation {
    put: (EnterpriseContextModelPutMutationPayload | null)
    delete: (EnterpriseContextModelDeleteMutation | null)
    __typename: 'EnterpriseContextModelMutation'
}

export interface EnterpriseContextModelPutMutationPayload {
    data: (EnterpriseContextModelObjectType | null)
    errors: ErrorType[]
    clientMutationId: (Scalars['String'] | null)
    __typename: 'EnterpriseContextModelPutMutationPayload'
}

export interface EnterpriseContextModelDeleteMutation {
    data: (Scalars['String'] | null)
    __typename: 'EnterpriseContextModelDeleteMutation'
}

export interface SimulationModelMutation {
    put: (SimulationModelPutMutationPayload | null)
    delete: (SimulationModelDeleteMutation | null)
    __typename: 'SimulationModelMutation'
}

export interface SimulationModelPutMutationPayload {
    data: (SimulationModelObjectType | null)
    errors: ErrorType[]
    clientMutationId: (Scalars['String'] | null)
    __typename: 'SimulationModelPutMutationPayload'
}

export interface SimulationModelDeleteMutation {
    data: (Scalars['String'] | null)
    __typename: 'SimulationModelDeleteMutation'
}

export interface UseCaseSimulationModelMutation {
    put: (UseCaseSimulationModelPutMutationPayload | null)
    delete: (UseCaseSimulationModelDeleteMutation | null)
    __typename: 'UseCaseSimulationModelMutation'
}

export interface UseCaseSimulationModelPutMutationPayload {
    data: (UseCaseSimulationModelObjectType | null)
    errors: ErrorType[]
    clientMutationId: (Scalars['String'] | null)
    __typename: 'UseCaseSimulationModelPutMutationPayload'
}

export interface UseCaseSimulationModelDeleteMutation {
    data: (Scalars['String'] | null)
    __typename: 'UseCaseSimulationModelDeleteMutation'
}

export interface UseCaseLibraryModelMutation {
    put: (UseCaseLibraryModelPutMutationPayload | null)
    delete: (UseCaseLibraryModelDeleteMutation | null)
    __typename: 'UseCaseLibraryModelMutation'
}

export interface UseCaseLibraryModelPutMutationPayload {
    data: (UseCaseLibraryModelObjectType | null)
    errors: ErrorType[]
    clientMutationId: (Scalars['String'] | null)
    __typename: 'UseCaseLibraryModelPutMutationPayload'
}

export interface UseCaseLibraryModelDeleteMutation {
    data: (Scalars['String'] | null)
    __typename: 'UseCaseLibraryModelDeleteMutation'
}

export interface M1PrepModelMutation {
    put: (M1PrepModelPutMutationPayload | null)
    delete: (M1PrepModelDeleteMutation | null)
    __typename: 'M1PrepModelMutation'
}

export interface M1PrepModelPutMutationPayload {
    data: (M1PrepModelObjectType | null)
    errors: ErrorType[]
    clientMutationId: (Scalars['String'] | null)
    __typename: 'M1PrepModelPutMutationPayload'
}

export interface M1PrepModelDeleteMutation {
    data: (Scalars['String'] | null)
    __typename: 'M1PrepModelDeleteMutation'
}

export interface CategoryQuestionModelMutation {
    put: (CategoryQuestionModelPutMutationPayload | null)
    delete: (CategoryQuestionModelDeleteMutation | null)
    __typename: 'CategoryQuestionModelMutation'
}

export interface CategoryQuestionModelPutMutationPayload {
    data: (CategoryQuestionModelObjectType | null)
    errors: ErrorType[]
    clientMutationId: (Scalars['String'] | null)
    __typename: 'CategoryQuestionModelPutMutationPayload'
}

export interface CategoryQuestionModelDeleteMutation {
    data: (Scalars['String'] | null)
    __typename: 'CategoryQuestionModelDeleteMutation'
}

export interface SubCategoryQuestionModelMutation {
    put: (SubCategoryQuestionModelPutMutationPayload | null)
    delete: (SubCategoryQuestionModelDeleteMutation | null)
    __typename: 'SubCategoryQuestionModelMutation'
}

export interface SubCategoryQuestionModelPutMutationPayload {
    data: (SubCategoryQuestionModelObjectType | null)
    errors: ErrorType[]
    clientMutationId: (Scalars['String'] | null)
    __typename: 'SubCategoryQuestionModelPutMutationPayload'
}

export interface SubCategoryQuestionModelDeleteMutation {
    data: (Scalars['String'] | null)
    __typename: 'SubCategoryQuestionModelDeleteMutation'
}

export interface QuestionModelMutation {
    put: (QuestionModelPutMutationPayload | null)
    delete: (QuestionModelDeleteMutation | null)
    __typename: 'QuestionModelMutation'
}

export interface QuestionModelPutMutationPayload {
    data: (QuestionModelObjectType | null)
    errors: ErrorType[]
    clientMutationId: (Scalars['String'] | null)
    __typename: 'QuestionModelPutMutationPayload'
}

export interface QuestionModelDeleteMutation {
    data: (Scalars['String'] | null)
    __typename: 'QuestionModelDeleteMutation'
}

export interface DiagramModelMutation {
    put: (DiagramModelPutMutationPayload | null)
    delete: (DiagramModelDeleteMutation | null)
    __typename: 'DiagramModelMutation'
}

export interface DiagramModelPutMutationPayload {
    data: (DiagramModelObjectType | null)
    errors: ErrorType[]
    clientMutationId: (Scalars['String'] | null)
    __typename: 'DiagramModelPutMutationPayload'
}

export interface DiagramModelDeleteMutation {
    data: (Scalars['String'] | null)
    __typename: 'DiagramModelDeleteMutation'
}

export interface UseCaseModelMutation {
    put: (UseCaseModelPutMutationPayload | null)
    delete: (UseCaseModelDeleteMutation | null)
    __typename: 'UseCaseModelMutation'
}

export interface UseCaseModelPutMutationPayload {
    data: (UseCaseModelObjectType | null)
    errors: ErrorType[]
    clientMutationId: (Scalars['String'] | null)
    __typename: 'UseCaseModelPutMutationPayload'
}

export interface UseCaseModelDeleteMutation {
    data: (Scalars['String'] | null)
    __typename: 'UseCaseModelDeleteMutation'
}

export interface PocModelMutation {
    put: (PocModelPutMutationPayload | null)
    delete: (PocModelDeleteMutation | null)
    __typename: 'PocModelMutation'
}

export interface PocModelPutMutationPayload {
    data: (PocModelObjectType | null)
    errors: ErrorType[]
    clientMutationId: (Scalars['String'] | null)
    __typename: 'PocModelPutMutationPayload'
}

export interface PocModelDeleteMutation {
    data: (Scalars['String'] | null)
    __typename: 'PocModelDeleteMutation'
}

export interface QueryGenqlSelection{
    common?: CommonQueryGenqlSelection
    e2eTaxonomy?: E2ETaxonomyQueryGenqlSelection
    solution?: SolutionQueryGenqlSelection
    useCase?: UseCaseQueryGenqlSelection
    maturity?: MaturityQueryGenqlSelection
    simulation?: SimulationQueryGenqlSelection
    commonData?: CommonDataModelQueryGenqlSelection
    organization?: OrganizationModelQueryGenqlSelection
    categoryFunction?: CategoryFunctionModelQueryGenqlSelection
    diagramE2e?: DiagramE2EModelQueryGenqlSelection
    diagramProcess?: DiagramProcessModelQueryGenqlSelection
    diagramTemplate?: DiagramTemplateModelQueryGenqlSelection
    function?: FunctionModelQueryGenqlSelection
    level1?: Level1ModelQueryGenqlSelection
    level2?: Level2ModelQueryGenqlSelection
    technology?: TechnologyModelQueryGenqlSelection
    aiOpportunity?: AIOpportunityModelQueryGenqlSelection
    assessment?: AssessmentMaturityModelQueryGenqlSelection
    maturityQuestion?: QuestionMaturityModelQueryGenqlSelection
    maturitySubCategoryQuestion?: SubCategoryQuestionMaturityModelQueryGenqlSelection
    maturityCategoryQuestion?: CategoryQuestionMaturityModelQueryGenqlSelection
    context?: EnterpriseContextModelQueryGenqlSelection
    simulationModel?: SimulationModelQueryGenqlSelection
    useCaseSimulation?: UseCaseSimulationModelQueryGenqlSelection
    useCaseLibrary?: UseCaseLibraryModelQueryGenqlSelection
    m1Prep?: M1PrepModelQueryGenqlSelection
    categoryQuestion?: CategoryQuestionModelQueryGenqlSelection
    subCategoryQuestion?: SubCategoryQuestionModelQueryGenqlSelection
    question?: QuestionModelQueryGenqlSelection
    diagram?: DiagramModelQueryGenqlSelection
    useCaseModel?: UseCaseModelQueryGenqlSelection
    poc?: PocModelQueryGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CommonQueryGenqlSelection{
    commonData?: CommonDataModelQueryGenqlSelection
    organization?: OrganizationModelQueryGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CommonDataModelQueryGenqlSelection{
    fetch?: (CommonDataModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (CommonDataModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (CommonDataModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CommonDataModelObjectTypeGenqlSelection{
    key?: boolean | number
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PaginationType {page: Scalars['Int'],size: Scalars['Int']}

export interface OrganizationModelQueryGenqlSelection{
    fetch?: (OrganizationModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (OrganizationModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (OrganizationModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface OrganizationModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    summary?: boolean | number
    taxonomy?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface E2ETaxonomyQueryGenqlSelection{
    categoryFunction?: CategoryFunctionModelQueryGenqlSelection
    diagramE2e?: DiagramE2EModelQueryGenqlSelection
    diagramProcess?: DiagramProcessModelQueryGenqlSelection
    diagramTemplate?: DiagramTemplateModelQueryGenqlSelection
    function?: FunctionModelQueryGenqlSelection
    level1?: Level1ModelQueryGenqlSelection
    level2?: Level2ModelQueryGenqlSelection
    technology?: TechnologyModelQueryGenqlSelection
    aiOpportunity?: AIOpportunityModelQueryGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CategoryFunctionModelQueryGenqlSelection{
    fetch?: (CategoryFunctionModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (CategoryFunctionModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (CategoryFunctionModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CategoryFunctionModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    short?: boolean | number
    sort?: boolean | number
    type?: boolean | number
    office?: boolean | number
    functions?: (FunctionModelObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    functionsIds?: { __args: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} } | boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface FunctionModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    short?: boolean | number
    name?: boolean | number
    description?: boolean | number
    sort?: boolean | number
    type?: boolean | number
    overall?: boolean | number
    category?: CategoryFunctionModelObjectTypeGenqlSelection
    categoryId?: boolean | number
    organization?: OrganizationModelObjectTypeGenqlSelection
    organizationId?: boolean | number
    levels1?: (Level1ModelObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    levels1Ids?: { __args: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} } | boolean | number
    diagram?: DiagramE2EModelObjectTypeGenqlSelection
    diagramId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface Level1ModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    description?: boolean | number
    spend?: boolean | number
    sme?: boolean | number
    overall?: boolean | number
    type?: boolean | number
    function?: FunctionModelObjectTypeGenqlSelection
    functionId?: boolean | number
    organization?: OrganizationModelObjectTypeGenqlSelection
    organizationId?: boolean | number
    levels2?: (Level2ModelObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    levels2Ids?: { __args: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} } | boolean | number
    diagram?: DiagramProcessModelObjectTypeGenqlSelection
    diagramId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface Level2ModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    description?: boolean | number
    values?: boolean | number
    type?: boolean | number
    level1?: Level1ModelObjectTypeGenqlSelection
    level1Id?: boolean | number
    diagram?: DiagramTemplateModelObjectTypeGenqlSelection
    diagramId?: boolean | number
    organization?: OrganizationModelObjectTypeGenqlSelection
    organizationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramTemplateModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    data?: boolean | number
    xmlData?: boolean | number
    xmlImage?: boolean | number
    level2?: Level2ModelObjectTypeGenqlSelection
    level2Id?: boolean | number
    opportunities?: (AIOpportunityModelObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    opportunitiesIds?: { __args: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} } | boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AIOpportunityModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    shapeId?: boolean | number
    name?: boolean | number
    description?: boolean | number
    checked?: boolean | number
    valid?: boolean | number
    type?: boolean | number
    data?: boolean | number
    poc?: PocModelObjectTypeGenqlSelection
    pocId?: boolean | number
    diagram?: DiagramTemplateModelObjectTypeGenqlSelection
    diagramId?: boolean | number
    useCase?: UseCaseModelObjectTypeGenqlSelection
    useCaseId?: boolean | number
    technologies?: (TechnologyModelObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    technologiesIds?: { __args: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} } | boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PocModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    title?: boolean | number
    description?: boolean | number
    approved?: boolean | number
    generalInformation?: boolean | number
    functionalRequirements?: boolean | number
    noFunctionalRequirements?: boolean | number
    pocRequirements?: boolean | number
    integratedRequirements?: boolean | number
    inputsDataSource?: boolean | number
    genAIChecklist?: boolean | number
    deploymentHandshake?: boolean | number
    effortAndCostEstimation?: boolean | number
    organization?: OrganizationModelObjectTypeGenqlSelection
    organizationId?: boolean | number
    useCase?: UseCaseModelObjectTypeGenqlSelection
    useCaseId?: boolean | number
    aiEnablers?: (TechnologyModelObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    aiEnablersIds?: { __args: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} } | boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UseCaseModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    description?: boolean | number
    generalData?: boolean | number
    formData?: boolean | number
    workPlanData?: boolean | number
    aiValues?: boolean | number
    hubble?: boolean | number
    benefits?: boolean | number
    benefitsValues?: boolean | number
    benefitsOrder?: boolean | number
    simulationData?: boolean | number
    libraryData?: boolean | number
    nexus?: boolean | number
    organization?: OrganizationModelObjectTypeGenqlSelection
    organizationId?: boolean | number
    diagram?: DiagramModelObjectTypeGenqlSelection
    diagramId?: boolean | number
    levels2?: (Level2ModelObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    levels2Ids?: { __args: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} } | boolean | number
    opportunities?: (AIOpportunityModelObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    opportunitiesIds?: { __args: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} } | boolean | number
    technologies?: (TechnologyModelObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    technologiesIds?: { __args: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} } | boolean | number
    pocs?: (PocModelObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    pocsIds?: { __args: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} } | boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    data?: boolean | number
    xmlData?: boolean | number
    xmlImage?: boolean | number
    organization?: OrganizationModelObjectTypeGenqlSelection
    organizationId?: boolean | number
    useCase?: UseCaseModelObjectTypeGenqlSelection
    useCaseId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface TechnologyModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    short?: boolean | number
    neto?: boolean | number
    svg?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramProcessModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    data?: boolean | number
    xmlData?: boolean | number
    xmlImage?: boolean | number
    level1?: Level1ModelObjectTypeGenqlSelection
    level1Id?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramE2EModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    data?: boolean | number
    xmlData?: boolean | number
    xmlImage?: boolean | number
    function?: FunctionModelObjectTypeGenqlSelection
    functionId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramE2EModelQueryGenqlSelection{
    fetch?: (DiagramE2EModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (DiagramE2EModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (DiagramE2EModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramProcessModelQueryGenqlSelection{
    fetch?: (DiagramProcessModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (DiagramProcessModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (DiagramProcessModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramTemplateModelQueryGenqlSelection{
    fetch?: (DiagramTemplateModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (DiagramTemplateModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (DiagramTemplateModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface FunctionModelQueryGenqlSelection{
    fetch?: (FunctionModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (FunctionModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (FunctionModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface Level1ModelQueryGenqlSelection{
    fetch?: (Level1ModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (Level1ModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (Level1ModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface Level2ModelQueryGenqlSelection{
    fetch?: (Level2ModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (Level2ModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (Level2ModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface TechnologyModelQueryGenqlSelection{
    fetch?: (TechnologyModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (TechnologyModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (TechnologyModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AIOpportunityModelQueryGenqlSelection{
    fetch?: (AIOpportunityModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (AIOpportunityModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (AIOpportunityModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SolutionQueryGenqlSelection{
    categoryQuestion?: CategoryQuestionModelQueryGenqlSelection
    subCategoryQuestion?: SubCategoryQuestionModelQueryGenqlSelection
    question?: QuestionModelQueryGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CategoryQuestionModelQueryGenqlSelection{
    fetch?: (CategoryQuestionModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (CategoryQuestionModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (CategoryQuestionModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CategoryQuestionModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    text?: boolean | number
    sort?: boolean | number
    subCategories?: SubCategoryQuestionModelObjectTypeGenqlSelection
    subCategoriesid?: boolean | number
    questions?: (QuestionModelObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    questionsIds?: { __args: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} } | boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SubCategoryQuestionModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    text?: boolean | number
    sort?: boolean | number
    category?: CategoryQuestionModelObjectTypeGenqlSelection
    categoryId?: boolean | number
    questions?: (QuestionModelObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    questionsIds?: { __args: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} } | boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface QuestionModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    text?: boolean | number
    weight?: boolean | number
    sort?: boolean | number
    answers?: boolean | number
    weightJson?: boolean | number
    tag?: boolean | number
    category?: CategoryQuestionModelObjectTypeGenqlSelection
    categoryId?: boolean | number
    subcategory?: SubCategoryQuestionModelObjectTypeGenqlSelection
    subcategoryId?: boolean | number
    technology?: TechnologyModelObjectTypeGenqlSelection
    technologyId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SubCategoryQuestionModelQueryGenqlSelection{
    fetch?: (SubCategoryQuestionModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (SubCategoryQuestionModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (SubCategoryQuestionModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface QuestionModelQueryGenqlSelection{
    fetch?: (QuestionModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (QuestionModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (QuestionModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UseCaseQueryGenqlSelection{
    diagram?: DiagramModelQueryGenqlSelection
    useCase?: UseCaseModelQueryGenqlSelection
    poc?: PocModelQueryGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramModelQueryGenqlSelection{
    fetch?: (DiagramModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (DiagramModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (DiagramModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UseCaseModelQueryGenqlSelection{
    fetch?: (UseCaseModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (UseCaseModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (UseCaseModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PocModelQueryGenqlSelection{
    fetch?: (PocModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (PocModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (PocModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface MaturityQueryGenqlSelection{
    assessment?: AssessmentMaturityModelQueryGenqlSelection
    question?: QuestionMaturityModelQueryGenqlSelection
    subCategoryQuestion?: SubCategoryQuestionMaturityModelQueryGenqlSelection
    categoryQuestion?: CategoryQuestionMaturityModelQueryGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AssessmentMaturityModelQueryGenqlSelection{
    fetch?: (AssessmentMaturityModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (AssessmentMaturityModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (AssessmentMaturityModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AssessmentMaturityModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    content?: boolean | number
    formData?: boolean | number
    commentData?: boolean | number
    recommendations?: boolean | number
    organization?: OrganizationModelObjectTypeGenqlSelection
    organizationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface QuestionMaturityModelQueryGenqlSelection{
    fetch?: (QuestionMaturityModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (QuestionMaturityModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (QuestionMaturityModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface QuestionMaturityModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    text?: boolean | number
    sort?: boolean | number
    category?: CategoryQuestionMaturityModelObjectTypeGenqlSelection
    categoryId?: boolean | number
    subcategory?: SubCategoryQuestionMaturityModelObjectTypeGenqlSelection
    subcategoryId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CategoryQuestionMaturityModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    sort?: boolean | number
    subCategories?: (SubCategoryQuestionMaturityModelObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    subCategoriesids?: { __args: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} } | boolean | number
    questions?: (QuestionMaturityModelObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    questionsIds?: { __args: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} } | boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SubCategoryQuestionMaturityModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    sort?: boolean | number
    category?: CategoryQuestionMaturityModelObjectTypeGenqlSelection
    categoryId?: boolean | number
    questions?: (QuestionMaturityModelObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    questionsIds?: { __args: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} } | boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SubCategoryQuestionMaturityModelQueryGenqlSelection{
    fetch?: (SubCategoryQuestionMaturityModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (SubCategoryQuestionMaturityModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (SubCategoryQuestionMaturityModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CategoryQuestionMaturityModelQueryGenqlSelection{
    fetch?: (CategoryQuestionMaturityModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (CategoryQuestionMaturityModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (CategoryQuestionMaturityModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SimulationQueryGenqlSelection{
    context?: EnterpriseContextModelQueryGenqlSelection
    simulation?: SimulationModelQueryGenqlSelection
    useCaseSimulation?: UseCaseSimulationModelQueryGenqlSelection
    useCaseLibrary?: UseCaseLibraryModelQueryGenqlSelection
    m1Prep?: M1PrepModelQueryGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface EnterpriseContextModelQueryGenqlSelection{
    fetch?: (EnterpriseContextModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (EnterpriseContextModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (EnterpriseContextModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface EnterpriseContextModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    enterpriseContext?: boolean | number
    organization?: OrganizationModelObjectTypeGenqlSelection
    organizationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SimulationModelQueryGenqlSelection{
    fetch?: (SimulationModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (SimulationModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (SimulationModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SimulationModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    description?: boolean | number
    notes?: boolean | number
    search?: boolean | number
    generate?: boolean | number
    taxonomyId?: boolean | number
    taxonomyRel?: boolean | number
    enterpriseContext?: boolean | number
    simulationContext?: boolean | number
    useCases?: (UseCaseSimulationModelObjectTypeGenqlSelection & { __args?: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    useCasesids?: { __args: {query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} } | boolean | number
    organization?: OrganizationModelObjectTypeGenqlSelection
    organizationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UseCaseSimulationModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    description?: boolean | number
    deployedId?: boolean | number
    keyFeatures?: boolean | number
    context?: boolean | number
    data?: boolean | number
    valid?: boolean | number
    type?: boolean | number
    organization?: OrganizationModelObjectTypeGenqlSelection
    organizationId?: boolean | number
    simulation?: SimulationModelObjectTypeGenqlSelection
    simulationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UseCaseSimulationModelQueryGenqlSelection{
    fetch?: (UseCaseSimulationModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (UseCaseSimulationModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (UseCaseSimulationModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UseCaseLibraryModelQueryGenqlSelection{
    fetch?: (UseCaseLibraryModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (UseCaseLibraryModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (UseCaseLibraryModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UseCaseLibraryModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    name?: boolean | number
    description?: boolean | number
    data?: boolean | number
    sourceId?: boolean | number
    sourceRel?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface M1PrepModelQueryGenqlSelection{
    fetch?: (M1PrepModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null), pagination?: (PaginationType | null)} })
    count?: { __args: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} } | boolean | number
    get?: (M1PrepModelObjectTypeGenqlSelection & { __args?: {organizationId?: (Scalars['String'] | null), query?: (Scalars['GenericScalar'] | null), sort?: ((Scalars['String'] | null)[] | null)} })
    all?: (M1PrepModelObjectTypeGenqlSelection & { __args?: {dtUnix?: (Scalars['BigInt'] | null), organizationId?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface M1PrepModelObjectTypeGenqlSelection{
    id?: boolean | number
    createdAt?: boolean | number
    updatedAt?: boolean | number
    deletedAt?: boolean | number
    context?: boolean | number
    companyAnalysis?: boolean | number
    products?: boolean | number
    competitiveLandscape?: boolean | number
    conversation?: boolean | number
    salesforce?: boolean | number
    organization?: OrganizationModelObjectTypeGenqlSelection
    organizationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface MutationGenqlSelection{
    categoryFunction?: CategoryFunctionModelMutationGenqlSelection
    aiOpportunity?: AIOpportunityModelMutationGenqlSelection
    diagramE2e?: DiagramE2EModelMutationGenqlSelection
    diagramProcess?: DiagramProcessModelMutationGenqlSelection
    diagramTemplate?: DiagramTemplateModelMutationGenqlSelection
    function?: FunctionModelMutationGenqlSelection
    level1?: Level1ModelMutationGenqlSelection
    level2?: Level2ModelMutationGenqlSelection
    technology?: TechnologyModelMutationGenqlSelection
    assessment?: AssessmentMaturityModelMutationGenqlSelection
    maturityQuestion?: QuestionMaturityModelMutationGenqlSelection
    maturitySubCategoryQuestion?: SubCategoryQuestionMaturityModelMutationGenqlSelection
    maturityCategoryQuestion?: CategoryQuestionMaturityModelMutationGenqlSelection
    context?: EnterpriseContextModelMutationGenqlSelection
    simulation?: SimulationModelMutationGenqlSelection
    useCaseSimulation?: UseCaseSimulationModelMutationGenqlSelection
    useCaseLibrary?: UseCaseLibraryModelMutationGenqlSelection
    m1Prep?: M1PrepModelMutationGenqlSelection
    categoryQuestion?: CategoryQuestionModelMutationGenqlSelection
    subCategoryQuestion?: SubCategoryQuestionModelMutationGenqlSelection
    question?: QuestionModelMutationGenqlSelection
    diagram?: DiagramModelMutationGenqlSelection
    useCase?: UseCaseModelMutationGenqlSelection
    poc?: PocModelMutationGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CategoryFunctionModelMutationGenqlSelection{
    put?: (CategoryFunctionModelPutMutationPayloadGenqlSelection & { __args: {input: CategoryFunctionModelPutMutationInput} })
    delete?: (CategoryFunctionModelDeleteMutationGenqlSelection & { __args?: {id?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CategoryFunctionModelPutMutationPayloadGenqlSelection{
    data?: CategoryFunctionModelObjectTypeGenqlSelection
    errors?: ErrorTypeGenqlSelection
    clientMutationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ErrorTypeGenqlSelection{
    field?: boolean | number
    messages?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CategoryFunctionModelPutMutationInput {office?: (Scalars['String'] | null),short?: (Scalars['String'] | null),sort?: (Scalars['Int'] | null),type?: (Scalars['String'] | null),name?: (Scalars['String'] | null),id?: (Scalars['ID'] | null),clientMutationId?: (Scalars['String'] | null)}

export interface CategoryFunctionModelDeleteMutationGenqlSelection{
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AIOpportunityModelMutationGenqlSelection{
    put?: (AIOpportunityModelPutMutationPayloadGenqlSelection & { __args: {input: AIOpportunityModelPutMutationInput} })
    delete?: (AIOpportunityModelDeleteMutationGenqlSelection & { __args?: {id?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AIOpportunityModelPutMutationPayloadGenqlSelection{
    data?: AIOpportunityModelObjectTypeGenqlSelection
    errors?: ErrorTypeGenqlSelection
    clientMutationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AIOpportunityModelPutMutationInput {shapeId?: (Scalars['String'] | null),data?: (Scalars['String'] | null),useCase?: (Scalars['ID'] | null),diagram?: (Scalars['ID'] | null),type?: (Scalars['String'] | null),valid?: (Scalars['Boolean'] | null),checked?: (Scalars['Boolean'] | null),description?: (Scalars['String'] | null),name?: (Scalars['String'] | null),id?: (Scalars['ID'] | null),clientMutationId?: (Scalars['String'] | null)}

export interface AIOpportunityModelDeleteMutationGenqlSelection{
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramE2EModelMutationGenqlSelection{
    put?: (DiagramE2EModelPutMutationPayloadGenqlSelection & { __args: {input: DiagramE2EModelPutMutationInput} })
    delete?: (DiagramE2EModelDeleteMutationGenqlSelection & { __args?: {id?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramE2EModelPutMutationPayloadGenqlSelection{
    data?: DiagramE2EModelObjectTypeGenqlSelection
    errors?: ErrorTypeGenqlSelection
    clientMutationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramE2EModelPutMutationInput {data?: (Scalars['String'] | null),function?: (Scalars['ID'] | null),id?: (Scalars['ID'] | null),clientMutationId?: (Scalars['String'] | null)}

export interface DiagramE2EModelDeleteMutationGenqlSelection{
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramProcessModelMutationGenqlSelection{
    put?: (DiagramProcessModelPutMutationPayloadGenqlSelection & { __args: {input: DiagramProcessModelPutMutationInput} })
    delete?: (DiagramProcessModelDeleteMutationGenqlSelection & { __args?: {id?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramProcessModelPutMutationPayloadGenqlSelection{
    data?: DiagramProcessModelObjectTypeGenqlSelection
    errors?: ErrorTypeGenqlSelection
    clientMutationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramProcessModelPutMutationInput {data?: (Scalars['String'] | null),level1?: (Scalars['ID'] | null),id?: (Scalars['ID'] | null),clientMutationId?: (Scalars['String'] | null)}

export interface DiagramProcessModelDeleteMutationGenqlSelection{
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramTemplateModelMutationGenqlSelection{
    put?: (DiagramTemplateModelPutMutationPayloadGenqlSelection & { __args: {input: DiagramTemplateModelPutMutationInput} })
    delete?: (DiagramTemplateModelDeleteMutationGenqlSelection & { __args?: {id?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramTemplateModelPutMutationPayloadGenqlSelection{
    data?: DiagramTemplateModelObjectTypeGenqlSelection
    errors?: ErrorTypeGenqlSelection
    clientMutationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramTemplateModelPutMutationInput {data?: (Scalars['String'] | null),level2?: (Scalars['ID'] | null),id?: (Scalars['ID'] | null),clientMutationId?: (Scalars['String'] | null)}

export interface DiagramTemplateModelDeleteMutationGenqlSelection{
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface FunctionModelMutationGenqlSelection{
    put?: (FunctionModelPutMutationPayloadGenqlSelection & { __args: {input: FunctionModelPutMutationInput} })
    delete?: (FunctionModelDeleteMutationGenqlSelection & { __args?: {id?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface FunctionModelPutMutationPayloadGenqlSelection{
    data?: FunctionModelObjectTypeGenqlSelection
    errors?: ErrorTypeGenqlSelection
    clientMutationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface FunctionModelPutMutationInput {short?: (Scalars['String'] | null),sort?: (Scalars['Int'] | null),type?: (Scalars['String'] | null),description?: (Scalars['String'] | null),name?: (Scalars['String'] | null),id?: (Scalars['ID'] | null),clientMutationId?: (Scalars['String'] | null)}

export interface FunctionModelDeleteMutationGenqlSelection{
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface Level1ModelMutationGenqlSelection{
    put?: (Level1ModelPutMutationPayloadGenqlSelection & { __args: {input: Level1ModelPutMutationInput} })
    delete?: (Level1ModelDeleteMutationGenqlSelection & { __args?: {id?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface Level1ModelPutMutationPayloadGenqlSelection{
    data?: Level1ModelObjectTypeGenqlSelection
    errors?: ErrorTypeGenqlSelection
    clientMutationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface Level1ModelPutMutationInput {overall?: (Scalars['Int'] | null),sme?: (Scalars['Int'] | null),spend?: (Scalars['Int'] | null),type?: (Scalars['String'] | null),description?: (Scalars['String'] | null),name?: (Scalars['String'] | null),id?: (Scalars['ID'] | null),clientMutationId?: (Scalars['String'] | null)}

export interface Level1ModelDeleteMutationGenqlSelection{
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface Level2ModelMutationGenqlSelection{
    put?: (Level2ModelPutMutationPayloadGenqlSelection & { __args: {input: Level2ModelPutMutationInput} })
    delete?: (Level2ModelDeleteMutationGenqlSelection & { __args?: {id?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface Level2ModelPutMutationPayloadGenqlSelection{
    data?: Level2ModelObjectTypeGenqlSelection
    errors?: ErrorTypeGenqlSelection
    clientMutationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface Level2ModelPutMutationInput {values?: (Scalars['String'] | null),type?: (Scalars['String'] | null),description?: (Scalars['String'] | null),name?: (Scalars['String'] | null),id?: (Scalars['ID'] | null),clientMutationId?: (Scalars['String'] | null)}

export interface Level2ModelDeleteMutationGenqlSelection{
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface TechnologyModelMutationGenqlSelection{
    put?: (TechnologyModelPutMutationPayloadGenqlSelection & { __args: {input: TechnologyModelPutMutationInput} })
    delete?: (TechnologyModelDeleteMutationGenqlSelection & { __args?: {id?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface TechnologyModelPutMutationPayloadGenqlSelection{
    data?: TechnologyModelObjectTypeGenqlSelection
    errors?: ErrorTypeGenqlSelection
    clientMutationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface TechnologyModelPutMutationInput {svg?: (Scalars['String'] | null),neto?: (Scalars['Boolean'] | null),short?: (Scalars['String'] | null),name?: (Scalars['String'] | null),id?: (Scalars['ID'] | null),clientMutationId?: (Scalars['String'] | null)}

export interface TechnologyModelDeleteMutationGenqlSelection{
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AssessmentMaturityModelMutationGenqlSelection{
    put?: (AssessmentMaturityModelPutMutationPayloadGenqlSelection & { __args: {input: AssessmentMaturityModelPutMutationInput} })
    delete?: (AssessmentMaturityModelDeleteMutationGenqlSelection & { __args?: {id?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AssessmentMaturityModelPutMutationPayloadGenqlSelection{
    data?: AssessmentMaturityModelObjectTypeGenqlSelection
    errors?: ErrorTypeGenqlSelection
    clientMutationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface AssessmentMaturityModelPutMutationInput {formData?: (Scalars['String'] | null),commentData?: (Scalars['String'] | null),recommendations?: (Scalars['String'] | null),content?: (Scalars['String'] | null),id?: (Scalars['ID'] | null),clientMutationId?: (Scalars['String'] | null)}

export interface AssessmentMaturityModelDeleteMutationGenqlSelection{
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface QuestionMaturityModelMutationGenqlSelection{
    put?: (QuestionMaturityModelPutMutationPayloadGenqlSelection & { __args: {input: QuestionMaturityModelPutMutationInput} })
    delete?: (QuestionMaturityModelDeleteMutationGenqlSelection & { __args?: {id?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface QuestionMaturityModelPutMutationPayloadGenqlSelection{
    data?: QuestionMaturityModelObjectTypeGenqlSelection
    errors?: ErrorTypeGenqlSelection
    clientMutationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface QuestionMaturityModelPutMutationInput {text?: (Scalars['String'] | null),sort?: (Scalars['Int'] | null),id?: (Scalars['ID'] | null),clientMutationId?: (Scalars['String'] | null)}

export interface QuestionMaturityModelDeleteMutationGenqlSelection{
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SubCategoryQuestionMaturityModelMutationGenqlSelection{
    put?: (SubCategoryQuestionMaturityModelPutMutationPayloadGenqlSelection & { __args: {input: SubCategoryQuestionMaturityModelPutMutationInput} })
    delete?: (SubCategoryQuestionMaturityModelDeleteMutationGenqlSelection & { __args?: {id?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SubCategoryQuestionMaturityModelPutMutationPayloadGenqlSelection{
    data?: SubCategoryQuestionMaturityModelObjectTypeGenqlSelection
    errors?: ErrorTypeGenqlSelection
    clientMutationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SubCategoryQuestionMaturityModelPutMutationInput {name?: (Scalars['String'] | null),sort?: (Scalars['Int'] | null),id?: (Scalars['ID'] | null),clientMutationId?: (Scalars['String'] | null)}

export interface SubCategoryQuestionMaturityModelDeleteMutationGenqlSelection{
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CategoryQuestionMaturityModelMutationGenqlSelection{
    put?: (CategoryQuestionMaturityModelPutMutationPayloadGenqlSelection & { __args: {input: CategoryQuestionMaturityModelPutMutationInput} })
    delete?: (CategoryQuestionMaturityModelDeleteMutationGenqlSelection & { __args?: {id?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CategoryQuestionMaturityModelPutMutationPayloadGenqlSelection{
    data?: CategoryQuestionMaturityModelObjectTypeGenqlSelection
    errors?: ErrorTypeGenqlSelection
    clientMutationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CategoryQuestionMaturityModelPutMutationInput {name?: (Scalars['String'] | null),sort?: (Scalars['Int'] | null),id?: (Scalars['ID'] | null),clientMutationId?: (Scalars['String'] | null)}

export interface CategoryQuestionMaturityModelDeleteMutationGenqlSelection{
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface EnterpriseContextModelMutationGenqlSelection{
    put?: (EnterpriseContextModelPutMutationPayloadGenqlSelection & { __args: {input: EnterpriseContextModelPutMutationInput} })
    delete?: (EnterpriseContextModelDeleteMutationGenqlSelection & { __args?: {id?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface EnterpriseContextModelPutMutationPayloadGenqlSelection{
    data?: EnterpriseContextModelObjectTypeGenqlSelection
    errors?: ErrorTypeGenqlSelection
    clientMutationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface EnterpriseContextModelPutMutationInput {enterpriseContext?: (Scalars['String'] | null),id?: (Scalars['ID'] | null),clientMutationId?: (Scalars['String'] | null)}

export interface EnterpriseContextModelDeleteMutationGenqlSelection{
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SimulationModelMutationGenqlSelection{
    put?: (SimulationModelPutMutationPayloadGenqlSelection & { __args: {input: SimulationModelPutMutationInput} })
    delete?: (SimulationModelDeleteMutationGenqlSelection & { __args?: {id?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SimulationModelPutMutationPayloadGenqlSelection{
    data?: SimulationModelObjectTypeGenqlSelection
    errors?: ErrorTypeGenqlSelection
    clientMutationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SimulationModelPutMutationInput {taxonomyId?: (Scalars['UUID'] | null),simulationContext?: (Scalars['String'] | null),search?: (Scalars['Boolean'] | null),notes?: (Scalars['String'] | null),enterpriseContext?: (Scalars['String'] | null),taxonomyRel?: (Scalars['String'] | null),generate?: (Scalars['Boolean'] | null),description?: (Scalars['String'] | null),name?: (Scalars['String'] | null),id?: (Scalars['ID'] | null),clientMutationId?: (Scalars['String'] | null)}

export interface SimulationModelDeleteMutationGenqlSelection{
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UseCaseSimulationModelMutationGenqlSelection{
    put?: (UseCaseSimulationModelPutMutationPayloadGenqlSelection & { __args: {input: UseCaseSimulationModelPutMutationInput} })
    delete?: (UseCaseSimulationModelDeleteMutationGenqlSelection & { __args?: {id?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UseCaseSimulationModelPutMutationPayloadGenqlSelection{
    data?: UseCaseSimulationModelObjectTypeGenqlSelection
    errors?: ErrorTypeGenqlSelection
    clientMutationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UseCaseSimulationModelPutMutationInput {context?: (Scalars['String'] | null),data?: (Scalars['String'] | null),deployedId?: (Scalars['UUID'] | null),type?: (Scalars['String'] | null),valid?: (Scalars['Boolean'] | null),keyFeatures?: (Scalars['String'] | null),description?: (Scalars['String'] | null),name?: (Scalars['String'] | null),id?: (Scalars['ID'] | null),clientMutationId?: (Scalars['String'] | null)}

export interface UseCaseSimulationModelDeleteMutationGenqlSelection{
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UseCaseLibraryModelMutationGenqlSelection{
    put?: (UseCaseLibraryModelPutMutationPayloadGenqlSelection & { __args: {input: UseCaseLibraryModelPutMutationInput} })
    delete?: (UseCaseLibraryModelDeleteMutationGenqlSelection & { __args?: {id?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UseCaseLibraryModelPutMutationPayloadGenqlSelection{
    data?: UseCaseLibraryModelObjectTypeGenqlSelection
    errors?: ErrorTypeGenqlSelection
    clientMutationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UseCaseLibraryModelPutMutationInput {data?: (Scalars['String'] | null),sourceId?: (Scalars['UUID'] | null),sourceRel?: (Scalars['String'] | null),description?: (Scalars['String'] | null),name?: (Scalars['String'] | null),id?: (Scalars['ID'] | null),clientMutationId?: (Scalars['String'] | null)}

export interface UseCaseLibraryModelDeleteMutationGenqlSelection{
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface M1PrepModelMutationGenqlSelection{
    put?: (M1PrepModelPutMutationPayloadGenqlSelection & { __args: {input: M1PrepModelPutMutationInput} })
    delete?: (M1PrepModelDeleteMutationGenqlSelection & { __args?: {id?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface M1PrepModelPutMutationPayloadGenqlSelection{
    data?: M1PrepModelObjectTypeGenqlSelection
    errors?: ErrorTypeGenqlSelection
    clientMutationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface M1PrepModelPutMutationInput {companyAnalysis?: (Scalars['String'] | null),context?: (Scalars['String'] | null),competitiveLandscape?: (Scalars['String'] | null),products?: (Scalars['String'] | null),conversation?: (Scalars['String'] | null),salesforce?: (Scalars['String'] | null),id?: (Scalars['ID'] | null),clientMutationId?: (Scalars['String'] | null)}

export interface M1PrepModelDeleteMutationGenqlSelection{
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CategoryQuestionModelMutationGenqlSelection{
    put?: (CategoryQuestionModelPutMutationPayloadGenqlSelection & { __args: {input: CategoryQuestionModelPutMutationInput} })
    delete?: (CategoryQuestionModelDeleteMutationGenqlSelection & { __args?: {id?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CategoryQuestionModelPutMutationPayloadGenqlSelection{
    data?: CategoryQuestionModelObjectTypeGenqlSelection
    errors?: ErrorTypeGenqlSelection
    clientMutationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CategoryQuestionModelPutMutationInput {name?: (Scalars['String'] | null),text?: (Scalars['String'] | null),sort?: (Scalars['Int'] | null),id?: (Scalars['ID'] | null),clientMutationId?: (Scalars['String'] | null)}

export interface CategoryQuestionModelDeleteMutationGenqlSelection{
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SubCategoryQuestionModelMutationGenqlSelection{
    put?: (SubCategoryQuestionModelPutMutationPayloadGenqlSelection & { __args: {input: SubCategoryQuestionModelPutMutationInput} })
    delete?: (SubCategoryQuestionModelDeleteMutationGenqlSelection & { __args?: {id?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SubCategoryQuestionModelPutMutationPayloadGenqlSelection{
    data?: SubCategoryQuestionModelObjectTypeGenqlSelection
    errors?: ErrorTypeGenqlSelection
    clientMutationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SubCategoryQuestionModelPutMutationInput {name?: (Scalars['String'] | null),text?: (Scalars['String'] | null),sort?: (Scalars['Int'] | null),id?: (Scalars['ID'] | null),clientMutationId?: (Scalars['String'] | null)}

export interface SubCategoryQuestionModelDeleteMutationGenqlSelection{
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface QuestionModelMutationGenqlSelection{
    put?: (QuestionModelPutMutationPayloadGenqlSelection & { __args: {input: QuestionModelPutMutationInput} })
    delete?: (QuestionModelDeleteMutationGenqlSelection & { __args?: {id?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface QuestionModelPutMutationPayloadGenqlSelection{
    data?: QuestionModelObjectTypeGenqlSelection
    errors?: ErrorTypeGenqlSelection
    clientMutationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface QuestionModelPutMutationInput {tag?: (Scalars['String'] | null),answers?: (Scalars['String'] | null),text?: (Scalars['String'] | null),weightJson?: (Scalars['String'] | null),weight?: (Scalars['Decimal'] | null),sort?: (Scalars['Int'] | null),id?: (Scalars['ID'] | null),clientMutationId?: (Scalars['String'] | null)}

export interface QuestionModelDeleteMutationGenqlSelection{
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramModelMutationGenqlSelection{
    put?: (DiagramModelPutMutationPayloadGenqlSelection & { __args: {input: DiagramModelPutMutationInput} })
    delete?: (DiagramModelDeleteMutationGenqlSelection & { __args?: {id?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramModelPutMutationPayloadGenqlSelection{
    data?: DiagramModelObjectTypeGenqlSelection
    errors?: ErrorTypeGenqlSelection
    clientMutationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface DiagramModelPutMutationInput {useCase?: (Scalars['ID'] | null),data?: (Scalars['String'] | null),id?: (Scalars['ID'] | null),clientMutationId?: (Scalars['String'] | null)}

export interface DiagramModelDeleteMutationGenqlSelection{
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UseCaseModelMutationGenqlSelection{
    put?: (UseCaseModelPutMutationPayloadGenqlSelection & { __args: {input: UseCaseModelPutMutationInput} })
    delete?: (UseCaseModelDeleteMutationGenqlSelection & { __args?: {id?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UseCaseModelPutMutationPayloadGenqlSelection{
    data?: UseCaseModelObjectTypeGenqlSelection
    errors?: ErrorTypeGenqlSelection
    clientMutationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UseCaseModelPutMutationInput {benefitsValues?: (Scalars['String'] | null),benefits?: (Scalars['Boolean'] | null),nexus?: (Scalars['String'] | null),libraryData?: (Scalars['String'] | null),simulationData?: (Scalars['String'] | null),name?: (Scalars['String'] | null),benefitsOrder?: (Scalars['String'] | null),hubble?: (Scalars['Boolean'] | null),formData?: (Scalars['String'] | null),aiValues?: (Scalars['String'] | null),generalData?: (Scalars['String'] | null),description?: (Scalars['String'] | null),workPlanData?: (Scalars['String'] | null),id?: (Scalars['ID'] | null),clientMutationId?: (Scalars['String'] | null)}

export interface UseCaseModelDeleteMutationGenqlSelection{
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PocModelMutationGenqlSelection{
    put?: (PocModelPutMutationPayloadGenqlSelection & { __args: {input: PocModelPutMutationInput} })
    delete?: (PocModelDeleteMutationGenqlSelection & { __args?: {id?: (Scalars['String'] | null)} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PocModelPutMutationPayloadGenqlSelection{
    data?: PocModelObjectTypeGenqlSelection
    errors?: ErrorTypeGenqlSelection
    clientMutationId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface PocModelPutMutationInput {functionalRequirements?: (Scalars['String'] | null),integratedRequirements?: (Scalars['String'] | null),generalInformation?: (Scalars['String'] | null),genAIChecklist?: (Scalars['String'] | null),title?: (Scalars['String'] | null),inputsDataSource?: (Scalars['String'] | null),pocRequirements?: (Scalars['String'] | null),deploymentHandshake?: (Scalars['String'] | null),noFunctionalRequirements?: (Scalars['String'] | null),description?: (Scalars['String'] | null),id?: (Scalars['ID'] | null),clientMutationId?: (Scalars['String'] | null)}

export interface PocModelDeleteMutationGenqlSelection{
    data?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}


    const Query_possibleTypes: string[] = ['Query']
    export const isQuery = (obj?: { __typename?: any } | null): obj is Query => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isQuery"')
      return Query_possibleTypes.includes(obj.__typename)
    }
    


    const CommonQuery_possibleTypes: string[] = ['CommonQuery']
    export const isCommonQuery = (obj?: { __typename?: any } | null): obj is CommonQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCommonQuery"')
      return CommonQuery_possibleTypes.includes(obj.__typename)
    }
    


    const CommonDataModelQuery_possibleTypes: string[] = ['CommonDataModelQuery']
    export const isCommonDataModelQuery = (obj?: { __typename?: any } | null): obj is CommonDataModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCommonDataModelQuery"')
      return CommonDataModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const CommonDataModelObjectType_possibleTypes: string[] = ['CommonDataModelObjectType']
    export const isCommonDataModelObjectType = (obj?: { __typename?: any } | null): obj is CommonDataModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCommonDataModelObjectType"')
      return CommonDataModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const OrganizationModelQuery_possibleTypes: string[] = ['OrganizationModelQuery']
    export const isOrganizationModelQuery = (obj?: { __typename?: any } | null): obj is OrganizationModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isOrganizationModelQuery"')
      return OrganizationModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const OrganizationModelObjectType_possibleTypes: string[] = ['OrganizationModelObjectType']
    export const isOrganizationModelObjectType = (obj?: { __typename?: any } | null): obj is OrganizationModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isOrganizationModelObjectType"')
      return OrganizationModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const E2ETaxonomyQuery_possibleTypes: string[] = ['E2ETaxonomyQuery']
    export const isE2ETaxonomyQuery = (obj?: { __typename?: any } | null): obj is E2ETaxonomyQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isE2ETaxonomyQuery"')
      return E2ETaxonomyQuery_possibleTypes.includes(obj.__typename)
    }
    


    const CategoryFunctionModelQuery_possibleTypes: string[] = ['CategoryFunctionModelQuery']
    export const isCategoryFunctionModelQuery = (obj?: { __typename?: any } | null): obj is CategoryFunctionModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCategoryFunctionModelQuery"')
      return CategoryFunctionModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const CategoryFunctionModelObjectType_possibleTypes: string[] = ['CategoryFunctionModelObjectType']
    export const isCategoryFunctionModelObjectType = (obj?: { __typename?: any } | null): obj is CategoryFunctionModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCategoryFunctionModelObjectType"')
      return CategoryFunctionModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const FunctionModelObjectType_possibleTypes: string[] = ['FunctionModelObjectType']
    export const isFunctionModelObjectType = (obj?: { __typename?: any } | null): obj is FunctionModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isFunctionModelObjectType"')
      return FunctionModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const Level1ModelObjectType_possibleTypes: string[] = ['Level1ModelObjectType']
    export const isLevel1ModelObjectType = (obj?: { __typename?: any } | null): obj is Level1ModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLevel1ModelObjectType"')
      return Level1ModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const Level2ModelObjectType_possibleTypes: string[] = ['Level2ModelObjectType']
    export const isLevel2ModelObjectType = (obj?: { __typename?: any } | null): obj is Level2ModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLevel2ModelObjectType"')
      return Level2ModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramTemplateModelObjectType_possibleTypes: string[] = ['DiagramTemplateModelObjectType']
    export const isDiagramTemplateModelObjectType = (obj?: { __typename?: any } | null): obj is DiagramTemplateModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramTemplateModelObjectType"')
      return DiagramTemplateModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const AIOpportunityModelObjectType_possibleTypes: string[] = ['AIOpportunityModelObjectType']
    export const isAIOpportunityModelObjectType = (obj?: { __typename?: any } | null): obj is AIOpportunityModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAIOpportunityModelObjectType"')
      return AIOpportunityModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const PocModelObjectType_possibleTypes: string[] = ['PocModelObjectType']
    export const isPocModelObjectType = (obj?: { __typename?: any } | null): obj is PocModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPocModelObjectType"')
      return PocModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const UseCaseModelObjectType_possibleTypes: string[] = ['UseCaseModelObjectType']
    export const isUseCaseModelObjectType = (obj?: { __typename?: any } | null): obj is UseCaseModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUseCaseModelObjectType"')
      return UseCaseModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramModelObjectType_possibleTypes: string[] = ['DiagramModelObjectType']
    export const isDiagramModelObjectType = (obj?: { __typename?: any } | null): obj is DiagramModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramModelObjectType"')
      return DiagramModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const TechnologyModelObjectType_possibleTypes: string[] = ['TechnologyModelObjectType']
    export const isTechnologyModelObjectType = (obj?: { __typename?: any } | null): obj is TechnologyModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isTechnologyModelObjectType"')
      return TechnologyModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramProcessModelObjectType_possibleTypes: string[] = ['DiagramProcessModelObjectType']
    export const isDiagramProcessModelObjectType = (obj?: { __typename?: any } | null): obj is DiagramProcessModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramProcessModelObjectType"')
      return DiagramProcessModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramE2EModelObjectType_possibleTypes: string[] = ['DiagramE2EModelObjectType']
    export const isDiagramE2EModelObjectType = (obj?: { __typename?: any } | null): obj is DiagramE2EModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramE2EModelObjectType"')
      return DiagramE2EModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramE2EModelQuery_possibleTypes: string[] = ['DiagramE2EModelQuery']
    export const isDiagramE2EModelQuery = (obj?: { __typename?: any } | null): obj is DiagramE2EModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramE2EModelQuery"')
      return DiagramE2EModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramProcessModelQuery_possibleTypes: string[] = ['DiagramProcessModelQuery']
    export const isDiagramProcessModelQuery = (obj?: { __typename?: any } | null): obj is DiagramProcessModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramProcessModelQuery"')
      return DiagramProcessModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramTemplateModelQuery_possibleTypes: string[] = ['DiagramTemplateModelQuery']
    export const isDiagramTemplateModelQuery = (obj?: { __typename?: any } | null): obj is DiagramTemplateModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramTemplateModelQuery"')
      return DiagramTemplateModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const FunctionModelQuery_possibleTypes: string[] = ['FunctionModelQuery']
    export const isFunctionModelQuery = (obj?: { __typename?: any } | null): obj is FunctionModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isFunctionModelQuery"')
      return FunctionModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const Level1ModelQuery_possibleTypes: string[] = ['Level1ModelQuery']
    export const isLevel1ModelQuery = (obj?: { __typename?: any } | null): obj is Level1ModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLevel1ModelQuery"')
      return Level1ModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const Level2ModelQuery_possibleTypes: string[] = ['Level2ModelQuery']
    export const isLevel2ModelQuery = (obj?: { __typename?: any } | null): obj is Level2ModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLevel2ModelQuery"')
      return Level2ModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const TechnologyModelQuery_possibleTypes: string[] = ['TechnologyModelQuery']
    export const isTechnologyModelQuery = (obj?: { __typename?: any } | null): obj is TechnologyModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isTechnologyModelQuery"')
      return TechnologyModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const AIOpportunityModelQuery_possibleTypes: string[] = ['AIOpportunityModelQuery']
    export const isAIOpportunityModelQuery = (obj?: { __typename?: any } | null): obj is AIOpportunityModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAIOpportunityModelQuery"')
      return AIOpportunityModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const SolutionQuery_possibleTypes: string[] = ['SolutionQuery']
    export const isSolutionQuery = (obj?: { __typename?: any } | null): obj is SolutionQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSolutionQuery"')
      return SolutionQuery_possibleTypes.includes(obj.__typename)
    }
    


    const CategoryQuestionModelQuery_possibleTypes: string[] = ['CategoryQuestionModelQuery']
    export const isCategoryQuestionModelQuery = (obj?: { __typename?: any } | null): obj is CategoryQuestionModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCategoryQuestionModelQuery"')
      return CategoryQuestionModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const CategoryQuestionModelObjectType_possibleTypes: string[] = ['CategoryQuestionModelObjectType']
    export const isCategoryQuestionModelObjectType = (obj?: { __typename?: any } | null): obj is CategoryQuestionModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCategoryQuestionModelObjectType"')
      return CategoryQuestionModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const SubCategoryQuestionModelObjectType_possibleTypes: string[] = ['SubCategoryQuestionModelObjectType']
    export const isSubCategoryQuestionModelObjectType = (obj?: { __typename?: any } | null): obj is SubCategoryQuestionModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSubCategoryQuestionModelObjectType"')
      return SubCategoryQuestionModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const QuestionModelObjectType_possibleTypes: string[] = ['QuestionModelObjectType']
    export const isQuestionModelObjectType = (obj?: { __typename?: any } | null): obj is QuestionModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isQuestionModelObjectType"')
      return QuestionModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const SubCategoryQuestionModelQuery_possibleTypes: string[] = ['SubCategoryQuestionModelQuery']
    export const isSubCategoryQuestionModelQuery = (obj?: { __typename?: any } | null): obj is SubCategoryQuestionModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSubCategoryQuestionModelQuery"')
      return SubCategoryQuestionModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const QuestionModelQuery_possibleTypes: string[] = ['QuestionModelQuery']
    export const isQuestionModelQuery = (obj?: { __typename?: any } | null): obj is QuestionModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isQuestionModelQuery"')
      return QuestionModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const UseCaseQuery_possibleTypes: string[] = ['UseCaseQuery']
    export const isUseCaseQuery = (obj?: { __typename?: any } | null): obj is UseCaseQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUseCaseQuery"')
      return UseCaseQuery_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramModelQuery_possibleTypes: string[] = ['DiagramModelQuery']
    export const isDiagramModelQuery = (obj?: { __typename?: any } | null): obj is DiagramModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramModelQuery"')
      return DiagramModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const UseCaseModelQuery_possibleTypes: string[] = ['UseCaseModelQuery']
    export const isUseCaseModelQuery = (obj?: { __typename?: any } | null): obj is UseCaseModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUseCaseModelQuery"')
      return UseCaseModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const PocModelQuery_possibleTypes: string[] = ['PocModelQuery']
    export const isPocModelQuery = (obj?: { __typename?: any } | null): obj is PocModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPocModelQuery"')
      return PocModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const MaturityQuery_possibleTypes: string[] = ['MaturityQuery']
    export const isMaturityQuery = (obj?: { __typename?: any } | null): obj is MaturityQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isMaturityQuery"')
      return MaturityQuery_possibleTypes.includes(obj.__typename)
    }
    


    const AssessmentMaturityModelQuery_possibleTypes: string[] = ['AssessmentMaturityModelQuery']
    export const isAssessmentMaturityModelQuery = (obj?: { __typename?: any } | null): obj is AssessmentMaturityModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAssessmentMaturityModelQuery"')
      return AssessmentMaturityModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const AssessmentMaturityModelObjectType_possibleTypes: string[] = ['AssessmentMaturityModelObjectType']
    export const isAssessmentMaturityModelObjectType = (obj?: { __typename?: any } | null): obj is AssessmentMaturityModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAssessmentMaturityModelObjectType"')
      return AssessmentMaturityModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const QuestionMaturityModelQuery_possibleTypes: string[] = ['QuestionMaturityModelQuery']
    export const isQuestionMaturityModelQuery = (obj?: { __typename?: any } | null): obj is QuestionMaturityModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isQuestionMaturityModelQuery"')
      return QuestionMaturityModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const QuestionMaturityModelObjectType_possibleTypes: string[] = ['QuestionMaturityModelObjectType']
    export const isQuestionMaturityModelObjectType = (obj?: { __typename?: any } | null): obj is QuestionMaturityModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isQuestionMaturityModelObjectType"')
      return QuestionMaturityModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const CategoryQuestionMaturityModelObjectType_possibleTypes: string[] = ['CategoryQuestionMaturityModelObjectType']
    export const isCategoryQuestionMaturityModelObjectType = (obj?: { __typename?: any } | null): obj is CategoryQuestionMaturityModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCategoryQuestionMaturityModelObjectType"')
      return CategoryQuestionMaturityModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const SubCategoryQuestionMaturityModelObjectType_possibleTypes: string[] = ['SubCategoryQuestionMaturityModelObjectType']
    export const isSubCategoryQuestionMaturityModelObjectType = (obj?: { __typename?: any } | null): obj is SubCategoryQuestionMaturityModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSubCategoryQuestionMaturityModelObjectType"')
      return SubCategoryQuestionMaturityModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const SubCategoryQuestionMaturityModelQuery_possibleTypes: string[] = ['SubCategoryQuestionMaturityModelQuery']
    export const isSubCategoryQuestionMaturityModelQuery = (obj?: { __typename?: any } | null): obj is SubCategoryQuestionMaturityModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSubCategoryQuestionMaturityModelQuery"')
      return SubCategoryQuestionMaturityModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const CategoryQuestionMaturityModelQuery_possibleTypes: string[] = ['CategoryQuestionMaturityModelQuery']
    export const isCategoryQuestionMaturityModelQuery = (obj?: { __typename?: any } | null): obj is CategoryQuestionMaturityModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCategoryQuestionMaturityModelQuery"')
      return CategoryQuestionMaturityModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const SimulationQuery_possibleTypes: string[] = ['SimulationQuery']
    export const isSimulationQuery = (obj?: { __typename?: any } | null): obj is SimulationQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSimulationQuery"')
      return SimulationQuery_possibleTypes.includes(obj.__typename)
    }
    


    const EnterpriseContextModelQuery_possibleTypes: string[] = ['EnterpriseContextModelQuery']
    export const isEnterpriseContextModelQuery = (obj?: { __typename?: any } | null): obj is EnterpriseContextModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isEnterpriseContextModelQuery"')
      return EnterpriseContextModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const EnterpriseContextModelObjectType_possibleTypes: string[] = ['EnterpriseContextModelObjectType']
    export const isEnterpriseContextModelObjectType = (obj?: { __typename?: any } | null): obj is EnterpriseContextModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isEnterpriseContextModelObjectType"')
      return EnterpriseContextModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const SimulationModelQuery_possibleTypes: string[] = ['SimulationModelQuery']
    export const isSimulationModelQuery = (obj?: { __typename?: any } | null): obj is SimulationModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSimulationModelQuery"')
      return SimulationModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const SimulationModelObjectType_possibleTypes: string[] = ['SimulationModelObjectType']
    export const isSimulationModelObjectType = (obj?: { __typename?: any } | null): obj is SimulationModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSimulationModelObjectType"')
      return SimulationModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const UseCaseSimulationModelObjectType_possibleTypes: string[] = ['UseCaseSimulationModelObjectType']
    export const isUseCaseSimulationModelObjectType = (obj?: { __typename?: any } | null): obj is UseCaseSimulationModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUseCaseSimulationModelObjectType"')
      return UseCaseSimulationModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const UseCaseSimulationModelQuery_possibleTypes: string[] = ['UseCaseSimulationModelQuery']
    export const isUseCaseSimulationModelQuery = (obj?: { __typename?: any } | null): obj is UseCaseSimulationModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUseCaseSimulationModelQuery"')
      return UseCaseSimulationModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const UseCaseLibraryModelQuery_possibleTypes: string[] = ['UseCaseLibraryModelQuery']
    export const isUseCaseLibraryModelQuery = (obj?: { __typename?: any } | null): obj is UseCaseLibraryModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUseCaseLibraryModelQuery"')
      return UseCaseLibraryModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const UseCaseLibraryModelObjectType_possibleTypes: string[] = ['UseCaseLibraryModelObjectType']
    export const isUseCaseLibraryModelObjectType = (obj?: { __typename?: any } | null): obj is UseCaseLibraryModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUseCaseLibraryModelObjectType"')
      return UseCaseLibraryModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const M1PrepModelQuery_possibleTypes: string[] = ['M1PrepModelQuery']
    export const isM1PrepModelQuery = (obj?: { __typename?: any } | null): obj is M1PrepModelQuery => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isM1PrepModelQuery"')
      return M1PrepModelQuery_possibleTypes.includes(obj.__typename)
    }
    


    const M1PrepModelObjectType_possibleTypes: string[] = ['M1PrepModelObjectType']
    export const isM1PrepModelObjectType = (obj?: { __typename?: any } | null): obj is M1PrepModelObjectType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isM1PrepModelObjectType"')
      return M1PrepModelObjectType_possibleTypes.includes(obj.__typename)
    }
    


    const Mutation_possibleTypes: string[] = ['Mutation']
    export const isMutation = (obj?: { __typename?: any } | null): obj is Mutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isMutation"')
      return Mutation_possibleTypes.includes(obj.__typename)
    }
    


    const CategoryFunctionModelMutation_possibleTypes: string[] = ['CategoryFunctionModelMutation']
    export const isCategoryFunctionModelMutation = (obj?: { __typename?: any } | null): obj is CategoryFunctionModelMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCategoryFunctionModelMutation"')
      return CategoryFunctionModelMutation_possibleTypes.includes(obj.__typename)
    }
    


    const CategoryFunctionModelPutMutationPayload_possibleTypes: string[] = ['CategoryFunctionModelPutMutationPayload']
    export const isCategoryFunctionModelPutMutationPayload = (obj?: { __typename?: any } | null): obj is CategoryFunctionModelPutMutationPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCategoryFunctionModelPutMutationPayload"')
      return CategoryFunctionModelPutMutationPayload_possibleTypes.includes(obj.__typename)
    }
    


    const ErrorType_possibleTypes: string[] = ['ErrorType']
    export const isErrorType = (obj?: { __typename?: any } | null): obj is ErrorType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isErrorType"')
      return ErrorType_possibleTypes.includes(obj.__typename)
    }
    


    const CategoryFunctionModelDeleteMutation_possibleTypes: string[] = ['CategoryFunctionModelDeleteMutation']
    export const isCategoryFunctionModelDeleteMutation = (obj?: { __typename?: any } | null): obj is CategoryFunctionModelDeleteMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCategoryFunctionModelDeleteMutation"')
      return CategoryFunctionModelDeleteMutation_possibleTypes.includes(obj.__typename)
    }
    


    const AIOpportunityModelMutation_possibleTypes: string[] = ['AIOpportunityModelMutation']
    export const isAIOpportunityModelMutation = (obj?: { __typename?: any } | null): obj is AIOpportunityModelMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAIOpportunityModelMutation"')
      return AIOpportunityModelMutation_possibleTypes.includes(obj.__typename)
    }
    


    const AIOpportunityModelPutMutationPayload_possibleTypes: string[] = ['AIOpportunityModelPutMutationPayload']
    export const isAIOpportunityModelPutMutationPayload = (obj?: { __typename?: any } | null): obj is AIOpportunityModelPutMutationPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAIOpportunityModelPutMutationPayload"')
      return AIOpportunityModelPutMutationPayload_possibleTypes.includes(obj.__typename)
    }
    


    const AIOpportunityModelDeleteMutation_possibleTypes: string[] = ['AIOpportunityModelDeleteMutation']
    export const isAIOpportunityModelDeleteMutation = (obj?: { __typename?: any } | null): obj is AIOpportunityModelDeleteMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAIOpportunityModelDeleteMutation"')
      return AIOpportunityModelDeleteMutation_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramE2EModelMutation_possibleTypes: string[] = ['DiagramE2EModelMutation']
    export const isDiagramE2EModelMutation = (obj?: { __typename?: any } | null): obj is DiagramE2EModelMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramE2EModelMutation"')
      return DiagramE2EModelMutation_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramE2EModelPutMutationPayload_possibleTypes: string[] = ['DiagramE2EModelPutMutationPayload']
    export const isDiagramE2EModelPutMutationPayload = (obj?: { __typename?: any } | null): obj is DiagramE2EModelPutMutationPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramE2EModelPutMutationPayload"')
      return DiagramE2EModelPutMutationPayload_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramE2EModelDeleteMutation_possibleTypes: string[] = ['DiagramE2EModelDeleteMutation']
    export const isDiagramE2EModelDeleteMutation = (obj?: { __typename?: any } | null): obj is DiagramE2EModelDeleteMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramE2EModelDeleteMutation"')
      return DiagramE2EModelDeleteMutation_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramProcessModelMutation_possibleTypes: string[] = ['DiagramProcessModelMutation']
    export const isDiagramProcessModelMutation = (obj?: { __typename?: any } | null): obj is DiagramProcessModelMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramProcessModelMutation"')
      return DiagramProcessModelMutation_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramProcessModelPutMutationPayload_possibleTypes: string[] = ['DiagramProcessModelPutMutationPayload']
    export const isDiagramProcessModelPutMutationPayload = (obj?: { __typename?: any } | null): obj is DiagramProcessModelPutMutationPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramProcessModelPutMutationPayload"')
      return DiagramProcessModelPutMutationPayload_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramProcessModelDeleteMutation_possibleTypes: string[] = ['DiagramProcessModelDeleteMutation']
    export const isDiagramProcessModelDeleteMutation = (obj?: { __typename?: any } | null): obj is DiagramProcessModelDeleteMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramProcessModelDeleteMutation"')
      return DiagramProcessModelDeleteMutation_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramTemplateModelMutation_possibleTypes: string[] = ['DiagramTemplateModelMutation']
    export const isDiagramTemplateModelMutation = (obj?: { __typename?: any } | null): obj is DiagramTemplateModelMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramTemplateModelMutation"')
      return DiagramTemplateModelMutation_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramTemplateModelPutMutationPayload_possibleTypes: string[] = ['DiagramTemplateModelPutMutationPayload']
    export const isDiagramTemplateModelPutMutationPayload = (obj?: { __typename?: any } | null): obj is DiagramTemplateModelPutMutationPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramTemplateModelPutMutationPayload"')
      return DiagramTemplateModelPutMutationPayload_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramTemplateModelDeleteMutation_possibleTypes: string[] = ['DiagramTemplateModelDeleteMutation']
    export const isDiagramTemplateModelDeleteMutation = (obj?: { __typename?: any } | null): obj is DiagramTemplateModelDeleteMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramTemplateModelDeleteMutation"')
      return DiagramTemplateModelDeleteMutation_possibleTypes.includes(obj.__typename)
    }
    


    const FunctionModelMutation_possibleTypes: string[] = ['FunctionModelMutation']
    export const isFunctionModelMutation = (obj?: { __typename?: any } | null): obj is FunctionModelMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isFunctionModelMutation"')
      return FunctionModelMutation_possibleTypes.includes(obj.__typename)
    }
    


    const FunctionModelPutMutationPayload_possibleTypes: string[] = ['FunctionModelPutMutationPayload']
    export const isFunctionModelPutMutationPayload = (obj?: { __typename?: any } | null): obj is FunctionModelPutMutationPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isFunctionModelPutMutationPayload"')
      return FunctionModelPutMutationPayload_possibleTypes.includes(obj.__typename)
    }
    


    const FunctionModelDeleteMutation_possibleTypes: string[] = ['FunctionModelDeleteMutation']
    export const isFunctionModelDeleteMutation = (obj?: { __typename?: any } | null): obj is FunctionModelDeleteMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isFunctionModelDeleteMutation"')
      return FunctionModelDeleteMutation_possibleTypes.includes(obj.__typename)
    }
    


    const Level1ModelMutation_possibleTypes: string[] = ['Level1ModelMutation']
    export const isLevel1ModelMutation = (obj?: { __typename?: any } | null): obj is Level1ModelMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLevel1ModelMutation"')
      return Level1ModelMutation_possibleTypes.includes(obj.__typename)
    }
    


    const Level1ModelPutMutationPayload_possibleTypes: string[] = ['Level1ModelPutMutationPayload']
    export const isLevel1ModelPutMutationPayload = (obj?: { __typename?: any } | null): obj is Level1ModelPutMutationPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLevel1ModelPutMutationPayload"')
      return Level1ModelPutMutationPayload_possibleTypes.includes(obj.__typename)
    }
    


    const Level1ModelDeleteMutation_possibleTypes: string[] = ['Level1ModelDeleteMutation']
    export const isLevel1ModelDeleteMutation = (obj?: { __typename?: any } | null): obj is Level1ModelDeleteMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLevel1ModelDeleteMutation"')
      return Level1ModelDeleteMutation_possibleTypes.includes(obj.__typename)
    }
    


    const Level2ModelMutation_possibleTypes: string[] = ['Level2ModelMutation']
    export const isLevel2ModelMutation = (obj?: { __typename?: any } | null): obj is Level2ModelMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLevel2ModelMutation"')
      return Level2ModelMutation_possibleTypes.includes(obj.__typename)
    }
    


    const Level2ModelPutMutationPayload_possibleTypes: string[] = ['Level2ModelPutMutationPayload']
    export const isLevel2ModelPutMutationPayload = (obj?: { __typename?: any } | null): obj is Level2ModelPutMutationPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLevel2ModelPutMutationPayload"')
      return Level2ModelPutMutationPayload_possibleTypes.includes(obj.__typename)
    }
    


    const Level2ModelDeleteMutation_possibleTypes: string[] = ['Level2ModelDeleteMutation']
    export const isLevel2ModelDeleteMutation = (obj?: { __typename?: any } | null): obj is Level2ModelDeleteMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isLevel2ModelDeleteMutation"')
      return Level2ModelDeleteMutation_possibleTypes.includes(obj.__typename)
    }
    


    const TechnologyModelMutation_possibleTypes: string[] = ['TechnologyModelMutation']
    export const isTechnologyModelMutation = (obj?: { __typename?: any } | null): obj is TechnologyModelMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isTechnologyModelMutation"')
      return TechnologyModelMutation_possibleTypes.includes(obj.__typename)
    }
    


    const TechnologyModelPutMutationPayload_possibleTypes: string[] = ['TechnologyModelPutMutationPayload']
    export const isTechnologyModelPutMutationPayload = (obj?: { __typename?: any } | null): obj is TechnologyModelPutMutationPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isTechnologyModelPutMutationPayload"')
      return TechnologyModelPutMutationPayload_possibleTypes.includes(obj.__typename)
    }
    


    const TechnologyModelDeleteMutation_possibleTypes: string[] = ['TechnologyModelDeleteMutation']
    export const isTechnologyModelDeleteMutation = (obj?: { __typename?: any } | null): obj is TechnologyModelDeleteMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isTechnologyModelDeleteMutation"')
      return TechnologyModelDeleteMutation_possibleTypes.includes(obj.__typename)
    }
    


    const AssessmentMaturityModelMutation_possibleTypes: string[] = ['AssessmentMaturityModelMutation']
    export const isAssessmentMaturityModelMutation = (obj?: { __typename?: any } | null): obj is AssessmentMaturityModelMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAssessmentMaturityModelMutation"')
      return AssessmentMaturityModelMutation_possibleTypes.includes(obj.__typename)
    }
    


    const AssessmentMaturityModelPutMutationPayload_possibleTypes: string[] = ['AssessmentMaturityModelPutMutationPayload']
    export const isAssessmentMaturityModelPutMutationPayload = (obj?: { __typename?: any } | null): obj is AssessmentMaturityModelPutMutationPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAssessmentMaturityModelPutMutationPayload"')
      return AssessmentMaturityModelPutMutationPayload_possibleTypes.includes(obj.__typename)
    }
    


    const AssessmentMaturityModelDeleteMutation_possibleTypes: string[] = ['AssessmentMaturityModelDeleteMutation']
    export const isAssessmentMaturityModelDeleteMutation = (obj?: { __typename?: any } | null): obj is AssessmentMaturityModelDeleteMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isAssessmentMaturityModelDeleteMutation"')
      return AssessmentMaturityModelDeleteMutation_possibleTypes.includes(obj.__typename)
    }
    


    const QuestionMaturityModelMutation_possibleTypes: string[] = ['QuestionMaturityModelMutation']
    export const isQuestionMaturityModelMutation = (obj?: { __typename?: any } | null): obj is QuestionMaturityModelMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isQuestionMaturityModelMutation"')
      return QuestionMaturityModelMutation_possibleTypes.includes(obj.__typename)
    }
    


    const QuestionMaturityModelPutMutationPayload_possibleTypes: string[] = ['QuestionMaturityModelPutMutationPayload']
    export const isQuestionMaturityModelPutMutationPayload = (obj?: { __typename?: any } | null): obj is QuestionMaturityModelPutMutationPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isQuestionMaturityModelPutMutationPayload"')
      return QuestionMaturityModelPutMutationPayload_possibleTypes.includes(obj.__typename)
    }
    


    const QuestionMaturityModelDeleteMutation_possibleTypes: string[] = ['QuestionMaturityModelDeleteMutation']
    export const isQuestionMaturityModelDeleteMutation = (obj?: { __typename?: any } | null): obj is QuestionMaturityModelDeleteMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isQuestionMaturityModelDeleteMutation"')
      return QuestionMaturityModelDeleteMutation_possibleTypes.includes(obj.__typename)
    }
    


    const SubCategoryQuestionMaturityModelMutation_possibleTypes: string[] = ['SubCategoryQuestionMaturityModelMutation']
    export const isSubCategoryQuestionMaturityModelMutation = (obj?: { __typename?: any } | null): obj is SubCategoryQuestionMaturityModelMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSubCategoryQuestionMaturityModelMutation"')
      return SubCategoryQuestionMaturityModelMutation_possibleTypes.includes(obj.__typename)
    }
    


    const SubCategoryQuestionMaturityModelPutMutationPayload_possibleTypes: string[] = ['SubCategoryQuestionMaturityModelPutMutationPayload']
    export const isSubCategoryQuestionMaturityModelPutMutationPayload = (obj?: { __typename?: any } | null): obj is SubCategoryQuestionMaturityModelPutMutationPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSubCategoryQuestionMaturityModelPutMutationPayload"')
      return SubCategoryQuestionMaturityModelPutMutationPayload_possibleTypes.includes(obj.__typename)
    }
    


    const SubCategoryQuestionMaturityModelDeleteMutation_possibleTypes: string[] = ['SubCategoryQuestionMaturityModelDeleteMutation']
    export const isSubCategoryQuestionMaturityModelDeleteMutation = (obj?: { __typename?: any } | null): obj is SubCategoryQuestionMaturityModelDeleteMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSubCategoryQuestionMaturityModelDeleteMutation"')
      return SubCategoryQuestionMaturityModelDeleteMutation_possibleTypes.includes(obj.__typename)
    }
    


    const CategoryQuestionMaturityModelMutation_possibleTypes: string[] = ['CategoryQuestionMaturityModelMutation']
    export const isCategoryQuestionMaturityModelMutation = (obj?: { __typename?: any } | null): obj is CategoryQuestionMaturityModelMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCategoryQuestionMaturityModelMutation"')
      return CategoryQuestionMaturityModelMutation_possibleTypes.includes(obj.__typename)
    }
    


    const CategoryQuestionMaturityModelPutMutationPayload_possibleTypes: string[] = ['CategoryQuestionMaturityModelPutMutationPayload']
    export const isCategoryQuestionMaturityModelPutMutationPayload = (obj?: { __typename?: any } | null): obj is CategoryQuestionMaturityModelPutMutationPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCategoryQuestionMaturityModelPutMutationPayload"')
      return CategoryQuestionMaturityModelPutMutationPayload_possibleTypes.includes(obj.__typename)
    }
    


    const CategoryQuestionMaturityModelDeleteMutation_possibleTypes: string[] = ['CategoryQuestionMaturityModelDeleteMutation']
    export const isCategoryQuestionMaturityModelDeleteMutation = (obj?: { __typename?: any } | null): obj is CategoryQuestionMaturityModelDeleteMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCategoryQuestionMaturityModelDeleteMutation"')
      return CategoryQuestionMaturityModelDeleteMutation_possibleTypes.includes(obj.__typename)
    }
    


    const EnterpriseContextModelMutation_possibleTypes: string[] = ['EnterpriseContextModelMutation']
    export const isEnterpriseContextModelMutation = (obj?: { __typename?: any } | null): obj is EnterpriseContextModelMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isEnterpriseContextModelMutation"')
      return EnterpriseContextModelMutation_possibleTypes.includes(obj.__typename)
    }
    


    const EnterpriseContextModelPutMutationPayload_possibleTypes: string[] = ['EnterpriseContextModelPutMutationPayload']
    export const isEnterpriseContextModelPutMutationPayload = (obj?: { __typename?: any } | null): obj is EnterpriseContextModelPutMutationPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isEnterpriseContextModelPutMutationPayload"')
      return EnterpriseContextModelPutMutationPayload_possibleTypes.includes(obj.__typename)
    }
    


    const EnterpriseContextModelDeleteMutation_possibleTypes: string[] = ['EnterpriseContextModelDeleteMutation']
    export const isEnterpriseContextModelDeleteMutation = (obj?: { __typename?: any } | null): obj is EnterpriseContextModelDeleteMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isEnterpriseContextModelDeleteMutation"')
      return EnterpriseContextModelDeleteMutation_possibleTypes.includes(obj.__typename)
    }
    


    const SimulationModelMutation_possibleTypes: string[] = ['SimulationModelMutation']
    export const isSimulationModelMutation = (obj?: { __typename?: any } | null): obj is SimulationModelMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSimulationModelMutation"')
      return SimulationModelMutation_possibleTypes.includes(obj.__typename)
    }
    


    const SimulationModelPutMutationPayload_possibleTypes: string[] = ['SimulationModelPutMutationPayload']
    export const isSimulationModelPutMutationPayload = (obj?: { __typename?: any } | null): obj is SimulationModelPutMutationPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSimulationModelPutMutationPayload"')
      return SimulationModelPutMutationPayload_possibleTypes.includes(obj.__typename)
    }
    


    const SimulationModelDeleteMutation_possibleTypes: string[] = ['SimulationModelDeleteMutation']
    export const isSimulationModelDeleteMutation = (obj?: { __typename?: any } | null): obj is SimulationModelDeleteMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSimulationModelDeleteMutation"')
      return SimulationModelDeleteMutation_possibleTypes.includes(obj.__typename)
    }
    


    const UseCaseSimulationModelMutation_possibleTypes: string[] = ['UseCaseSimulationModelMutation']
    export const isUseCaseSimulationModelMutation = (obj?: { __typename?: any } | null): obj is UseCaseSimulationModelMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUseCaseSimulationModelMutation"')
      return UseCaseSimulationModelMutation_possibleTypes.includes(obj.__typename)
    }
    


    const UseCaseSimulationModelPutMutationPayload_possibleTypes: string[] = ['UseCaseSimulationModelPutMutationPayload']
    export const isUseCaseSimulationModelPutMutationPayload = (obj?: { __typename?: any } | null): obj is UseCaseSimulationModelPutMutationPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUseCaseSimulationModelPutMutationPayload"')
      return UseCaseSimulationModelPutMutationPayload_possibleTypes.includes(obj.__typename)
    }
    


    const UseCaseSimulationModelDeleteMutation_possibleTypes: string[] = ['UseCaseSimulationModelDeleteMutation']
    export const isUseCaseSimulationModelDeleteMutation = (obj?: { __typename?: any } | null): obj is UseCaseSimulationModelDeleteMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUseCaseSimulationModelDeleteMutation"')
      return UseCaseSimulationModelDeleteMutation_possibleTypes.includes(obj.__typename)
    }
    


    const UseCaseLibraryModelMutation_possibleTypes: string[] = ['UseCaseLibraryModelMutation']
    export const isUseCaseLibraryModelMutation = (obj?: { __typename?: any } | null): obj is UseCaseLibraryModelMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUseCaseLibraryModelMutation"')
      return UseCaseLibraryModelMutation_possibleTypes.includes(obj.__typename)
    }
    


    const UseCaseLibraryModelPutMutationPayload_possibleTypes: string[] = ['UseCaseLibraryModelPutMutationPayload']
    export const isUseCaseLibraryModelPutMutationPayload = (obj?: { __typename?: any } | null): obj is UseCaseLibraryModelPutMutationPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUseCaseLibraryModelPutMutationPayload"')
      return UseCaseLibraryModelPutMutationPayload_possibleTypes.includes(obj.__typename)
    }
    


    const UseCaseLibraryModelDeleteMutation_possibleTypes: string[] = ['UseCaseLibraryModelDeleteMutation']
    export const isUseCaseLibraryModelDeleteMutation = (obj?: { __typename?: any } | null): obj is UseCaseLibraryModelDeleteMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUseCaseLibraryModelDeleteMutation"')
      return UseCaseLibraryModelDeleteMutation_possibleTypes.includes(obj.__typename)
    }
    


    const M1PrepModelMutation_possibleTypes: string[] = ['M1PrepModelMutation']
    export const isM1PrepModelMutation = (obj?: { __typename?: any } | null): obj is M1PrepModelMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isM1PrepModelMutation"')
      return M1PrepModelMutation_possibleTypes.includes(obj.__typename)
    }
    


    const M1PrepModelPutMutationPayload_possibleTypes: string[] = ['M1PrepModelPutMutationPayload']
    export const isM1PrepModelPutMutationPayload = (obj?: { __typename?: any } | null): obj is M1PrepModelPutMutationPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isM1PrepModelPutMutationPayload"')
      return M1PrepModelPutMutationPayload_possibleTypes.includes(obj.__typename)
    }
    


    const M1PrepModelDeleteMutation_possibleTypes: string[] = ['M1PrepModelDeleteMutation']
    export const isM1PrepModelDeleteMutation = (obj?: { __typename?: any } | null): obj is M1PrepModelDeleteMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isM1PrepModelDeleteMutation"')
      return M1PrepModelDeleteMutation_possibleTypes.includes(obj.__typename)
    }
    


    const CategoryQuestionModelMutation_possibleTypes: string[] = ['CategoryQuestionModelMutation']
    export const isCategoryQuestionModelMutation = (obj?: { __typename?: any } | null): obj is CategoryQuestionModelMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCategoryQuestionModelMutation"')
      return CategoryQuestionModelMutation_possibleTypes.includes(obj.__typename)
    }
    


    const CategoryQuestionModelPutMutationPayload_possibleTypes: string[] = ['CategoryQuestionModelPutMutationPayload']
    export const isCategoryQuestionModelPutMutationPayload = (obj?: { __typename?: any } | null): obj is CategoryQuestionModelPutMutationPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCategoryQuestionModelPutMutationPayload"')
      return CategoryQuestionModelPutMutationPayload_possibleTypes.includes(obj.__typename)
    }
    


    const CategoryQuestionModelDeleteMutation_possibleTypes: string[] = ['CategoryQuestionModelDeleteMutation']
    export const isCategoryQuestionModelDeleteMutation = (obj?: { __typename?: any } | null): obj is CategoryQuestionModelDeleteMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCategoryQuestionModelDeleteMutation"')
      return CategoryQuestionModelDeleteMutation_possibleTypes.includes(obj.__typename)
    }
    


    const SubCategoryQuestionModelMutation_possibleTypes: string[] = ['SubCategoryQuestionModelMutation']
    export const isSubCategoryQuestionModelMutation = (obj?: { __typename?: any } | null): obj is SubCategoryQuestionModelMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSubCategoryQuestionModelMutation"')
      return SubCategoryQuestionModelMutation_possibleTypes.includes(obj.__typename)
    }
    


    const SubCategoryQuestionModelPutMutationPayload_possibleTypes: string[] = ['SubCategoryQuestionModelPutMutationPayload']
    export const isSubCategoryQuestionModelPutMutationPayload = (obj?: { __typename?: any } | null): obj is SubCategoryQuestionModelPutMutationPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSubCategoryQuestionModelPutMutationPayload"')
      return SubCategoryQuestionModelPutMutationPayload_possibleTypes.includes(obj.__typename)
    }
    


    const SubCategoryQuestionModelDeleteMutation_possibleTypes: string[] = ['SubCategoryQuestionModelDeleteMutation']
    export const isSubCategoryQuestionModelDeleteMutation = (obj?: { __typename?: any } | null): obj is SubCategoryQuestionModelDeleteMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSubCategoryQuestionModelDeleteMutation"')
      return SubCategoryQuestionModelDeleteMutation_possibleTypes.includes(obj.__typename)
    }
    


    const QuestionModelMutation_possibleTypes: string[] = ['QuestionModelMutation']
    export const isQuestionModelMutation = (obj?: { __typename?: any } | null): obj is QuestionModelMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isQuestionModelMutation"')
      return QuestionModelMutation_possibleTypes.includes(obj.__typename)
    }
    


    const QuestionModelPutMutationPayload_possibleTypes: string[] = ['QuestionModelPutMutationPayload']
    export const isQuestionModelPutMutationPayload = (obj?: { __typename?: any } | null): obj is QuestionModelPutMutationPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isQuestionModelPutMutationPayload"')
      return QuestionModelPutMutationPayload_possibleTypes.includes(obj.__typename)
    }
    


    const QuestionModelDeleteMutation_possibleTypes: string[] = ['QuestionModelDeleteMutation']
    export const isQuestionModelDeleteMutation = (obj?: { __typename?: any } | null): obj is QuestionModelDeleteMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isQuestionModelDeleteMutation"')
      return QuestionModelDeleteMutation_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramModelMutation_possibleTypes: string[] = ['DiagramModelMutation']
    export const isDiagramModelMutation = (obj?: { __typename?: any } | null): obj is DiagramModelMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramModelMutation"')
      return DiagramModelMutation_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramModelPutMutationPayload_possibleTypes: string[] = ['DiagramModelPutMutationPayload']
    export const isDiagramModelPutMutationPayload = (obj?: { __typename?: any } | null): obj is DiagramModelPutMutationPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramModelPutMutationPayload"')
      return DiagramModelPutMutationPayload_possibleTypes.includes(obj.__typename)
    }
    


    const DiagramModelDeleteMutation_possibleTypes: string[] = ['DiagramModelDeleteMutation']
    export const isDiagramModelDeleteMutation = (obj?: { __typename?: any } | null): obj is DiagramModelDeleteMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isDiagramModelDeleteMutation"')
      return DiagramModelDeleteMutation_possibleTypes.includes(obj.__typename)
    }
    


    const UseCaseModelMutation_possibleTypes: string[] = ['UseCaseModelMutation']
    export const isUseCaseModelMutation = (obj?: { __typename?: any } | null): obj is UseCaseModelMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUseCaseModelMutation"')
      return UseCaseModelMutation_possibleTypes.includes(obj.__typename)
    }
    


    const UseCaseModelPutMutationPayload_possibleTypes: string[] = ['UseCaseModelPutMutationPayload']
    export const isUseCaseModelPutMutationPayload = (obj?: { __typename?: any } | null): obj is UseCaseModelPutMutationPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUseCaseModelPutMutationPayload"')
      return UseCaseModelPutMutationPayload_possibleTypes.includes(obj.__typename)
    }
    


    const UseCaseModelDeleteMutation_possibleTypes: string[] = ['UseCaseModelDeleteMutation']
    export const isUseCaseModelDeleteMutation = (obj?: { __typename?: any } | null): obj is UseCaseModelDeleteMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUseCaseModelDeleteMutation"')
      return UseCaseModelDeleteMutation_possibleTypes.includes(obj.__typename)
    }
    


    const PocModelMutation_possibleTypes: string[] = ['PocModelMutation']
    export const isPocModelMutation = (obj?: { __typename?: any } | null): obj is PocModelMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPocModelMutation"')
      return PocModelMutation_possibleTypes.includes(obj.__typename)
    }
    


    const PocModelPutMutationPayload_possibleTypes: string[] = ['PocModelPutMutationPayload']
    export const isPocModelPutMutationPayload = (obj?: { __typename?: any } | null): obj is PocModelPutMutationPayload => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPocModelPutMutationPayload"')
      return PocModelPutMutationPayload_possibleTypes.includes(obj.__typename)
    }
    


    const PocModelDeleteMutation_possibleTypes: string[] = ['PocModelDeleteMutation']
    export const isPocModelDeleteMutation = (obj?: { __typename?: any } | null): obj is PocModelDeleteMutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isPocModelDeleteMutation"')
      return PocModelDeleteMutation_possibleTypes.includes(obj.__typename)
    }
    

export const enumE2ETaxonomyCategoryFunctionTypeChoices = {
   A_1: 'A_1' as const,
   A_2: 'A_2' as const,
   A_3: 'A_3' as const
}

export const enumE2ETaxonomyCategoryFunctionOfficeChoices = {
   A_1: 'A_1' as const,
   A_2: 'A_2' as const,
   A_3: 'A_3' as const
}

export const enumE2ETaxonomyFunctionTypeChoices = {
   A_1: 'A_1' as const,
   A_2: 'A_2' as const,
   A_3: 'A_3' as const
}

export const enumE2ETaxonomyLevel1TypeChoices = {
   A_1: 'A_1' as const,
   A_2: 'A_2' as const,
   A_3: 'A_3' as const
}

export const enumE2ETaxonomyLevel2TypeChoices = {
   A_1: 'A_1' as const,
   A_2: 'A_2' as const,
   A_3: 'A_3' as const
}

export const enumE2ETaxonomyAiOpportunityTypeChoices = {
   A_1: 'A_1' as const,
   A_2: 'A_2' as const
}

export const enumSimulationSimulationTaxonomyRelChoices = {
   A_1: 'A_1' as const,
   A_2: 'A_2' as const,
   A_3: 'A_3' as const
}

export const enumSimulationUseCaseSimulationTypeChoices = {
   A_1: 'A_1' as const,
   A_2: 'A_2' as const
}

export const enumSimulationUseCaseLibrarySourceRelChoices = {
   A_1: 'A_1' as const,
   A_2: 'A_2' as const,
   A_3: 'A_3' as const
}
