import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { AuthService } from '../rtp/service/auth.service';
import { Router } from '@angular/router';
import { Organization } from '../rtp/api/common';
import { DbService } from '../rtp/service/db.service';
import { SimulationService } from '../rtp/components/simulation/simulation.service';
import {
	EnterpriseContextModalComponent,
} from '../rtp/components/modal/enterprise-context-modal/enterprise-context-modal.component';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
	selector: 'app-topbar',
	templateUrl: './app.topbar.component.html',
	providers: [ConfirmationService, MessageService],
})
export class AppTopBarComponent implements OnInit {
	organizationSelected: Organization | undefined;
	organizationItems: Organization[] = [];
	menuItems1: MenuItem[] = [];
	menuItems2: MenuItem[] = [];
	tableRows: any[] = [];
	checked: boolean = false;
	public title: string = '';
	loadOrganization = true;
	visible_video = false;

	@ViewChild('menubutton') menuButton!: ElementRef;

	constructor(
		public messageService: MessageService,
		public confirmationService: ConfirmationService,
		public cdr: ChangeDetectorRef,
		public layoutService: LayoutService,
		public authService: AuthService,
		public dbService: DbService,
		public simulationService: SimulationService,
		public router: Router,
		public dialogService: DialogService,
	) {
		this.title = this.layoutService.title;

		this.layoutService.titleText$.subscribe((title) => {
			this.title = title;
			this.cdr.detectChanges();
		});

		this.authService.updateSource$.subscribe(async () => {
			this.menuItems1 = [
				{
					label: this.authService.profile,
					icon: 'pi pi-user',
				},
			];
			this.organizationItems = await this.authService.getOrganizations();
			this.organizationSelected = await this.authService.getCurrentOrganization();
			this.cdr.detectChanges();
		});

		this.menuItems2 = [
			{
				label: 'Sign Out',
				icon: 'pi pi-sign-out',
				command: () => {
					this.confirmationService.confirm({
						header: 'Sign Out',
						message: 'Are you sure you want to sign out?',
						icon: 'pi pi-exclamation-triangle',
						accept: async () => {
							this.layoutService.updateTitle('');
							this.messageService.add({ severity: 'warn', summary: 'Sign Out' });
							await this.authService.signOut();
							await this.router.navigateByUrl('/sign-in');
						},
					});
				},
			},
		];
	}

	async ngOnInit() {
		await this.loadOrganizations();
		await this.simulationService.getEnterpriseContext();
	}

	private async loadOrganizations() {
		const loadOrganizations = () =>
			setTimeout(async () => {
				if (this.loadOrganization && this.authService.loadOrganizations) {
					this.organizationItems = await this.authService.getOrganizations();
					this.organizationItems.sort((a, b) => a.name.localeCompare(b.name));
					this.organizationSelected = await this.authService.getCurrentOrganization();
					this.loadOrganization = false;
				} else {
					loadOrganizations();
				}
			}, 150);
		loadOrganizations();
	}

	async onChangeOrganization() {
		if (this.organizationSelected) {
			// this.simulationService.enterpriseContext = undefined;
			await this.authService.setCurrentOrganization(this.organizationSelected, true);
			// this.dbService.dbOrganizationInitLoad = true;
			// this.dbService.organizationSync(this.organizationSelected?.id || '', false);
			window.location.reload();
		}
	}

	onChipAdd(event: any, index: number) {
		const value = event.value.trim();

		if (value) {
			this.tableRows[index].tech_vendor.push({ label: value });
		}

		this.tableRows[index].tech_vendor = this.tableRows[index].tech_vendor.filter(
			(vendor: { label: string }) => vendor.label && vendor.label.trim(),
		);

		this.cdr.detectChanges();
	}

	showDialogEnterpriseContext: boolean = false;

	openEnterpriseContext() {
		this.dialogService.open(EnterpriseContextModalComponent, {
			header: 'Enterprise Context',
			width: '90vw',
			height: '100%',
			contentStyle: { overflow: 'auto' },
			showHeader: true,
			baseZIndex: 1,
			resizable: false,
			draggable: false,
			maximizable: false,
			closeOnEscape: false,
			styleClass: 'simulation-sme-prompt-modal',
		});
	}
}
