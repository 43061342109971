import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, TreeNode } from 'primeng/api';
import { LayoutService } from '../../../layout/service/app.layout.service';
import { SimulationService } from './simulation.service';
import { HttpClient } from '@angular/common/http';
import { UseCasesService } from './use-cases.service';
import { AuthService } from '../../service/auth.service';
import { M1Prep, Simulation } from '../../api/simulation.api';
import { Location } from '@angular/common';
import { lastValueFrom } from 'rxjs';

@Component({
	templateUrl: './simulation.component.html',
	styleUrls: ['./simulation.component.scss'],
	providers: [ConfirmationService, MessageService],
})
export class SimulationComponent implements OnInit {
	constructor(
		private http: HttpClient,
		private layoutService: LayoutService,
		public confirmationService: ConfirmationService,
		public messageService: MessageService,
		public authService: AuthService,
		public simulationService: SimulationService,
		public useCasesService: UseCasesService,
		private route: ActivatedRoute,
		private router: Router,
		private location: Location,
	) {
	}

	@Input() selectionView: number = 0;

	isAccordionOpen: boolean = false; // Control the accordion state
	selectedFilterCount: number = 0;
	showFilter: boolean = false; // State to control the filter visibility

	toggleFilter() {
		if (this.showFilter) {
			this.isAccordionOpen = false;
			setTimeout(() => {
				this.showFilter = false;
			}, 300);
		} else {
			this.showFilter = true;
			setTimeout(() => {
				this.isAccordionOpen = true;
			}, 0);
		}
	}

	updateSelectedFilterCount(count: number) {
		this.selectedFilterCount = count;
	}

	navigateToPromptStudio(): void {
		this.selectionView = 3;
	}

	goToPromptStudio(): void {
		this.selectionView = 3;
		this.changeSelectionView();
	}

	async ngOnInit() {
		this.route.queryParams.subscribe((params) => {
			if (params['NavTab']) {
				this.selectionView = +params['NavTab'];
				this.clearNavTabQueryParam();
			}
		});

		//this.simulationService.simulationsUsesCases
		this.layoutService.updateTitle('Simulation');

		this.simulationService.confirmationService = this.confirmationService;

		if (!this.simulationService.loaded) {
			await this.simulationService.onLoad();
		}

		this.useCasesService.confirmationService = this.confirmationService;
		this.useCasesService.messageService = this.messageService;

		if (!this.useCasesService.init) {
			this.useCasesService.loading = true;
			await this.useCasesService.prepareData();
		}
	}

	clearNavTabQueryParam() {
		const urlTree = this.router.createUrlTree([], {
			relativeTo: this.route,
			queryParams: { NavTab: null },
			queryParamsHandling: 'merge',
		});
		const url = this.router.serializeUrl(urlTree);
		this.location.go(url);
	}

	filterIndex: number = 0;
	useCaseFilter: number = -1;

	getClass(value: string): string {
		switch (value) {
			case 'High':
				return 'background-high';
			case 'Medium':
				return 'background-medium';
			case 'Low':
				return 'background-low';
			case 'None':
				return 'background-none';
			default:
				return '';
		}
	}

	changeFilter(i: number) {
		if (i === this.filterIndex) {
			this.filterIndex = 0;
		} else {
			this.filterIndex = i;
		}
	}

	aiEnablersTreeSelections: TreeNode[] = [];
	aiEnablersTreeOptions: TreeNode[] = [];

	scopeTree: TreeNode[] = [
		{
			label: 'E2E',
			key: '1',
		},
		{
			label: 'Process',
			key: '2',
		},
		{
			label: 'SubProcess',
			key: '3',
		},
	];
	scopeTreeSelections: TreeNode[] = [...this.scopeTree];
	scopeTreeOptions: TreeNode[] = [...this.scopeTree];

	impactTree: TreeNode[] = [
		{
			label: 'Breakthrough',
			key: '1',
		},
		{
			label: 'Transformative',
			key: '2',
		},
	];
	impactTreeSelections: TreeNode[] = [...this.impactTree];
	impactTreeOptions: TreeNode[] = [...this.impactTree];

	technologyTree: TreeNode[] = [
		{
			label: 'OneStream',
			key: '1',
		},
		{
			label: 'SAP',
			key: '2',
		},
	];
	technologyTreeSelections: TreeNode[] = [...this.technologyTree];
	technologyTreeOptions: TreeNode[] = [...this.technologyTree];

	benefitsTree: TreeNode[] = [
		{
			label: 'Savings',
			key: '1',
		},
		{
			label: 'Automation',
			key: '2',
		},
	];
	benefitsTreeSelections: TreeNode[] = [...this.benefitsTree];
	benefitsTreeOptions: TreeNode[] = [...this.benefitsTree];

	addProcessDiagram() {
		this.simulationService.addProcessTab = 0;
		this.simulationService.addProcessForm = { name: '', description: '' };
		this.simulationService.addProcessTaxonomyTreeSelected = [];
		this.simulationService.addProcessTaxonomyScope =
			this.simulationService.addProcessTaxonomyScopeOptions[
			this.simulationService.addProcessTaxonomyScopeOptions.length - 1
				];
		this.simulationService.addProcessDialog = true;
	}

	selectionViewOptions: { value: number }[] = [{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }];

	taxonomyPromptStudioBackup: string[] = [];

	changeSelectionView() {
		this.filterIndex = 0;
		this.useCaseFilter = -1;

		if (this.selectionView === 3) {
			if (this.simulationService.taxonomyPromptStudio.length === 0) {
				this.taxonomyPromptStudioBackup = [];
			}
			this.simulationService.taxonomyPromptStudio = [];
		} else {
			const areEqual =
				this.simulationService.taxonomyPromptStudio.length === this.taxonomyPromptStudioBackup.length &&
				new Set(this.simulationService.taxonomyPromptStudio).size ===
				new Set(this.taxonomyPromptStudioBackup).size &&
				this.simulationService.taxonomyPromptStudio.every((item) =>
					this.taxonomyPromptStudioBackup.includes(item),
				);

			if (!areEqual) {
				this.selectedTaxonomy = this.useCasesService.functionsTree.filter((t) =>
					this.simulationService.taxonomyPromptStudio.includes(t.key as string),
				);

				this.taxonomyPromptStudioBackup = this.selectedTaxonomy.map((t) => t.key as string);
				lastValueFrom(this.simulationService.loadSimulations(undefined, this.taxonomyPromptStudioBackup));
			}
		}
	}

	showOpenSimulation: boolean = false;

	openSimulation() {
		this.simulationService._loadSimulations().then(() => {
			this.showOpenSimulation = true;
		});
	}

	selectSimulation(simulation: Simulation) {
		this.showOpenSimulation = false;

		this.simulationService.selectSimulation(simulation);
	}

	showOpenM1Prep: boolean = false;

	openM1Prep() {
		this.simulationService._loadM1Preps().then(() => {
			this.showOpenM1Prep = true;
		});
	}

	selectM1Prep(m1Prep: M1Prep) {
		this.showOpenM1Prep = false;

		this.simulationService.selectM1Prep(m1Prep);
	}

	// Filter Dashboard

	selectedTaxonomy: TreeNode[] = [];
	bSelectedTaxonomy: TreeNode[] = [];

	async updateSelectedFilters(panel = true) {
		const isOpen = !!document.querySelector('.p-multiselect-panel');

		if (
			(panel || (!panel && !isOpen)) &&
			!this.areListsEqual(
				this.selectedTaxonomy.map((t) => t.key as string),
				this.bSelectedTaxonomy.map((t) => t.key as string),
			)
		) {
			this.bSelectedTaxonomy = [...this.selectedTaxonomy];
			const categoryIds = this.selectedTaxonomy.map((t) => t.key as string);

			this.simulationService.loadSimulations(undefined, categoryIds)
				.subscribe(
					(_) => {
						console.log('categories loaded', categoryIds);
					},
					(error) => {
						console.error('Error loading simulations', error);
					},
				);
		}
	}

	areListsEqual(list1: string[], list2: string[]): boolean {
		if (list1.length !== list2.length) return false;

		const set1 = new Set(list1);
		const set2 = new Set(list2);

		if (set1.size !== set2.size) return false;
		for (const item of set1) {
			if (!set2.has(item)) return false;
		}

		return true;
	}
}
