import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { DbService } from './db.service';
import { Collection } from 'dexie';
import {
	CategoryFunction,
	DiagramE2E,
	DiagramProcess,
	DiagramTemplate,
	DigitalWorker,
	Function,
	IFunction,
	ILevel1,
	ILevel2,
	Level1,
	Level2,
	Technology,
} from '../api/e2e-taxonomy.api';
import {
	OptionGetCategoriesFunction,
	OptionGetDigitalWorkers,
	OptionGetFunctions,
	OptionGetLevels1,
	OptionGetLevels2,
	OptionGetTechnologies,
} from './e2e-taxonomy.types';
import { instanceToInstance, plainToInstance } from 'class-transformer';
import { DataUpdateDiagram } from '../components/diagram/diagram.types';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import deepEqual from 'deep-equal';
import { v4 as uuid } from 'uuid';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Organization } from '../api/common';
import { IDiagramBase, IDiagramDataItem } from '../api/base.api';
import { DiagramComponent } from '../components/diagram/diagram.component';
import { Subject } from 'rxjs';

/**
 * Service class for interacting with the E2E Taxonomy data.
 */
@Injectable({
	providedIn: 'root',
})
export class E2ETaxonomyService {
	constructor(
		public authService: AuthService,
		public dbService: DbService,
		public dialogService: DialogService,
		public http: HttpClient,
	) {}

	_newDiagramBase(xml?: string, capture_url?: string, data?: { [key: string]: IDiagramDataItem }): IDiagramBase {
		return {
			id: uuid(),
			xmlImage: capture_url || '',
			xmlData: xml?.startsWith('http') ? xml : '',
			data: data || {},
		};
	}

	newDiagramE2E(fn: IFunction): DiagramE2E {
		return plainToInstance(DiagramE2E, {
			function: fn,
			functionId: fn.id || '',
			...this._newDiagramBase(),
		});
	}

	newDiagramProcess(level1: ILevel1): DiagramProcess {
		return plainToInstance(DiagramProcess, {
			level1,
			level1Id: level1.id || '',
			...this._newDiagramBase(),
		});
	}

	newDiagramTemplate(level2: ILevel2): DiagramTemplate {
		return plainToInstance(DiagramTemplate, {
			level2,
			level2Id: level2.id || '',
			...this._newDiagramBase(),
		});
	}

	currentDiagramE2E: DiagramE2E | undefined;
	currentOriginalDiagramE2E: DiagramE2E | undefined;

	currentDiagramProcess: DiagramProcess | undefined;
	currentOriginalDiagramProcess: DiagramProcess | undefined;

	currentDiagramTemplate: DiagramTemplate | undefined;
	currentOriginalDiagramTemplate: DiagramTemplate | undefined;

	async setCurrentDiagramProcess(id: string): Promise<void> {
		this.currentDiagramProcess = await this.getDiagramProcess(id);
		if (!this.currentDiagramProcess && this.currentLevel1) {
			this.currentDiagramProcess = this.newDiagramProcess(this.currentLevel1);
		}
		this.currentOriginalDiagramProcess = this.currentDiagramProcess
			? instanceToInstance(this.currentDiagramProcess)
			: undefined;
	}

	async setCurrentDiagramTemplate(id: string): Promise<void> {
		this.currentDiagramTemplate = await this.getDiagramTemplate(id);
		if (!this.currentDiagramTemplate && this.currentLevel2) {
			this.currentDiagramTemplate = this.newDiagramTemplate(this.currentLevel2);
		}
		this.currentOriginalDiagramTemplate = this.currentDiagramTemplate
			? instanceToInstance(this.currentDiagramTemplate)
			: undefined;
	}

	async clearCurrentDiagramProcess(): Promise<any> {
		this.currentDiagramProcess = undefined;
		this.currentOriginalDiagramProcess = this.currentDiagramProcess
			? instanceToInstance(this.currentDiagramProcess)
			: undefined;
	}

	async clearCurrentDiagramTemplate(): Promise<any> {
		this.currentDiagramTemplate = undefined;
		this.currentOriginalDiagramTemplate = this.currentDiagramTemplate
			? instanceToInstance(this.currentDiagramTemplate)
			: undefined;
	}

	/**
	 * Retrieves categories based on provided options.
	 *
	 * @param {OptionGetCategoriesFunction} options - The options for retrieving categories.
	 * @param {boolean} [treeNode=false] - Indicates whether to retrieve the categories as tree nodes.
	 * @returns {Promise<CategoryFunction[] | TreeNode[]>} - A promise that resolves to an array of category functions.
	 */
	async getCategoriesFunction(options: OptionGetCategoriesFunction, treeNode?: false): Promise<CategoryFunction[]>;
	async getCategoriesFunction(options: OptionGetCategoriesFunction, treeNode?: true): Promise<TreeNode[]>;
	async getCategoriesFunction(
		options: OptionGetCategoriesFunction = {},
		treeNode: boolean = false,
	): Promise<CategoryFunction[] | TreeNode[]> {
		let query: Collection<CategoryFunction, string>;
		if (options.ids && options.ids.length) {
			query = this.dbService.data_category_function.where('id').anyOf(options.ids);
		} else {
			query = this.dbService.data_category_function.toCollection();
		}
		const organization = await this.authService.getCurrentOrganization();
		const categoriesFunction = (await query.sortBy('sort')).filter((d) => {
			if (organization && organization.taxonomy?.value?.length && d.id) {
				return organization.taxonomy.value.includes(d.id);
			} else {
				return true;
			}
		});
		const hasExtend = options.extend && options.extend.startsWith('function');
		const extendOptionFunction = hasExtend ? (options.extend?.split('.').splice(1).join('.') as any) : '';
		const functions: Function[] = [];
		if (hasExtend) {
			functions.push(
				...(await this.getFunctions({
					categoriesIds: categoriesFunction.map((cf) => cf.id as string),
					extend: extendOptionFunction,
				})),
			);
		}
		if (treeNode) {
			const treeNodes: TreeNode[] = [];
			let children_fields = (extendOptionFunction as string)
				.split('.')
				.map((f) => {
					if (f === 'level1') return 'levels1';
					if (f === 'level2') return 'levels2';
					return f;
				})
				.join('.');
			for (const cf of categoriesFunction) {
				const t = this.makeTreeNode(cf);
				t.data = {
					type: cf.type,
					office: cf.office,
				};
				if (hasExtend) {
					t.children = functions
						.filter((f) => f.categoryId === cf.id)
						.map((f) => {
							const tf = this.makeTreeNode(f, children_fields);
							tf.data = {
								type: f.type,
							};
							return tf;
						});
				}
				treeNodes.push(t);
			}
			return treeNodes;
		} else {
			for (const cf of categoriesFunction) {
				if (hasExtend) {
					cf.functions = functions.filter((f) => f.categoryId === cf.id);
				}
			}
			return categoriesFunction;
		}
	}

	/**
	 * Retrieves a list of functions based on the specified options.
	 *
	 * @param {OptionGetFunctions} [options] - The options to filter the functions.
	 * @param {false} [treeNode=false] - Specifies whether to include functions in child tree nodes.
	 * @returns {Promise<Function[] | TreeNode[]>} A Promise that resolves to an array of Function objects.
	 */
	async getFunctions(options?: OptionGetFunctions, treeNode?: false): Promise<Function[]>;
	async getFunctions(options?: OptionGetFunctions, treeNode?: true): Promise<TreeNode[]>;
	async getFunctions(options: OptionGetFunctions = {}, treeNode: boolean = false): Promise<Function[] | TreeNode[]> {
		let query: Collection<Function, string>;
		if (options.ids && options.ids.length) {
			if (options.categoryId) {
				query = this.dbService.data_function
					.where('[id+categoryId]')
					.anyOf(options.ids.map((id) => [id, options.categoryId as string]));
			} else {
				query = this.dbService.data_function.where('id').anyOf(options.ids);
			}
		} else {
			if (options.categoryId) {
				query = this.dbService.data_function.where('categoryId').equals(options.categoryId);
			}
			if (options.categoriesIds && options.categoriesIds.length) {
				query = this.dbService.data_function.where('categoryId').anyOf(options.categoriesIds);
			} else {
				query = this.dbService.data_function.toCollection();
			}
		}
		const organization = await this.authService.getCurrentOrganization();
		const functions = (await query.sortBy('sort')).filter((d) => {
			if (organization && organization.taxonomy?.value?.length && d.id) {
				return organization.taxonomy.value.includes(d.id);
			} else {
				return true;
			}
		});
		const hasExtend = options.extend && options.extend.startsWith('level1');
		const extendOptionLevel1 = hasExtend ? (options.extend?.split('.').splice(1).join('.') as any) : '';
		const levels1: Level1[] = [];
		if (hasExtend) {
			levels1.push(
				...(await this.getLevels1({
					functionsIds: functions.map((f) => f.id as string),
					extend: extendOptionLevel1,
				})),
			);
		}
		if (treeNode) {
			const treeNodes: TreeNode[] = [];
			let children_fields = (extendOptionLevel1 as string)
				.split('.')
				.map((f) => {
					if (f === 'level2') return 'levels2';
					return f;
				})
				.join('.');
			for (const f of functions) {
				const t = this.makeTreeNode(f);
				if (hasExtend) {
					t.children = levels1
						.filter((l1) => l1.functionId === f.id)
						.map((l1) => this.makeTreeNode(l1, children_fields));
				}
				t.selectable = !t.children?.length;
				treeNodes.push(t);
			}
			return treeNodes;
		} else {
			let diagrams: DiagramE2E[] = await this.dbService.data_diagram_e2e
				.where('functionId')
				.anyOf(functions.map((fn) => fn.id as string))
				.toArray();
			for (const f of functions) {
				if (hasExtend) {
					f.levels1 = levels1.filter((l1) => l1.functionId === f.id);
				}
				const diagram = diagrams.find((d) => d.functionId === f.id);
				if (diagram) {
					f.diagram = diagram;
				} else {
					f.diagram = this.newDiagramE2E(f);
					await this.saveDiagramE2E(f.diagram);
				}
			}
			return functions;
		}
	}

	/**
	 * Retrieves the level 1 data based on the provided options.
	 *
	 * @param {OptionGetLevels1} options - The options to filter the level 1 data.
	 * @param {boolean} [treeNode=false] - Determines whether to include tree node information in the result.
	 * @returns {Promise<Level1[] | TreeNode[]>} A promise that resolves with an array of level 1 data objects.
	 */
	async getLevels1(options: OptionGetLevels1, treeNode?: false): Promise<Level1[]>;
	async getLevels1(options: OptionGetLevels1, treeNode?: true): Promise<TreeNode[]>;
	async getLevels1(options: OptionGetLevels1 = {}, treeNode: boolean = false): Promise<Level1[] | TreeNode[]> {
		let query: Collection<Level1, string>;
		if (options.ids && options.ids.length) {
			if (options.functionId) {
				query = this.dbService.data_level1
					.where('[id+functionId]')
					.anyOf(options.ids.map((id) => [id, options.functionId as string]));
			} else {
				query = this.dbService.data_level1.where('id').anyOf(options.ids);
			}
		} else {
			if (options.functionId) {
				query = this.dbService.data_level1.where('functionId').equals(options.functionId);
			} else if (options.functionsIds && options.functionsIds.length) {
				query = this.dbService.data_level1.where('functionId').anyOf(options.functionsIds);
			} else {
				query = this.dbService.data_level1.toCollection();
			}
		}
		const organization = await this.authService.getCurrentOrganization();
		const levels1 = (await this.sortTableByName(query.toArray())).filter((d) => {
			if (organization && organization.taxonomy?.value?.length && d.id) {
				return organization.taxonomy.value.includes(d.id);
			} else {
				return true;
			}
		});
		const optionsL2: OptionGetLevels2 = {
			level1sIds: levels1.map((l1) => l1.id as string),
		};
		if (!treeNode) {
			optionsL2.extend = options.extend?.split('.').splice(1).join('.') as any;
			optionsL2.valuesTechnologySort = options.level2TechnologySort;
		}
		const hasExtend = options.extend && options.extend.startsWith('level2');
		const levels2: Level2[] = [];
		if (hasExtend) {
			levels2.push(...(await this.getLevels2(optionsL2)));
		}
		if (treeNode) {
			const treeNodes: TreeNode[] = [];
			for (const l1 of levels1) {
				const t = this.makeTreeNode(l1);
				if (hasExtend) {
					t.children = levels2.filter((l2) => l2.level1Id === l1.id).map((l2) => this.makeTreeNode(l2));
				}
				t.selectable = !t.children?.length;
				treeNodes.push(t);
			}
			return treeNodes;
		} else {
			let diagrams: DiagramProcess[] = await this.dbService.data_diagram_process
				.where('level1Id')
				.anyOf(levels1.map((l1) => l1.id as string))
				.toArray();
			for (const l1 of levels1) {
				if (hasExtend) {
					l1.levels2 = levels2.filter((l2) => l2.level1Id === l1.id);
				}
				const diagram = diagrams.find((d) => d.level1Id === l1.id);
				if (diagram) {
					l1.diagram = diagram;
				} else {
					l1.diagram = this.newDiagramProcess(l1);
					await this.saveDiagramProcess(l1.diagram);
				}
			}
			return levels1;
		}
	}

	/**
	 * Retrieves the level 2 data based on the provided options.
	 *
	 * @param {OptionGetLevels2} options - The options to customize the level 2 data retrieval.
	 * @param {boolean} [treeNode=false] - Determines if the level 2 data should be retrieved for a specific tree node. Defaults to false.
	 * @return {Promise<Level2[] | TreeNode[]>} - A promise that resolves to an array of level 2 data.
	 */
	async getLevels2(options: OptionGetLevels2, treeNode?: false): Promise<Level2[]>;
	async getLevels2(options: OptionGetLevels2, treeNode?: true): Promise<TreeNode[]>;
	async getLevels2(options: OptionGetLevels2 = {}, treeNode: boolean = false): Promise<Level2[] | TreeNode[]> {
		let query: Collection<Level2, string>;
		if (options.ids && options.ids.length) {
			if (options.level1Id) {
				query = this.dbService.data_level2
					.where('[id+level1Id]')
					.anyOf(options.ids.map((id) => [id, options.level1Id as string]));
			} else {
				query = this.dbService.data_level2.where('id').anyOf(options.ids);
			}
		} else {
			if (options.level1Id) {
				query = this.dbService.data_level2.where('level1Id').equals(options.level1Id);
			} else if (options.level1sIds && options.level1sIds.length) {
				query = this.dbService.data_level2.where('level1Id').anyOf(options.level1sIds);
			} else {
				query = this.dbService.data_level2.toCollection();
			}
		}
		const organization = await this.authService.getCurrentOrganization();
		const levels2 = (await this.sortTableByName(query.toArray())).filter((d) => {
			if (organization && organization.taxonomy?.value?.length && d.id) {
				return organization.taxonomy.value.includes(d.id);
			} else {
				return true;
			}
		});
		if (treeNode) {
			return levels2.map((l2) => this.makeTreeNode(l2));
		} else {
			if (options.extend) {
				let diagrams: DiagramTemplate[] = [];
				if (options.extend === 'diagram' || options.extend === '*') {
					diagrams = await this.dbService.data_diagram_template
						.where('level2Id')
						.anyOf(levels2.map((l2) => l2.id as string))
						.toArray();
				}
				for (const l2 of levels2) {
					const diagram = diagrams.find((d) => d.level2Id === l2.id);
					if (diagram) {
						l2.diagram = diagram;
					} else {
						l2.diagram = this.newDiagramTemplate(l2);
						await this.saveDiagramTemplate(l2.diagram);
					}
				}
			}
			if (options.recursiveRelation) {
				for (const l2 of levels2) {
					await this.getLevel1(l2.level1Id, options.recursiveRelation).then((level1) => {
						if (level1) {
							l2.level1 = level1;
						}
					});
				}
			}
			return levels2;
		}
	}

	/**
	 * Fetches the technologies based on the given options.
	 *
	 * @param {OptionGetTechnologies} [options] - The options for fetching technologies. Optional.
	 * @param {boolean} [treeNode=false] - Specifies if the technologies should be returned as tree nodes. Optional.
	 * @returns {Promise<Technology[] | TreeNode[]>} - A promise that resolves to an array of technologies.
	 */
	async getTechnologies(options?: OptionGetTechnologies, treeNode?: false): Promise<Technology[]>;
	async getTechnologies(options?: OptionGetTechnologies, treeNode?: true): Promise<TreeNode[]>;
	async getTechnologies(
		options: OptionGetTechnologies = {},
		treeNode: boolean = false,
	): Promise<Technology[] | TreeNode[]> {
		let query: Collection<Technology, string>;
		if (options.ids && options.ids.length) {
			query = this.dbService.data_technology.where('id').anyOf(options.ids);
		} else {
			query = this.dbService.data_technology.toCollection();
		}
		let technologies = await query.sortBy('name');
		if (options.technologySort && options.technologySort.length) {
			technologies = technologies.sort((a, b) => {
				if (options.technologySort && options.technologySort.length) {
					return (
						options.technologySort.findIndex((v) => v === a.id) -
						options.technologySort.findIndex((v) => v === b.id)
					);
				} else {
					return 0;
				}
			});
		}
		if (!options.neto) {
			technologies = technologies.filter((t) => !t.neto);
		}
		if (treeNode) {
			return technologies.map((t) => this.makeTreeNode(t));
		} else {
			return technologies;
		}
	}

	/**
	 * Retrieves digital workers based on the provided options.
	 *
	 * @param {OptionGetDigitalWorkers} [options] - The options to filter digital workers (optional).
	 * @param {boolean} [treeNode=false] - If true, return only digital workers associated with tree nodes (optional).
	 * @returns {Promise<DigitalWorker[] | TreeNode[]>} - The promise that resolves to an array of digital workers.
	 */
	async getDigitalWorkers(options?: OptionGetDigitalWorkers, treeNode?: false): Promise<DigitalWorker[]>;
	async getDigitalWorkers(options?: OptionGetDigitalWorkers, treeNode?: true): Promise<TreeNode[]>;
	async getDigitalWorkers(
		options: OptionGetDigitalWorkers = {},
		treeNode: boolean = false,
	): Promise<DigitalWorker[] | TreeNode[]> {
		let query: Collection<DigitalWorker, string>;
		if (options.ids && options.ids.length) {
			if (options.technologyId) {
				query = this.dbService.data_digital_worker
					.where('[id+technologyId]')
					.anyOf(options.ids.map((id) => [id, options.technologyId as string]));
			} else {
				query = this.dbService.data_digital_worker.where('id').anyOf(options.ids);
			}
		} else {
			if (options.technologiesIds) {
				query = this.dbService.data_digital_worker.where('technologyId').anyOf(options.technologiesIds);
			} else if (options.technologyId) {
				query = this.dbService.data_digital_worker.where('technologyId').equals(options.technologyId);
			} else {
				query = this.dbService.data_digital_worker.toCollection();
			}
		}
		const digitalWorkers = await query.sortBy('name');
		if (treeNode) {
			const treeNodes: TreeNode[] = [];
			for (const d of digitalWorkers) {
				const t = this.makeTreeNode(d);
				treeNodes.push(t);
			}
			return treeNodes;
		} else {
			return digitalWorkers;
		}
	}

	/**
	 * Creates a tree node object based on the provided data.
	 *
	 * @param {Object} obj - An object containing the node's data.
	 * @param {string} [children_field=''] - The name of the children field in the data object.
	 * @return {TreeNode} The created tree node object.
	 */
	makeTreeNode(obj: { id?: string; name: string }, children_field: string = ''): TreeNode {
		const children: TreeNode[] = [];
		if (children_field) {
			const fields = children_field.split('.');
			let extendOptionFunction = '';
			if (fields.length > 1) {
				extendOptionFunction = fields.splice(1).join('.');
			}
			children_field = fields[0];
			if ((obj as any)[children_field] && (obj as any)[children_field].length) {
				children.push(
					...(obj as any)[children_field].map((o: any) => this.makeTreeNode(o, extendOptionFunction)),
				);
			}
		}
		return {
			key: obj.id,
			label: obj.name,
			children,
		};
	}

	/**
	 * Returns the category function based on the given ID.
	 *
	 * @param {string} id - The ID of the category function.
	 * @returns {Promise<CategoryFunction | undefined>} A promise that resolves with the category function object, or `undefined` if the category function is not found.
	 */
	async getCategoryFunction(id: string): Promise<CategoryFunction | undefined> {
		const organization: Organization | undefined = await this.authService.getCurrentOrganization();
		let valid = false;
		if (organization && organization.taxonomy?.value?.length) {
			valid = organization.taxonomy.value.includes(id);
		} else {
			valid = true;
		}
		if (valid) {
			return this.dbService.data_category_function.get(id);
		} else {
			return undefined;
		}
	}

	/**
	 * Retrieves a function from the database.
	 *
	 * @param {string} id - The ID of the function to retrieve.
	 * @param {boolean} [recursiveRelation] - Whether to retrieve the function's category recursively.
	 * @returns {Promise<Function | undefined>} A Promise that resolves with the retrieved function object.
	 */
	async getFunction(id: string, recursiveRelation?: boolean): Promise<Function | undefined> {
		const f: Function | undefined = await this.dbService.data_function.get(id);
		if (f && recursiveRelation) {
			await this.getCategoryFunction(f.categoryId).then((cf) => {
				if (cf) {
					f.category = cf;
				}
			});
		}
		return f;
	}

	/**
	 * Retrieves Level1 data from the database.
	 *
	 * @param {string} id - The ID of the Level1 data to retrieve.
	 * @param {boolean} [recursiveRelation] - Specifies whether to retrieve the recursive relation data.
	 * @returns {Promise<Level1 | undefined>} A Promise that resolves with the retrieved Level1 data, or undefined if not found.
	 */
	async getLevel1(id: string, recursiveRelation?: boolean): Promise<Level1 | undefined> {
		const l1: Level1 | undefined = await this.dbService.data_level1.get(id);
		if (l1 && recursiveRelation) {
			await this.getFunction(l1.functionId, true).then((f) => {
				if (f) {
					l1.function = f;
				}
			});
		}
		return l1;
	}

	/**
	 * Retrieves the Level2 object with the given ID.
	 *
	 * @param {string} id - The ID of the Level2 object to retrieve.
	 * @param {boolean} [recursiveRelation] - Specifies whether to retrieve the recursive relation data.
	 * @return {Promise<Level2 | undefined>} - A promise that resolves to the Level2 object
	 * with the given ID, or undefined if no Level2 object is found.
	 */
	async getLevel2(id: string, recursiveRelation?: boolean): Promise<Level2 | undefined> {
		const l2: Level2 | undefined = await this.dbService.data_level2.get(id);
		if (l2 && recursiveRelation) {
			await this.getLevel1(l2.level1Id, true).then((l1) => {
				if (l1) {
					l2.level1 = l1;
				}
			});
		}
		return l2;
	}

	currentFunction: Function | undefined;
	currentOriginalFunction: Function | undefined;

	currentLevel1: Level1 | undefined;
	currentOriginalLevel1: Level1 | undefined;

	currentLevel2: Level2 | undefined;
	currentOriginalLevel2: Level2 | undefined;

	async getDiagramE2E(query: string | { functionId: string }): Promise<DiagramE2E | undefined> {
		return this.dbService.data_diagram_e2e.get(query as any);
	}

	async getRelationFunction(fn: Function, diagram: boolean = false): Promise<void> {
		if (diagram) {
			const diagramE2E = await this.getDiagramE2E({ functionId: fn.id || '' });
			if (diagramE2E) {
				fn.diagram = diagramE2E;
			} else {
				fn.diagram = this.newDiagramE2E(fn);
				await this.saveDiagramE2E(fn.diagram);
			}
		}
	}

	async saveDiagramE2E(diagram: DiagramE2E): Promise<DiagramE2E> {
		const original_diagram = await this.getDiagramE2E(diagram.id || '');
		const data = plainToInstance(DiagramE2E, {
			...(original_diagram || {}),
			...diagram,
		});
		if (!data.id) {
			data.id = uuid();
		}
		await this.dbService.data_diagram_e2e.put(data);
		return data;
	}

	async getDiagramProcess(query: string | { level1Id: string }): Promise<DiagramProcess | undefined> {
		return this.dbService.data_diagram_process.get(query as any);
	}

	async getRelationLevel1(level1: Level1, diagram: boolean = false): Promise<void> {
		if (diagram) {
			const diagramProcess = await this.getDiagramProcess({ level1Id: level1.id || '' });
			if (diagramProcess) {
				level1.diagram = diagramProcess;
			} else {
				level1.diagram = this.newDiagramProcess(level1);
				await this.saveDiagramProcess(level1.diagram);
			}
		}
	}

	async saveDiagramProcess(diagram: DiagramProcess): Promise<DiagramProcess> {
		const original_diagram = await this.getDiagramProcess(diagram.id || '');
		const data = plainToInstance(DiagramProcess, {
			...(original_diagram || {}),
			...diagram,
		});
		if (!data.id) {
			data.id = uuid();
		}
		await this.dbService.data_diagram_process.put(data);
		return data;
	}

	async getDiagramTemplate(query: string | { level2Id: string }): Promise<DiagramTemplate | undefined> {
		return this.dbService.data_diagram_template.get(query as any);
	}

	async getRelationLevel2(level2: Level2, diagram: boolean = false): Promise<void> {
		if (diagram) {
			const diagram = (await this.getDiagramTemplate({ level2Id: level2.id || '' })) as DiagramTemplate;
			if (diagram) {
				level2.diagram = diagram;
			} else {
				level2.diagram = this.newDiagramTemplate(level2);
				await this.saveDiagramTemplate(level2.diagram);
			}
		}
	}

	async saveDiagramTemplate(diagram: DiagramTemplate): Promise<DiagramTemplate> {
		const original_diagram = await this.getDiagramTemplate(diagram.id || '');
		const data = plainToInstance(DiagramTemplate, {
			...(original_diagram || {}),
			...diagram,
		});
		if (!data.id) {
			data.id = uuid();
		}
		await this.dbService.data_diagram_template.put(data);
		return data;
	}

	async setCurrentFunction(id: string): Promise<void> {
		this.currentFunction = await this.getFunction(id);
		if (this.currentFunction) {
			await this.getRelationFunction(this.currentFunction, true);
			this.currentDiagramE2E = this.currentFunction.diagram;
		}
		this.currentOriginalFunction = this.currentFunction ? instanceToInstance(this.currentFunction) : undefined;
	}

	async saveCurrentFunction(): Promise<string> {
		if (this.currentFunction) {
			if (!this.currentFunction.name) {
				return 'no-name';
			}
			const updated = !deepEqual(this.currentOriginalLevel2, this.currentFunction);
			if (updated) {
				await this.saveFunction(this.currentFunction);
				this.currentOriginalFunction = plainToInstance(Function, {
					...this.currentFunction,
					category: undefined,
					levels1: undefined,
				});
				this.currentOriginalFunction.category = this.currentFunction.category;
				this.currentOriginalFunction.levels1 = this.currentFunction.levels1;
				return '';
			} else {
				return 'no-updated';
			}
		}
		return 'no-data';
	}

	async saveFunction(fn: Function): Promise<Function> {
		const original_function = await this.dbService.data_function.get(fn.id || '');
		const data = plainToInstance(Function, {
			...(original_function || {}),
			...fn,
			category: undefined,
			levels1: undefined,
		});
		if (!data.id) {
			data.id = uuid();
		}
		await this.dbService.data_function.put(data);
		return data;
	}

	async setCurrentLevel1(id: string): Promise<void> {
		this.currentLevel1 = await this.getLevel1(id);
		if (this.currentLevel1) {
			await this.getRelationLevel1(this.currentLevel1, true);
			this.currentDiagramProcess = this.currentLevel1.diagram;
		}
		this.currentOriginalLevel1 = this.currentLevel1 ? instanceToInstance(this.currentLevel1) : undefined;
	}

	async saveCurrentLevel1(): Promise<string> {
		if (this.currentLevel1) {
			if (!this.currentLevel1.name) {
				return 'no-name';
			}
			const updated = !deepEqual(this.currentOriginalLevel1, this.currentLevel1);
			if (updated) {
				await this.saveLevel1(this.currentLevel1);
				this.currentOriginalLevel1 = plainToInstance(Level1, {
					...this.currentLevel1,
					function: undefined,
					levels2: undefined,
				});
				this.currentOriginalLevel1.function = this.currentLevel1.function;
				this.currentOriginalLevel1.levels2 = this.currentLevel1.levels2;
				return '';
			} else {
				return 'no-updated';
			}
		}
		return 'no-data';
	}

	async saveLevel1(level1: Level1): Promise<Level1> {
		const original_level1 = await this.dbService.data_level1.get(level1.id || '');
		const data = plainToInstance(Level1, {
			...(original_level1 || {}),
			...level1,
			function: undefined,
			levels2: undefined,
		});
		if (!data.id) {
			data.id = uuid();
		}
		await this.dbService.data_level1.put(data);
		return data;
	}

	/**
	 * Set the current level 2 based on the provided ID.
	 *
	 * @param {string} id - The ID of the level 2.
	 * @returns {Promise<void>}
	 */
	async setCurrentLevel2(id: string): Promise<void> {
		this.currentLevel2 = await this.getLevel2(id);
		if (this.currentLevel2) {
			await this.getRelationLevel2(this.currentLevel2, true);
			this.currentDiagramTemplate = this.currentLevel2.diagram;
		}
		this.currentOriginalLevel2 = this.currentLevel2 ? instanceToInstance(this.currentLevel2) : undefined;
	}

	/**
	 * Saves the current level 2.
	 * @returns A Promise that resolves to a string:
	 *  - "no-name" if the current level 2 does not have a name.
	 *  - "" if the current level 2 is updated and saved successfully.
	 *  - "no-updated" if the current level 2 is not updated.
	 *  - "no-data" if there is no current level 2.
	 */
	async saveCurrentLevel2(): Promise<string> {
		if (this.currentLevel2) {
			if (!this.currentLevel2.name) {
				return 'no-name';
			}
			const updated = !deepEqual(this.currentOriginalLevel2, this.currentLevel2);
			if (updated) {
				await this.saveLevel2(this.currentLevel2);
				this.currentOriginalLevel2 = plainToInstance(Level2, {
					...this.currentLevel2,
					diagram: undefined,
					level1: undefined,
				});
				this.currentOriginalLevel2.diagram = this.currentLevel2.diagram;
				this.currentOriginalLevel2.level1 = this.currentLevel2.level1;
				return '';
			} else {
				return 'no-updated';
			}
		}
		return 'no-data';
	}

	/**
	 * Saves a Level2 object to the database.
	 *
	 * @param {Level2} level2 - The Level2 object to save.
	 * @returns {Promise<Level2>} - A promise that resolves with the saved Level2 object.
	 */
	async saveLevel2(level2: Level2): Promise<Level2> {
		const original_level2 = await this.dbService.data_level2.get(level2.id || '');
		const data = plainToInstance(Level2, {
			...(original_level2 || {}),
			...level2,
			diagram: undefined,
			level1: undefined,
		});
		if (!data.id) {
			data.id = uuid();
		}
		await this.dbService.data_level2.put(data);
		return data;
	}

	/**
	 * Sorts an array of objects by name.
	 *
	 * @param {Promise<T[]>} data - A promise that resolves to an array of objects with a `name` property.
	 * @returns {Promise<T[]>} - A promise that resolves to the sorted array of objects.
	 */
	async sortTableByName<T extends { name: string }>(data: Promise<T[]>): Promise<T[]> {
		return data.then((arr) => {
			arr.sort((a, b) => {
				const regex = /(\D*)(\d*\.?\d*)/;
				const matchA = a.name.match(regex);
				const matchB = b.name.match(regex);

				if (!matchA || !matchB) {
					return 0;
				}

				const textA = matchA[1].trim().toLowerCase();
				const textB = matchB[1].trim().toLowerCase();
				const numA = parseFloat(matchA[2]) || 0;
				const numB = parseFloat(matchB[2]) || 0;

				if (textA !== textB) {
					return textA.localeCompare(textB);
				}

				return numA - numB;
			});

			return arr;
		});
	}

	hasModalDiagram: boolean = false;

	updateDiagramModal(data: DataUpdateDiagram): DynamicDialogRef | undefined {
		if (!this.hasModalDiagram) {
			document.body.style.cursor = 'wait';
			this.hasModalDiagram = true;
			const ref: DynamicDialogRef = this.dialogService.open(DiagramComponent, {
				data,
				header: 'Diagram',
				width: '100%',
				height: '100%',
				styleClass: 'diagram-modal',
				contentStyle: { overflow: 'auto' },
				showHeader: false,
				baseZIndex: 100,
				maximizable: false,
				closeOnEscape: false,
			});
			setTimeout(() => {
				document.body.style.cursor = 'default';
			}, 150);
			ref.onClose.subscribe(() => {
				this.hasModalDiagram = false;
			});
			ref.onDestroy.subscribe(() => {
				this.hasModalDiagram = false;
			});
			return ref;
		}
		return undefined;
	}

	async editDiagramE2E(fn: Function) {
		if (fn && fn.id) {
			const preventClick = (e: any) => {
				e.stopPropagation();
				e.preventDefault();
			};

			document.body.style.cursor = 'wait';
			document.body.addEventListener('click', preventClick, true);

			this.lastFunction = undefined;
			await this.setCurrentFunction(fn.id);
			if (this.currentFunction) {
				this.currentFunction.category = fn.category;
				this.currentFunction.levels1 = fn.levels1;
			}
			if (this.currentDiagramE2E?.id) {
				const ref = this.updateDiagramModal({
					diagramE2EId: this.currentDiagramE2E.id,
				});

				if (ref) {
					const onClose = () => {
						if (this.currentFunction) {
							fn.name = this.currentFunction.name;
							fn.description = this.currentFunction.description;
						}
						if (this.currentDiagramE2E) {
							fn.diagram = this.currentDiagramE2E;
						}
						fn.category.functions = fn.category.functions.map((l) => {
							return l.id === fn.id ? fn : l;
						});
					};

					ref.onClose.pipe().subscribe(() => {
						onClose();
					});

					ref.onDestroy.pipe().subscribe(() => {
						onClose();
					});
				}
			}

			setTimeout(() => {
				document.body.style.cursor = 'default';
				document.body.removeEventListener('click', preventClick, true);
			}, 150);
		}
	}

	initFunction: boolean = false;
	lastFunction: Function | undefined = undefined;

	async editDiagramProcess(level1: Level1) {
		if (level1 && level1.id) {
			const preventClick = (e: any) => {
				e.stopPropagation();
				e.preventDefault();
			};

			document.body.style.cursor = 'wait';
			document.body.addEventListener('click', preventClick, true);

			if (this.lastLevel1 && this.initFunction) {
				this.lastFunction = level1.function;
			}
			this.lastLevel1 = undefined;
			await this.setCurrentLevel1(level1.id);
			if (this.currentLevel1) {
				this.currentLevel1.function = level1.function;
				this.currentLevel1.levels2 = level1.levels2;
			}
			if (this.currentDiagramProcess?.id) {
				const ref = this.updateDiagramModal({
					diagramProcessId: this.currentDiagramProcess.id,
				});

				if (ref) {
					const onClose = () => {
						if (this.currentLevel1) {
							level1.name = this.currentLevel1.name;
							level1.description = this.currentLevel1.description;
						}
						if (this.currentDiagramProcess) {
							level1.diagram = this.currentDiagramProcess;
						}
						level1.function.levels1 = level1.function.levels1.map((l) => {
							return l.id === level1.id ? level1 : l;
						});
						if (this.lastFunction) {
							this.editDiagramE2E(this.lastFunction);
						}
					};

					ref.onClose.pipe().subscribe(() => {
						onClose();
					});

					ref.onDestroy.pipe().subscribe(() => {
						onClose();
					});
				}
			}

			setTimeout(() => {
				document.body.style.cursor = 'default';
				document.body.removeEventListener('click', preventClick, true);
			}, 150);
		}
	}

	lastLevel1: Level1 | undefined = undefined;

	diagramTemplateSubject: Subject<string> = new Subject();
	diagramTemplateObservable = this.diagramTemplateSubject.asObservable();

	async editDiagramTemplate(level2: Level2) {
		if (level2 && level2.id) {
			const preventClick = (e: any) => {
				e.stopPropagation();
				e.preventDefault();
			};

			document.body.style.cursor = 'wait';
			document.body.addEventListener('click', preventClick, true);

			await this.setCurrentLevel2(level2.id);
			if (this.currentLevel2) {
				this.currentLevel2.level1 = level2.level1;
			}
			if (this.currentDiagramTemplate) {
				const ref = this.updateDiagramModal({
					diagramTemplateId: this.currentDiagramTemplate.id,
				});

				if (ref) {
					const onClose = () => {
						if (this.currentLevel2) {
							level2.name = this.currentLevel2.name;
							level2.description = this.currentLevel2.description;
							level2.values = this.currentLevel2.values;
						}
						if (this.currentDiagramTemplate) {
							level2.diagram = this.currentDiagramTemplate;
						}
						level2.level1.levels2 = level2.level1.levels2.map((l) => {
							return l.id === level2.id ? level2 : l;
						});
						if (this.lastLevel1) {
							this.editDiagramProcess(this.lastLevel1);
						}
					};

					ref.onClose.pipe().subscribe(() => {
						onClose();
					});

					ref.onDestroy.pipe().subscribe(() => {
						onClose();
					});
				}
			}

			setTimeout(() => {
				document.body.style.cursor = 'default';
				document.body.removeEventListener('click', preventClick, true);
			}, 150);
		}
	}
}
