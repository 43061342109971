import { Component } from '@angular/core';
import { UseCaseReadinessModalService } from '../use-case-readiness-modal.service';
import { Menu } from 'primeng/menu';
import { MenuItem } from 'primeng/api';

@Component({
	selector: 'app-use-cases-readiness-result',
	templateUrl: './result.component.html',
	styleUrls: ['./result.component.scss'],
})
export class ResultReadinessComponent {
	constructor(public service: UseCaseReadinessModalService) {}

	impactOptions: string[] = ['Breakthrough', 'Transformative', 'Incremental'];
}
