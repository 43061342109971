import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

export class Assistant {
	constructor(private http: HttpClient) {}

	run<T>(assistant: string, content: string, callback: (data: T) => void) {
		this.createThread().subscribe((response) => {
			const threadId = response.id;

			this.addUserQuestion(threadId, content).subscribe(() => {
				this.runThread(threadId, assistant).subscribe((runResponse) => {
					this.checkMessage(threadId, runResponse.id, callback);
				});
			});
		});
	}

	private checkMessage<T>(threadId: string, runId: string, callback: (data: T) => void) {
		this.getRunStatus(threadId, runId).subscribe((statusResponse) => {
			if (statusResponse.status === 'completed') {
				this.getAssistantResponse(threadId).subscribe((assistantResponse) => {
					callback(JSON.parse(assistantResponse.data[0].content[0].text.value));
				});
			} else {
				setTimeout(() => {
					this.checkMessage(threadId, runId, callback);
				});
			}
		});
	}

	private endpoint = 'openai-aiexplorer-prod.openai.azure.com';
	private apiVersion = '2024-05-01-preview';

	private getHeaders(): HttpHeaders {
		return new HttpHeaders({
			'api-key': '9b98d88cfa224c7ebf3c453fbab3de73',
			'Content-Type': 'application/json',
		});
	}

	createThread(): Observable<any> {
		const url = `https://${this.endpoint}/openai/threads?api-version=${this.apiVersion}`;
		return this.http.post(url, {}, { headers: this.getHeaders() });
	}

	addUserQuestion(threadId: string, content: string): Observable<any> {
		const url = `https://${this.endpoint}/openai/threads/${threadId}/messages?api-version=${this.apiVersion}`;
		const body = {
			role: 'user',
			content: content,
		};
		return this.http.post(url, body, { headers: this.getHeaders() });
	}

	runThread(threadId: string, assistant_id: string): Observable<any> {
		const url = `https://${this.endpoint}/openai/threads/${threadId}/runs?api-version=${this.apiVersion}`;
		const body = {
			assistant_id,
		};
		return this.http.post(url, body, { headers: this.getHeaders() });
	}

	getRunStatus(threadId: string, runId: string): Observable<any> {
		const url = `https://${this.endpoint}/openai/threads/${threadId}/runs/${runId}?api-version=${this.apiVersion}`;
		return this.http.get(url, { headers: this.getHeaders() });
	}

	getAssistantResponse(threadId: string): Observable<any> {
		const url = `https://${this.endpoint}/openai/threads/${threadId}/messages?api-version=${this.apiVersion}`;
		return this.http.get(url, { headers: this.getHeaders() });
	}
}
