import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-type-icon',
	template: `
		<ng-container>
			<ng-template [ngIf]="type === 'Embedded'">
				<!-- SVG for Embedded -->
				<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g id="icon/embedded-hover" clip-path="url(#clip0_5867_46618)">
						<path id="Vector" d="M12.6663 3.33325L14.6663 1.33325M1.33301 14.6666L3.33301 12.6666M4.99967 8.99992L6.66634 7.33325M6.99967 10.9999L8.66634 9.33325M4.19967 13.5333C4.34832 13.6824 4.52495 13.8008 4.71943 13.8815C4.91391 13.9623 5.12243 14.0038 5.33301 14.0038C5.54359 14.0038 5.7521 13.9623 5.94658 13.8815C6.14107 13.8008 6.3177 13.6824 6.46634 13.5333L7.99967 11.9999L3.99967 7.99992L2.46634 9.53325C2.31718 9.6819 2.19883 9.85853 2.11807 10.053C2.03732 10.2475 1.99575 10.456 1.99575 10.6666C1.99575 10.8772 2.03732 11.0857 2.11807 11.2802C2.19883 11.4746 2.31718 11.6513 2.46634 11.7999L4.19967 13.5333ZM7.99967 3.99992L11.9997 7.99992L13.533 6.46658C13.6822 6.31794 13.8005 6.14131 13.8813 5.94683C13.962 5.75235 14.0036 5.54383 14.0036 5.33325C14.0036 5.12267 13.962 4.91416 13.8813 4.71967C13.8005 4.52519 13.6822 4.34856 13.533 4.19992L11.7997 2.46659C11.651 2.31742 11.4744 2.19907 11.2799 2.11832C11.0854 2.03756 10.8769 1.99599 10.6663 1.99599C10.4558 1.99599 10.2472 2.03756 10.0528 2.11832C9.85828 2.19907 9.68165 2.31742 9.53301 2.46659L7.99967 3.99992Z" stroke="#1B54F8" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
					</g>
					<defs>
						<clipPath id="clip0_5867_46618">
							<rect width="16" height="16" fill="white"/>
						</clipPath>
					</defs>
				</svg>
			</ng-template>

			<ng-template [ngIf]="type === 'ZBrain'">
				<!-- SVG for ZBrain -->
				<svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M6.38867 14.1491C6.93718 15.2435 8.11394 16.0001 9.47714 16.0001H19.7295V7.45966C19.7295 5.67057 18.1995 4.22021 16.3121 4.22021H14.913V9.41614C14.913 12.03 12.8265 14.1491 10.2528 14.1491H6.38867Z" fill="#ACE2F4"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H10.2524C12.1398 0 13.6699 1.55396 13.6699 3.47086V9.15043C13.6699 11.0674 12.1398 12.6213 10.2524 12.6213H3.41746C1.53005 12.6213 0 11.0674 0 9.15043V0Z" fill="#5E66D6"/>
				</svg>
			</ng-template>

			<ng-template [ngIf]="type === 'Custom'">
				<!-- SVG for Custom -->
				<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g id="icon/custom-hover" clip-path="url(#clip0_5867_46627)">
						<path id="Vector" d="..." stroke="#1B54F8" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
					</g>
				</svg>
			</ng-template>

			<ng-template [ngIf]="!type">
				<!-- Default SVG -->
				<svg width="16" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<text x="0" y="14" font-size="12" fill="#ccc">N/A</text>
				</svg>
			</ng-template>
		</ng-container>
	`,
	styles: [],
})
export class TypeIconComponent {
	@Input() type: string | undefined;

}
