import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { ApiService } from '../../service/api.service';
import { Requirements, PocInput, UseCase, InputsDataSource } from './types';
import { v4 as uuid } from 'uuid';

@Injectable({
	providedIn: 'root',
})
export class DesignNexusService {
	constructor(private apiService: ApiService) {
	}

	_onLoad: boolean = false;

	async onLoad() {
		if (!this._onLoad) {
			await this.getData();
		}
	}

	data: TreeNode[] = [];
	useCases: UseCase[] = [];

	async getData() {
		const data = await this.apiService.client.query({
			useCase: {
				useCase: {
					fetch: {
						...this.apiService.mergeArgs(
							await this.apiService.organizationArgs(),
							this.apiService.queryArgs({
								opportunities__valid: true,
							}),
						),
						id: true,
						name: true,
						diagram: { id: true },
						opportunities: {
							...this.apiService.queryArgs({ valid: true }),
							id: true,
							name: true,
							description: true,
							checked: true,
							valid: true,
							data: true,
							technologies: { id: true, name: true },
							poc: {
								id: true,
								title: true,
								approved: true,
								deploymentHandshake: true,
								description: true,
								functionalRequirements: true,
								genAIChecklist: true,
								generalInformation: true,
								noFunctionalRequirements: true,
								inputsDataSource: true,
								pocRequirements: true,
								integratedRequirements: true,
								effortAndCostEstimation: true,
							},
						},
					},
				},
			},
		});

		this.useCases = ((data.useCase?.useCase?.fetch || []) as unknown as UseCase[]).map((d) => {
			if (d.opportunities.length) {
				d.opportunities = d.opportunities.map((o) => {
					if (!o.poc) {
						o.poc = {
							id: uuid(),
							title: o.name,
							description: o.description,
							generalInformation: {
								typeImpact: '',
								complexity: '',
								opportunitySource: 'Low',
								source: {
									impact: 0,
									confidence: 0,
									ease: 0,
									'ice-score': 0,
									'ui-ux': 0,
									model: 0,
									integrations: 0,
									dataSources: 0,
								},
							},
							functionalRequirements: [] as Requirements[],
							noFunctionalRequirements: [] as Requirements[],
							pocRequirements: [] as Requirements[],
							integratedRequirements: [] as Requirements[],
							inputsDataSource: [] as InputsDataSource[],
							genAIChecklist: {},
							deploymentHandshake: {},
							effortAndCostEstimation: {},
							approved: false
						};
					}
					return o;
				});
			}
			return d;
		});

		console.log(this.useCases);
		this.processKnowledgeData();

		this.transformToTreeNodes(this.useCases);
	}

	transformToTreeNodes(useCases: UseCase[]) {
		const data = [...this.data];

		useCases.forEach((useCase) => {
			console.log('usecase', useCase);
			const useCaseNode: TreeNode = {
				key: useCase.id,
				label: useCase.name,
				expanded: true,
				data: { level1: true, name: useCase.name, useCaseId: useCase.id, diagramId: useCase.diagram?.id || '' },
				children: useCase.opportunities.map((opportunity) => ({
					key: opportunity.id,
					label: opportunity.name,
					data: {
						level2: true,
						useCaseId: useCase.id,
						opportunityId: opportunity.id,
						pocId: opportunity.poc?.id || '',
						status: opportunity.poc?.generalInformation.status || 'not-started',
						result: opportunity.poc?.generalInformation.result || 'none',
						name: opportunity.poc?.title,
						opportunitySource: opportunity.poc?.generalInformation?.opportunitySource,
						impactType: opportunity.poc?.generalInformation?.typeImpact,
						timeToBuild: opportunity.poc?.generalInformation?.complexity,
						iceScore: opportunity.poc?.generalInformation?.source?.['ice-score'],
					},
					leaf: true,
					children: opportunity.poc
						? [
							{
								key: opportunity.poc.id,
								label: opportunity.poc.title,
								data: {
									level3: true,
									impact: opportunity.poc?.generalInformation?.source?.impact,
									confidence: opportunity.poc?.generalInformation?.source?.confidence,
									ease: opportunity.poc?.generalInformation?.source?.ease,
									uiUx: opportunity.poc?.generalInformation?.source?.['ui-ux'],
									model: opportunity.poc?.generalInformation?.source?.model,
									integrations: opportunity.poc?.generalInformation?.source?.integrations,
									dataSources: opportunity.poc?.generalInformation?.source?.dataSources,
								},
							},
						]
						: [],
				})),
			};

			this.findOrUpdateNode(data, useCaseNode);
		});

		this.data = data;
	}

	findOrUpdateNode = (nodes: TreeNode[], newNode: TreeNode) => {
		const existingNodeIndex = nodes.findIndex((node) => node.key === newNode.key);
		if (existingNodeIndex !== -1) {
			const { expanded } = nodes[existingNodeIndex];
			nodes[existingNodeIndex] = {
				...nodes[existingNodeIndex],
				...newNode,
				expanded,
				children: this.mergeChildren(nodes[existingNodeIndex].children || [], newNode.children || []),
			};
		} else {
			nodes.push(newNode);
		}
	};

	processKnowledgeData() {
		this.useCases.forEach((useCase) => {
			useCase.opportunities.forEach((opportunity) => {
				if (opportunity.data.knowledgeData && Array.isArray(opportunity.data.knowledgeData)) {
					opportunity.data.knowledgeData.forEach((knowledge) => {

						const alreadyExists = opportunity?.poc?.inputsDataSource?.some(
							(input) => input.knowledgeData === knowledge
						);
	
						if (!alreadyExists) {
							opportunity?.poc?.inputsDataSource.push({
								id: uuid(),
								knowledgeData: knowledge,
								description: '',
								integrationRequirements: '',
								format: '',
								type: '',
								freqUpdate: '',
								sourceSystems: '',
								attachments: [],
							});
						}
					});
				}
			});
		});
	}

	mergeChildren = (existingChildren: TreeNode[], newChildren: TreeNode[]) => {
		newChildren.forEach((newChild) => this.findOrUpdateNode(existingChildren, newChild));
		return existingChildren;
	};

	async savePoc(pocInput: PocInput) {
		try {
			const res = await this.apiService.putObject(pocInput, 'poc', true);
			console.log('saved', pocInput, res);

			this.useCases.forEach((useCase) => {
				useCase.opportunities.forEach((opportunity) => {
					if (opportunity.id === pocInput.aiOpportunityId) {
						opportunity.poc = pocInput;
					}
				});
			});
			this.transformToTreeNodes(this.useCases);
		} catch (e) {
			console.log(e);
		}
	}
}
