import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { v4 as uuid } from 'uuid';

/**
 * Service class for interacting with the E2E Taxonomy data.
 */
@Injectable({
	providedIn: 'root',
})
export class EventService {
	private _subjects: { [key: string]: Subject<any> | BehaviorSubject<any> } = {};
	private _subjectsSimple: string[] = [];
	private _observables: { [key: string]: Observable<any> } = {};

	setEvent<T>(key: string, initValue?: T) {
		const subject = initValue !== undefined ? new BehaviorSubject<T>(initValue) : new Subject<T>();
		this._subjects[key] = subject;
		this._observables[key] = subject.asObservable();
	}

	setEventSimple(key: string, initValue: boolean = false) {
		const subject = initValue ? new BehaviorSubject(uuid()) : new Subject();
		this._subjects[key] = subject;
		this._subjectsSimple.push(key);
		this._observables[key] = subject.asObservable();
	}

	nextValueEvent<T>(key: string, value: T) {
		if (this._subjects.hasOwnProperty(key)) {
			this._subjects[key].next(value);
		}
	}

	nextEventSimple<T>(key: string) {
		if (this._subjectsSimple.includes(key)) {
			this._subjects[key].next(uuid());
		}
	}

	getEvent<T>(key: string) {
		if (!this._subjects.hasOwnProperty(key) && !this._subjects.hasOwnProperty(key)) {
			this.setEvent<T>(key);
		}
		return this._observables[key] as Observable<T>;
	}
}
