import { Component, OnInit, ViewChild, ElementRef, Input, EmbeddedViewRef, EventEmitter, Output } from '@angular/core';
import { Counter, UseCaseSimulation } from '../../../api/simulation.api';
import { SimulationService } from '../simulation.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UseCaseService } from '../../../service/use-case.service';
import type { Api, Config } from 'datatables.net';
import { v4 as uuid } from 'uuid';
import { CountUp } from 'countup.js';
import tippy from 'tippy.js';

@Component({
	selector: 'app-simulation-dashboard-info',
	templateUrl: './dashboard-info.component.html',
	styleUrls: ['./dashboard-info.component.scss'],
	providers: [ConfirmationService, MessageService],
})
export class DashboardInfoComponent implements OnInit {
	@ViewChild('tableUseCases') tableUseCases: ElementRef<HTMLTableElement>;

	@Output() impactArrayChange = new EventEmitter<any[]>();
	@Output() navigateToPromptStudio = new EventEmitter<void>();

	private breakthrough_countUp!: CountUp;
	private transformative_countUp!: CountUp;
	private incremental_countUp!: CountUp;

	constructor(
		public simulationService: SimulationService,
		public confirmationService: ConfirmationService,
		public messageService: MessageService,
		public useCaseService: UseCaseService,
	) {}

	businessValuesCount: { [key: string]: number } = {};
	businessValuesArray: { key: string; value: number }[] = [];
	impactCount: { [key: string]: number } = {};
	impactArray: { key: string; value: number }[] = [];

	ngOnInit() {
		this.simulationService.selectedSimulationObservable.subscribe({
			next: (simulation) => {
				this.onLoad();
			},
		});

		this.simulationService.selectedSimulationStarObservable.subscribe({
			next: (simulation) => {
				this.onLoad();
			},
		});

		if (this.simulationService.loaded) {
			this.onLoad();
		}
	}

	hasValidUseCases(): boolean {
		return this.simulationService.simulationsUsesCases.some((useCase) => useCase.valid === true);
	}

	onLoad() {
		this.simulationService.simulationsUsesCases
			.filter((useCase: UseCaseSimulation) => useCase.valid)
			.forEach((useCase: UseCaseSimulation) => {
				this.simulationService.counterGenAiSubject.next(this.simulationService.counters.genAiUseCases);
				useCase.context.business_values.forEach((values: string) => {
					this.businessValuesCount[values] = (this.businessValuesCount[values] || 0) + 1;
				});

				useCase.context.impact.forEach((impact: string) => {
					this.impactCount[impact] = (this.impactCount[impact] || 0) + 1;
				});

				useCase.context.business_values.forEach((values: string) => {
					this.businessValuesCount[values] = (this.businessValuesCount[values] || 0) + 1;
				});

				useCase.context.impact.forEach((impact: string) => {
					this.impactCount[impact] = (this.impactCount[impact] || 0) + 1;
				});
			});

		this.businessValuesArray = Object.keys(this.businessValuesCount).map((key) => ({
			key,
			value: this.businessValuesCount[key],
		}));
		this.impactArray = ['Incremental', 'Transformative', 'Breakthrough'].map((key) => ({
			key,
			value: this.impactCount[key],
		}));

		this.impactArrayChange.emit(this.impactArray);
		this.loadTable();
	}

	getValues(counter: any): number[] {
		if (!counter) {
			return [0, 0, 0, 0, 0, 0, 0];
		}
		return [
			Number(counter.sales) || 0,
			Number(counter.marketing) || 0,
			Number(counter.services) || 0,
			Number(counter.procurement) || 0,
			Number(counter.finance) || 0,
			Number(counter.hr) || 0,
			Number(counter.it) || 0,
		];
	}

	getBootstrapClass(value: number): string {
		value = Number(value);

		if (value > 700) return 'heatmap-9'; // #191747
		if (value > 600) return 'heatmap-8'; // #2A2868
		if (value > 500) return 'heatmap-7'; // #3D3C87
		if (value > 400) return 'heatmap-6'; // #5152A3
		if (value > 300) return 'heatmap-5'; // #676ABC
		if (value > 200) return 'heatmap-4'; // #7E83D1
		if (value > 100) return 'heatmap-3'; // #989EE2
		if (value > 50) return 'heatmap-2'; // #B3B9EE
		if (value > 0) return 'heatmap-1'; // #D1D5F7
		return 'heatmap-0'; // #EFF1FD
	}

	getEmbeddedHeatmapClass(value: number): string {
		value = Number(value);
		if (value > 700) return 'embedded-9'; // #001F4B
		if (value > 600) return 'embedded-8'; // #00336D
		if (value > 500) return 'embedded-7'; // #00498D
		if (value > 400) return 'embedded-6'; // #0060AA
		if (value > 300) return 'embedded-5'; // #1478C3
		if (value > 200) return 'embedded-4'; // #4391D7
		if (value > 100) return 'embedded-3'; // #6AAAE7
		if (value > 50) return 'embedded-2'; // #93C3F2
		if (value > 0) return 'embedded-1'; // #BDDBFA
		return 'embedded-0'; // #E9F3FE
	}

	loadTable() {
		const renderBox = (data: any, type: any, row: any) => {
			let label = '';
			let className = '';
			if (data === 0) {
				label = '---';
				className = 'background-none';
			} else if (data < 4) {
				label = 'Low';
				className = 'background-low';
			} else if (data >= 4 && data <= 7) {
				label = 'Medium';
				className = 'background-medium';
			} else {
				label = 'High';
				className = 'background-high';
			}
			return `<p class="${className}"> ${label} </p>`;
		};

		//console.log(this.simulationService.simulationsUsesCases);
		const orderToApply = this.currentOrder.length ? this.currentOrder : [];
		this.renderTable(
			() => this.tableUseCases,
			() => ({
				order: orderToApply,
				columns: [
					{
						title: 'Use Case',
						className: 'bold-column',
						data: 'name',
					},
					{
						title: 'Impact',
						data: 'benefits',
					},
					{
						title: 'Revenue Growth',
						data: 'revenue_growth',
						render: renderBox,
					},
					{
						title: 'Customer Experience',
						data: 'customer_experience',
						render: renderBox,
					},
					{
						title: 'Process Productivity',
						data: 'process_productivity',
						render: renderBox,
					},
					{
						title: 'Employee Productivity',
						data: 'employee_productivity',
						render: renderBox,
					},
					{
						title: 'Cost Savings',
						data: 'cost_savings',
						render: renderBox,
					},
					{
						title: 'Actions',
						className: 'text-center',
						render: (data: any, type: any, row: any) => {
							return `
								<div class="flex justify-content-center align-items-center">
									<button class="p-ripple p-element p-button-link p-button p-component p-button-icon-only open-button" type="button">
										<span aria-hidden="true" class="pi pi-window-maximize p-button-icon ng-star-inserted"></span>
										<span class="p-ink"></span>
									</button>
									<button class="p-ripple p-element p-button-link p-button p-component p-button-icon-only delete-button" type="button">
										<span aria-hidden="true" class="pi pi-times-circle p-button-icon ng-star-inserted"></span>
										<span class="p-ink"></span>
									</button>
									<i class="pi ${row.valid ? 'pi-star-fill' : 'pi-star'} star-icon ${
								row.type === '2' ? 'text-primary' : 'text-green-300'
							}" data-id="${row.id}" style="cursor: pointer; font-size: 1.5rem; margin-left: 10px;"></i>
								</div>
							`;
						},
					},
				],
				select: true,
				paging: false,
				info: false,
			}),
			() => {
				return this.simulationService.simulationsUsesCases
					.filter((d) => d.valid)
					.map((d) => {
						return {
							id: d.id as string,
							name: d.name,
							type: d.type,
							valid: d.valid,
							benefits: d.context.impact.join(', '),
							revenue_growth: d.data?.valuesDriversScores?.revenue_growth || 0,
							customer_experience: d.data?.valuesDriversScores?.customer_experience || 0,
							process_productivity: d.data?.valuesDriversScores?.process_productivity || 0,
							employee_productivity: d.data?.valuesDriversScores?.employee_productivity || 0,
							impact_assessment: Array.isArray(d.context?.impact) ? d.context.impact.join(',') : '',
							cost_savings: d.data?.valuesDriversScores?.cost_savings || 0,
						};
					});
			},
			(data: { id: string }[]) => {
				console.log(this.simulationService.simulationsUsesCases);

				const ids = data.map((d) => d.id);
				this.simulationService.simulationsUsesCases = this.simulationService.simulationsUsesCases.filter((d) =>
					ids.includes(d.id as string),
				);
			},
		);
		this.restoreOrder();
	}

	public currentOrder: any[] = [];

	storeCurrentOrder() {
		if (this.dTable) {
			this.currentOrder = this.dTable.order();
		}
	}

	restoreOrder() {
		if (this.dTable && this.currentOrder.length) {
			this.dTable.order(this.currentOrder).draw(false);
		}
	}

	renderTable(
		element: () => ElementRef<HTMLTableElement>,
		getOptions: () => Config,
		getData: () => {
			id: string;
		}[],
		updateData: (
			data: {
				id: string;
			}[],
		) => void,
	) {
		setTimeout(() => {
			const data = getData();
			if (data && data.length) {
				const el = element();
				if (el && el.nativeElement) {
					this._renderTable(el.nativeElement, () => ({
						...getOptions(),
						language: {
							search: '',
							searchPlaceholder: 'Search',
						},
					}), getData, updateData);
				} else {
					this.renderTable(element, () => ({
						...getOptions(),
						language: {
							search: '',
							searchPlaceholder: 'Search',
						},
					}), getData, updateData);
				}
			}
		});
	}

	dTable: Api | undefined = undefined;
	displayModal: boolean = false;
	dtSelection: number = 0;

	currentUseCase: UseCaseSimulation | undefined = undefined;

	onRunSimulationClick(): void {
		this.navigateToPromptStudio.emit();
	}

	_renderTable(
		table: HTMLTableElement,
		getOptions: () => Config,
		getData: () => {
			id: string;
		}[],
		updateData: (
			data: {
				id: string;
			}[],
		) => void,
	) {
		const data = getData();
		if (data.length) {
			if (this.dTable) {
				this.dTable.destroy();
			}
			const options = getOptions();
			const dt = new DataTable(table, {
				data,
				...options,
				initComplete: () => {
					if (
						table.parentElement &&
						table.parentElement.parentElement &&
						table.parentElement.parentElement.parentElement
					) {
						table.parentElement.parentElement.parentElement.classList.add('table-recommended-use-cases');
					}
				},
			});

			this.dTable = dt;

			dt.select().on('select', (a: any, dataSelect: string[]) => {
				this.dtSelection = dt.rows({ selected: true }).data().length;
			});

			dt.select().on('deselect', (a: any, dataSelect: string[]) => {
				this.dtSelection = dt.rows({ selected: true }).data().length;
			});

			table.querySelectorAll('.open-button').forEach((el) => {
				el.addEventListener('click', () => {
					const rowData: { id: string } = dt.row(el.closest('tr')).data();
					this.currentUseCase = undefined;
					setTimeout(() => {
						this.currentUseCase = this.simulationService.simulationsUsesCases.find(
							(d: UseCaseSimulation) => d.id === rowData.id,
						);
						if (this.currentUseCase) {
							this.displayModal = true;
						}
					}, 50);
				});
			});

			table.querySelectorAll('.delete-button').forEach((el) => {
				el.addEventListener('click', () => {
					this.storeCurrentOrder();
					const row = dt.row(el.closest('tr'));
					const rowData: { id: string } = row.data();
					const useCase = this.simulationService.simulationsUsesCases.find(
						(d: UseCaseSimulation) => d.id === rowData.id,
					);
					if (useCase) {
						this.confirmationService.confirm({
							header: 'Delete the use case?',
							message: 'This will delete the use case from the simulations.',
							icon: 'pi pi-exclamation-triangle',
							accept: () => {
								this.deleteUseCase(useCase).then(() => {
									row.remove().draw();
									const data = getData().filter((d) => d.id !== useCase.id);
									updateData(data);
									this.updateImpactArray();
								});
							},
						});
					}
				});
				table.querySelectorAll('.open-button').forEach((el) => {
					tippy(el, {
						content: 'See details',
						appendTo: 'parent',
						arrow: false,
						offset: [0, -5],
						placement: 'top',
						zIndex: 100,
						duration: 10,
					});
				});

				table.querySelectorAll('.delete-button').forEach((el) => {
					tippy(el, {
						content: 'Remove',
						appendTo: 'parent',
						arrow: false,
						offset: [0, -5],
						placement: 'top',
						zIndex: 100,
						duration: 10,
					});
				});
			});

			table.querySelectorAll('.star-icon').forEach((el) => {
				el.addEventListener('click', () => {
					this.confirmationService.confirm({
						header: 'Remove the Use Case from the list?',
						message:
							'This will not delete the Use Case, it will only remove it from this list, but it will still be visible in its simulation.',
						icon: 'pi pi-exclamation-triangle',
						acceptLabel: 'Continue',
						acceptIcon: 'pi pi-check',
						accept: () => {
							this.storeCurrentOrder();
							const row = dt.row(el.parentElement?.parentElement);
							if (row && row.data()) {
								const useCaseData: UseCaseSimulation = row.data();
								this.simulationService
									.getUseCaseSimulation(useCaseData.id as string)
									.then((useCase) => {
										if (useCase) {
											useCase.valid = !useCase.valid;
											this.simulationService.updateUseCaseValidSubject.next(useCase.id as string);
											this.simulationService.saveUseCase(useCase).then(() => {
												this.simulationService.simulationsUsesCases =
													this.simulationService.simulationsUsesCases.map((d) => {
														return useCase.id === d.id ? useCase : d;
													});
												this.loadTable();
												this.updateImpactArray();
												if (this.breakthrough_countUp) {
													this.breakthrough_countUp.update(
														parseInt(
															this.simulationService.counters.breakthrough
																.toString()
																.replace(/,/g, ''),
														),
													);
												}
												if (this.incremental_countUp) {
													this.incremental_countUp.update(
														parseInt(
															this.simulationService.counters.incremental
																.toString()
																.replace(/,/g, ''),
														),
													);
												}
												if (this.transformative_countUp) {
													this.transformative_countUp.update(
														parseInt(
															this.simulationService.counters.transformative
																.toString()
																.replace(/,/g, ''),
														),
													);
												}
												// this.simulationService.counterGenAiSubject.next(this.simulationService.counters.genAiUseCases);
												// console.log('HOME - update Star')
											});
										}
									});
							}
						},
						rejectLabel: 'Cancel',
					});
				});
			});

			table.querySelectorAll('.star-icon.text-primary').forEach((el) => {
				tippy(el, {
					content: 'Hackett Taxonomy',
					appendTo: 'parent',
					arrow: false,
					offset: [0, -5],
					placement: 'top',
					zIndex: 100,
					duration: 10,
				});
			});

			table.querySelectorAll('.star-icon.text-green-300').forEach((el) => {
				tippy(el, {
					content: 'Hackett Industry',
					appendTo: 'parent',
					arrow: false,
					offset: [0, -5],
					placement: 'top',
					zIndex: 100,
					duration: 10,
				});
			});
		}
	}

	updateImpactArray() {
		this.impactCount = {};
		this.simulationService.simulationsUsesCases
			.filter((uc) => uc.valid)
			.forEach((uc: UseCaseSimulation) => {
				uc.context.impact.forEach((impact: string) => {
					this.impactCount[impact] = (this.impactCount[impact] || 0) + 1;
				});
			});

		this.impactArray = ['Incremental', 'Transformative', 'Breakthrough'].map((key) => ({
			key,
			value: this.impactCount[key],
		}));

		this.impactArrayChange.emit(this.impactArray);
	}

	async deleteUseCase(useCase: UseCaseSimulation) {
		await this.simulationService.deleteUseCase(useCase);
		this.simulationService.simulationsUsesCases = this.simulationService.simulationsUsesCases.filter(
			(u) => u.id !== useCase.id,
		);
		this.updateImpactArray();
		this.dtSelection = 0;
		this.simulationService.updateSimulationsSubject.next(uuid());
	}

	async deleteUseCases() {
		if (this.dTable) {
			const rows = this.dTable.rows({ selected: true });
			if (rows.length && rows.data().length) {
				this.confirmationService.confirm({
					header: `Delete the ${this.dtSelection} use cases?`,
					message: 'This will delete the use cases from the simulations.',
					icon: 'pi pi-exclamation-triangle',
					accept: () => {
						const ids: string[] = [];
						const updates = [...Array(rows.data().length)]
							.map((_, i) => {
								const rowData: UseCaseSimulation = rows.data()[i];
								ids.push(rowData.id as string);
								return this.simulationService.simulationsUsesCases.find(
									(d: UseCaseSimulation) => d.id === rowData.id,
								);
							})
							.filter((d) => !!d)
							.map((d) => this.simulationService.deleteUseCase(d as UseCaseSimulation));

						Promise.all(updates).then(() => {
							rows.remove().draw();
							this.simulationService.simulationsUsesCases =
								this.simulationService.simulationsUsesCases.filter(
									(d) => !ids.includes(d.id as string),
								);
							this.updateImpactArray();
							this.dtSelection = 0;
							this.simulationService.updateSimulationsSubject.next(uuid());
						});
					},
				});
			}
		}
	}
}
