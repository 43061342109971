import { Component, HostListener } from '@angular/core';

@Component({
	selector: 'app-zbrain-icon',
	template: `
		<ng-template [ngIf]="!isHovered">
			<svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g id="icon/zbrain">
					<g id="Group 152">
						<path
							id="Vector"
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M7.11816 14.1491C7.66667 15.2435 8.84343 16.0001 10.2066 16.0001H20.459V7.45966C20.459 5.67057 18.929 4.22021 17.0416 4.22021H15.6425V9.41614C15.6425 12.03 13.556 14.1491 10.9823 14.1491H7.11816Z"
							fill="#CECFD3"
						/>
						<path
							id="Vector_2"
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M0.729492 0H10.9819C12.8693 0 14.3994 1.55396 14.3994 3.47086V9.15043C14.3994 11.0674 12.8693 12.6213 10.9819 12.6213H4.14695C2.25954 12.6213 0.729492 11.0674 0.729492 9.15043V0Z"
							fill="#82858E"
						/>
					</g>
				</g>
			</svg>
		</ng-template>
		<ng-template [ngIf]="isHovered">
			<svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g id="icon/zbrain-hover">
					<g id="Group 152">
						<path
							id="Vector"
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M6.38867 14.1491C6.93718 15.2435 8.11394 16.0001 9.47714 16.0001H19.7295V7.45966C19.7295 5.67057 18.1995 4.22021 16.3121 4.22021H14.913V9.41614C14.913 12.03 12.8265 14.1491 10.2528 14.1491H6.38867Z"
							fill="#ACE2F4"
						/>
						<path
							id="Vector_2"
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M0 0H10.2524C12.1398 0 13.6699 1.55396 13.6699 3.47086V9.15043C13.6699 11.0674 12.1398 12.6213 10.2524 12.6213H3.41746C1.53005 12.6213 0 11.0674 0 9.15043V0Z"
							fill="#5E66D6"
						/>
					</g>
				</g>
			</svg>
		</ng-template>
	`,
	styles: [],
})
export class ZBrainIconComponent {
	isHovered: boolean = false;

	@HostListener('mouseenter') onMouseEnter() {
		this.isHovered = true;
	}

	@HostListener('mouseleave') onMouseLeave() {
		this.isHovered = false;
	}
}
