import { gql } from 'apollo-angular';
import { ICommonData } from '../../api/common';
import {
	ICategoryFunction,
	IDiagramE2E,
	IDiagramProcess,
	IDiagramTemplate,
	IDigitalWorker,
	IFunction,
	ILevel1,
	ILevel2,
	ITechnology,
} from '../../api/e2e-taxonomy.api';
import {
	IAiLever,
	ICategoryQuestion,
	IFlow,
	IFunctionFilter,
	IIcon,
	IQuestion,
	ISkill,
	ISolution,
	ISubCategoryQuestion,
	ITool,
} from '../../api/solution.api';
import { IDiagram, IUseCase } from '../../api/use-case.api';
import {
	IAssessmentMaturity,
	ICategoryQuestionMaturity,
	IQuestionMaturity,
	ISubCategoryQuestionMaturity,
} from '../../api/maturity.api';
import {
	IEnterpriseContextModel,
	IM1Prep,
	ISimulation,
	IUseCaseLibrary,
	IUseCaseSimulation,
} from '../../api/simulation.api';

export interface TypeQueryData {
	common: {
		commonData: {
			all: ICommonData<any>[];
		};
	};
	e2eTaxonomy: {
		categoryFunction: {
			all: ICategoryFunction[];
		};
		diagramE2e: {
			all: IDiagramE2E[];
		};
		diagramProcess: {
			all: IDiagramProcess[];
		};
		diagramTemplate: {
			all: IDiagramTemplate[];
		};
		digitalWorker: {
			all: IDigitalWorker[];
		};
		function: {
			all: IFunction[];
		};
		level1: {
			all: ILevel1[];
		};
		level2: {
			all: ILevel2[];
		};
		technology: {
			all: ITechnology[];
		};
	};
	solution: {
		aiLever: {
			all: IAiLever[];
		};
		categoryQuestion: {
			all: ICategoryQuestion[];
		};
		subCategoryQuestion: {
			all: ISubCategoryQuestion[];
		};
		functionFilter: {
			all: IFunctionFilter[];
		};
		question: {
			all: IQuestion[];
		};
		skill: {
			all: ISkill[];
		};
		tool: {
			all: ITool[];
		};
		solution: {
			all: ISolution[];
		};
		flow: {
			all: IFlow[];
		};
		icon: {
			all: IIcon[];
		};
	};
	useCase: {
		diagram: {
			all: IDiagram[];
		};
		useCase: {
			all: IUseCase[];
		};
	};
	maturity: {
		assessment: {
			all: IAssessmentMaturity[];
		};
		categoryQuestion: {
			all: ICategoryQuestionMaturity[];
		};
		question: {
			all: IQuestionMaturity[];
		};
		subCategoryQuestion: {
			all: ISubCategoryQuestionMaturity[];
		};
	};
	simulation: {
		context: {
			all: IEnterpriseContextModel[];
		};
		simulation: {
			all: ISimulation[];
		};
		useCaseSimulation: {
			all: IUseCaseSimulation[];
		};
		useCaseLibrary: {
			all: IUseCaseLibrary[];
		};
		m1Prep: {
			all: IM1Prep[];
		};
	};
}

export const queryData1 = gql`
	query QueryData {
		common {
			commonData {
				all {
					data
					key
				}
			}
		}
		e2eTaxonomy {
			categoryFunction {
				all {
					createdAt
					deletedAt
					id
					name
					short
					sort
					type
					office
					updatedAt
				}
			}
			function {
				all {
					categoryId
					createdAt
					deletedAt
					id
					name
					description
					short
					sort
					overall
					type
					updatedAt
				}
			}
			level1 {
				all {
					createdAt
					deletedAt
					functionId
					id
					name
					description
					updatedAt
					type
				}
			}
			level2 {
				all {
					createdAt
					deletedAt
					description
					id
					level1Id
					name
					updatedAt
					values
					type
				}
			}
			technology {
				all {
					createdAt
					deletedAt
					id
					name
					neto
					short
					svg
					updatedAt
				}
			}
		}
	}
`;

export const queryData2 = gql`
	query QueryData {
		e2eTaxonomy {
			diagramE2e {
				all {
					xmlImage
					createdAt
					data
					deletedAt
					id
					functionId
					updatedAt
					xmlData
				}
			}
		}
	}
`;
export const queryData3 = gql`
	query QueryData {
		e2eTaxonomy {
			diagramProcess {
				all {
					xmlImage
					createdAt
					data
					deletedAt
					id
					level1Id
					updatedAt
					xmlData
				}
			}
		}
	}
`;
export const queryData4 = gql`
	query QueryData {
		e2eTaxonomy {
			diagramTemplate {
				all {
					xmlImage
					createdAt
					data
					deletedAt
					id
					level2Id
					updatedAt
					xmlData
				}
			}
		}
	}
`;

export const queryData5 = gql`
	query QueryData($organizationId: String!) {
		useCase {
			diagram {
				all(organizationId: $organizationId) {
					xmlImage
					createdAt
					data
					deletedAt
					id
					updatedAt
					useCaseId
					xmlData
					organizationId
				}
			}
			useCase {
				all(organizationId: $organizationId) {
					createdAt
					deletedAt
					description
					generalData
					id
					levels2Ids
					name
					technologiesIds
					updatedAt
					workPlanData
					formData
					benefitsValues
					benefitsOrder
					organizationId
					aiValues
					hubble
					benefits
					nexus
					libraryData
					simulationData
				}
			}
		}
		maturity {
			assessment {
				all(organizationId: $organizationId) {
					content
					createdAt
					deletedAt
					formData
					commentData
					recommendations
					id
					organizationId
					updatedAt
				}
			}
			categoryQuestion {
				all(organizationId: $organizationId) {
					createdAt
					deletedAt
					id
					name
					sort
					updatedAt
				}
			}
			question {
				all(organizationId: $organizationId) {
					categoryId
					createdAt
					deletedAt
					id
					sort
					subcategoryId
					text
					updatedAt
				}
			}
			subCategoryQuestion {
				all(organizationId: $organizationId) {
					categoryId
					createdAt
					deletedAt
					id
					name
					sort
					updatedAt
				}
			}
		}
	}
`;

export const queryData6 = gql`
	query QueryData {
		solution {
			categoryQuestion {
				all {
					createdAt
					updatedAt
					deletedAt
					id
					sort
					name
				}
			}
			subCategoryQuestion {
				all {
					createdAt
					updatedAt
					deletedAt
					id
					sort
					name
					categoryId
				}
			}
			question {
				all {
					answers
					categoryId
					subcategoryId
					createdAt
					deletedAt
					id
					tag
					technologyId
					text
					updatedAt
					weight
					weightJson
					sort
				}
			}
		}
	}
`;

export const queryData7 = gql`
	query QueryData($organizationId: String!) {
		simulation {
			context {
				all(organizationId: $organizationId) {
					enterpriseContext
					id
					organizationId
					updatedAt
					deletedAt
					createdAt
				}
			}
			m1Prep {
				all(organizationId: $organizationId) {
					companyAnalysis
					competitiveLandscape
					conversation
					products
					context
					salesforce
					organizationId
					id
					createdAt
					updatedAt
				}
			}
			simulation {
				all(organizationId: $organizationId) {
					createdAt
					deletedAt
					description
					notes
					search
					generate
					enterpriseContext
					id
					name
					simulationContext
					taxonomyId
					taxonomyRel
					organizationId
					updatedAt
				}
			}
			useCaseSimulation {
				all(organizationId: $organizationId) {
					context
					createdAt
					deletedAt
					description
					id
					keyFeatures
					name
					simulationId
					deployedId
					organizationId
					updatedAt
					data
					valid
					type
				}
			}
		}
	}
`;

export const queryData8 = gql`
	query QueryData {
		simulation {
			useCaseLibrary {
				all {
					data
					createdAt
					deletedAt
					description
					id
					name
					sourceId
					sourceRel
					updatedAt
				}
			}
		}
	}
`;
