import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AIOpportunity, PocInput } from '../../types';
import { PdfService } from '../../../../service/pdf.service';
import { delay } from '../../../../utils/delay';
import { environment } from '../../../../../../environments/environment';
import { MessageService } from 'primeng/api';

@Component({
	selector: 'app-design-nexus-deployment-handshake',
	templateUrl: './design-nexus-deployment-handshake.component.html',
	styleUrls: ['./design-nexus-deployment-handshake.component.scss'],
	providers: [MessageService],
})
export class DesignNexusDeploymentHandshakeComponent implements OnInit {
	@Input() opportunity: AIOpportunity;
	@Input() pocInput: PocInput;

	pdfBase64String: string | undefined;
	isPdfBase64Loading: boolean = false;
	isSavingDocument: boolean = false;
	isPdfViewerReady: boolean = false;

	constructor(
		private pdf: PdfService,
		private messageService: MessageService,
		private http: HttpClient
	) {}

	ngOnInit(): void {
        // Set PDF viewer ready if a file URL is already available
        if (this.pocInput.deploymentHandshake?.fileUrl) {
            this.isPdfViewerReady = true;
        }
    }

	getDocxTemplateParams(): (string | any)[] {
		const {
			description: useCaseDescription = '',
			data: { assumptionsPocValidation = '', knowledgeData = '' } = {},
		} = this.opportunity;

		const {
			title: pocName = '',
			description: pocDescription = '',
			inputsDataSource = '',
			functionalRequirements = '',
			noFunctionalRequirements = '',
			pocRequirements = '',
			integratedRequirements = '',
		} = this.pocInput;

		return [
			'/assets/docx/Scope of POC_Customer Profile Adherence_LWH reviewed EDIT.docx',
			{
				useCaseDescription,
				pocName,
				pocDescription,
				inputsDataSource,
				functionalRequirements,
				noFunctionalRequirements,
				pocRequirements,
				integratedRequirements,
				assumptionsPocValidation,
				knowledgeData,
			},
		];
	}

	async generatePdfFromTemplate() {
		try {
			this.isPdfBase64Loading = true;
			this.isPdfViewerReady = false; // Reset PDF viewer readiness
			const [docxTemplatePath, templateData] = this.getDocxTemplateParams();
	
			const pdfBase64 = await this.pdf.generatePdfBased64(docxTemplatePath, templateData);
	
			this.pdfBase64String = `data:application/pdf;base64,${pdfBase64}`;
			this.isPdfViewerReady = true; // Enable PDF viewer rendering
			this.saveGeneratedDocument();
		} catch (e) {
			console.error('Error generating PDF:', e);
			this.pdfBase64String = undefined;
			this.isPdfViewerReady = false;
		} finally {
			this.isPdfBase64Loading = false;
		}
	}
	
	onPdfViewerLoad() {
		console.log('PDF Viewer loaded successfully');
		this.isPdfViewerReady = true; // Confirm viewer is ready
	}
	

	async downloadWordFromTemplate() {
		try {
			this.isPdfBase64Loading = true;
			const [docxTemplatePath, templateData] = this.getDocxTemplateParams();

			await this.pdf.downLoadWord(docxTemplatePath, templateData);
			await delay(1500);
		} catch (e) {
			console.error('Error downloading Word:', e);
		} finally {
			this.isPdfBase64Loading = false;
		}
	}

	async saveGeneratedDocument() {
		if (!this.pdfBase64String) {
			this.messageService.add({
				severity: 'error',
				summary: 'Error',
				detail: 'No document to save. Please generate a document first.',
			});
			return;
		}

		try {
			this.isSavingDocument = true;

			// Creating Blob from the generated document
			const fileName = `PoC_Scope_of_Work.pdf`; // File name
			const fileBlob = this.convertBase64ToBlob(this.pdfBase64String, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
			
			// Preparing FormData
			const formData = new FormData();
			formData.append('upload', fileBlob, fileName);
			formData.append('name', 'Deployment Handshake Document');
			formData.append('description', 'Generated document for deployment handshake');

			// Sending the file to the endpoint
			this.http.post(`${environment.url}/api/nexus/files`, formData).subscribe({
				next: (response: any) => {
					console.log('File uploaded successfully:', response);
					this.pocInput.deploymentHandshake = {
						...(this.pocInput.deploymentHandshake || {}), // Preserve existing data
						fileUrl: response.files[0] || '', // Assuming the response contains the URL in files[0]
					};

					this.messageService.add({
						severity: 'success',
						summary: 'Success',
						detail: 'Document uploaded successfully.',
					});
				},
				error: (error) => {
					console.error('Error uploading file:', error);

					this.messageService.add({
						severity: 'error',
						summary: 'Error',
						detail: 'Failed to upload document. Please try again.',
					});
				},
				complete: () => {
					this.isSavingDocument = false;
				},
			});
		} catch (error) {
			console.error('Error in saveGeneratedDocument:', error);
			this.isSavingDocument = false;
		}
	}

	// Function to convert Base64 to Blob
	private convertBase64ToBlob(base64: string, mimeType: string): Blob {
		const byteString = atob(base64.split(',')[1]); // Decodes Base64
		const ab = new ArrayBuffer(byteString.length);
		const ia = new Uint8Array(ab);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ab], { type: mimeType });
	}
}
