<p-toast></p-toast>

<div
	*ngIf="service.useCaseService.currentUseCase && service.useCaseService.currentUseCase.calculatedData"
	class="mt-6 px-2"
>
	<div class="card-content-result">
		<div class="grid w-full pl-2">
			<div class="col-12 md:col-4 lg:col-4 xl:col-4">
				<div class="flex flex-column flex-wrap gap-5 justify-content-start h-full">
					<div class="card card-box p-0">
						<div
							class="flex flex-column justify-content-between px-2 py-3 align-items-center overall gap-2"
						>
							<p class="m-0 text-overall-title text-center">AI Overall Transformation Impact</p>
							<div class="flex justify-content-between w-full">
								<div
									class="flex flex-column align-content-center justify-content-center text-center w-full"
								>
									<p-dropdown
										styleClass="overall-dropdown"
										[options]="impactOptions"
										[(ngModel)]="service.useCaseService.currentUseCase.generalData.impact"
										(onChange)="service.updateUseCase()"
									></p-dropdown>
									<span class="text-overall-helper px-2"
										>Reimagine possibilities, create new value, achieve 2x or greater exponential
										benefits.</span
									>
								</div>
							</div>
						</div>
					</div>
					<div class="card card-box p-0 flex-grow-1">
						<div
							class="flex flex-column justify-content-between px-2 py-4 align-items-center overall gap-3"
						>
							<div class="flex justify-content-start align-items-center w-full gap-3">
								<span class="text-overall-title">Use Case Description</span>
							</div>

							<div
								class="text-sm line-height-2 text-justify"
								[innerHTML]="service.useCaseService.currentUseCase.description"
							></div>
						</div>
					</div>
					<div class="card card-box p-0 flex-grow-1">
						<div
							class="flex flex-column justify-content-between px-2 py-4 align-items-center overall gap-3"
						>
							<div class="flex justify-content-start align-items-center w-full gap-3">
								<span class="text-overall-title">Prioritization Matrix</span>
							</div>

							<div class="flex flex-wrap px-3 w-full">
								<div class="col p-0">
									<div class="flex flex-column gap-0">
										<div
											class="header-matrix-1 flex justify-content-center align-items-center h-3rem font-bold"
										>
											<p>Feasibility</p>
										</div>
										<div class="flex justify-content-center align-items-center h-5rem">
											<div
												class="p-3 text-center background-heatmap uppercase w-7rem h-3rem"
												[ngClass]="
													service.useCaseService.currentUseCase.calculatedData.matrixReadiness
														.class_value
												"
											>
												{{
													service.useCaseService.currentUseCase.calculatedData.matrixReadiness
														.label_value
												}}
											</div>
										</div>
									</div>
								</div>
								<div class="col p-0">
									<div class="flex flex-column gap-0">
										<div
											class="header-matrix-2 flex justify-content-center align-items-center h-3rem font-bold"
										>
											<p>Benefits</p>
										</div>
										<div class="flex justify-content-center align-items-center h-5rem">
											<div
												class="p-3 text-center background-heatmap w-7rem h-3rem"
												[ngClass]="
													service.useCaseService.currentUseCase.calculatedData.matrixBenefits
														.class_value
												"
											>
												{{
													service.useCaseService.currentUseCase.calculatedData.matrixBenefits
														.label_value
												}}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12 md:col-5 lg:col-5 xl:col-5">
				<div class="flex flex-column flex-wrap gap-5 justify-content-start h-full">
					<div id="business-drivers" class="card card-box py-1 h-full">
						<p class="mx-0 my-2 text-overall-title">Data Value Assessment</p>

						<div class="inner-box my-8">
							<div class="p-0">
								<div class="justify-content-center align-content-center">
									<p class="mb-0 text-center">Data Quality Feasibility</p>
									<div class="chart-result-data">
										<p-chart
											type="doughnut"
											[id]="'chart-' + service.dataChartsResult[0].label.toLowerCase()"
											[data]="service.dataChartsResult[0].data"
											[options]="service.dataChartsResult[0].options"
										></p-chart>
										<p *ngIf="service.dataChartsResult[0].value" class="percent">
											{{ service.dataChartsResult[0].value }}%
										</p>
										<p-skeleton
											*ngIf="!service.dataChartsResult[0].value"
											styleClass="percent-loading"
										></p-skeleton>
									</div>
								</div>
							</div>
						</div>

						<div class="grid">
							<div class="col-8 flex justify-content-start align-items-center py-75 gap-3">
								<svg
									width="18"
									height="18"
									viewBox="0 0 18 18"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M5.44727 0C5.83968 0 6.15779 0.318114 6.15779 0.710526V1.89474H10.4209V0.710526C10.4209 0.318114 10.7391 0 11.1315 0C11.5239 0 11.842 0.318114 11.842 0.710526V1.89474H13.0262C14.4651 1.89474 15.6315 3.06115 15.6315 4.5V7.81579C15.6315 8.2082 15.3134 8.52632 14.9209 8.52632H2.36832V14.9211C2.36832 15.5751 2.89851 16.1053 3.55253 16.1053H7.342C7.73442 16.1053 8.05253 16.4234 8.05253 16.8158C8.05253 17.2082 7.73442 17.5263 7.342 17.5263H3.55253C2.11368 17.5263 0.947266 16.3599 0.947266 14.9211V4.5C0.947266 3.06115 2.11368 1.89474 3.55253 1.89474H4.73674V0.710526C4.73674 0.318114 5.05485 0 5.44727 0ZM10.4209 3.31579V4.5C10.4209 4.89241 10.7391 5.21053 11.1315 5.21053C11.5239 5.21053 11.842 4.89241 11.842 4.5V3.31579H13.0262C13.6802 3.31579 14.2104 3.84598 14.2104 4.5V7.10526H2.36832V4.5C2.36832 3.84598 2.89851 3.31579 3.55253 3.31579H4.73674V4.5C4.73674 4.89241 5.05485 5.21053 5.44727 5.21053C5.83968 5.21053 6.15779 4.89241 6.15779 4.5V3.31579H10.4209ZM12.7894 10.8947C11.2197 10.8947 9.94727 12.1672 9.94727 13.7368C9.94727 15.3065 11.2197 16.5789 12.7894 16.5789C14.359 16.5789 15.6315 15.3065 15.6315 13.7368C15.6315 12.1672 14.359 10.8947 12.7894 10.8947ZM8.52621 13.7368C8.52621 11.3824 10.4349 9.47368 12.7894 9.47368C15.1438 9.47368 17.0525 11.3824 17.0525 13.7368C17.0525 16.0913 15.1438 18 12.7894 18C10.4349 18 8.52621 16.0913 8.52621 13.7368ZM13.2631 12.5526C13.2631 12.1602 12.9449 11.8421 12.5525 11.8421C12.1601 11.8421 11.842 12.1602 11.842 12.5526V14.4474C11.842 14.8398 12.1601 15.1579 12.5525 15.1579H13.4999C13.8923 15.1579 14.2104 14.8398 14.2104 14.4474C14.2104 14.055 13.8923 13.7368 13.4999 13.7368H13.2631V12.5526Z"
										fill="#585B62"
									/>
								</svg>
								<span>Availability</span>
							</div>
							<div class="col-4 py-75">
								<div
									class="p-2 text-center background-heatmap uppercase"
									[ngClass]="
										service.useCaseService.useCaseGetPercentBackground(service.dataValues[0])
									"
								>
									{{ service.useCaseService.useCaseGetPercentLabel(service.dataValues[0]) }}
								</div>
							</div>
						</div>
						<div class="grid">
							<div class="col-8 flex justify-content-start align-items-center py-75 gap-3">
								<svg
									width="18"
									height="18"
									viewBox="0 0 18 18"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M16.3333 7C16.7015 7 17 6.70152 17 6.33333V1.5C17 1.22386 16.7761 1 16.5 1H11.6667C11.2985 1 11 1.29848 11 1.66667C11 2.03486 11.2985 2.33333 11.6667 2.33333H14.724L9 8.05733L3.276 2.33333H6.33333C6.70152 2.33333 7 2.03486 7 1.66667C7 1.29848 6.70152 1 6.33333 1H1.5C1.22386 1 1 1.22386 1 1.5V6.33333C1 6.70152 1.29848 7 1.66667 7C2.03486 7 2.33333 6.70152 2.33333 6.33333V3.276L8.33333 9.276V15.6667H1.66667C1.29848 15.6667 1 15.9651 1 16.3333C1 16.7015 1.29848 17 1.66667 17H16.3333C16.7015 17 17 16.7015 17 16.3333C17 15.9651 16.7015 15.6667 16.3333 15.6667H9.66667V9.276L15.6667 3.276V6.33333C15.6667 6.70152 15.9651 7 16.3333 7Z"
										fill="#585B62"
									/>
								</svg>
								<span>Decision-Making Impact</span>
							</div>
							<div class="col-4 py-75">
								<div
									class="p-2 text-center background-heatmap uppercase"
									[ngClass]="
										service.useCaseService.useCaseGetPercentBackground(service.dataValues[1])
									"
								>
									{{ service.useCaseService.useCaseGetPercentLabel(service.dataValues[1]) }}
								</div>
							</div>
						</div>
						<div class="grid">
							<div class="col-8 flex justify-content-start align-items-center py-75 gap-3">
								<svg
									width="18"
									height="18"
									viewBox="0 0 18 18"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M9 15.1875C9 14.8768 9.25184 14.625 9.5625 14.625H16.875C17.1857 14.625 17.4375 14.8768 17.4375 15.1875C17.4375 15.4982 17.1857 15.75 16.875 15.75H9.5625C9.25184 15.75 9 15.4982 9 15.1875ZM9 9.43749C9 9.12683 9.25184 8.87499 9.5625 8.87499H16.875C17.1857 8.87499 17.4375 9.12683 17.4375 9.43749C17.4375 9.74815 17.1857 9.99999 16.875 9.99999H9.5625C9.25184 9.99999 9 9.74815 9 9.43749ZM11.5312 3.9375C11.5312 3.62684 11.7831 3.375 12.0938 3.375H16.875C17.1857 3.375 17.4375 3.62684 17.4375 3.9375C17.4375 4.24816 17.1857 4.5 16.875 4.5H12.0938C11.7831 4.5 11.5312 4.24816 11.5312 3.9375Z"
										fill="#585B62"
										stroke="#585B62"
										stroke-width="0.3"
									/>
									<path
										d="M10.0828 4.24414C10.3307 4.0856 10.3283 3.72276 10.0784 3.56746L6.48928 1.33665C6.22283 1.17103 5.87812 1.36264 5.87812 1.67637V3.37497H5.66842C4.28143 3.37497 2.98512 4.03433 2.01797 5.23158C1.0793 6.3937 0.5625 7.93179 0.5625 9.56247C0.5625 11.1932 1.0793 12.7312 2.01797 13.8934C2.98512 15.0906 4.28143 15.75 5.66828 15.75H7.03125C7.34191 15.75 7.59375 15.4981 7.59375 15.1875C7.59375 14.8768 7.34191 14.625 7.03125 14.625H5.66828C3.47344 14.625 1.6875 12.3539 1.6875 9.56247C1.6875 6.77107 3.47344 4.49997 5.66828 4.49997H5.87812V6.20307C5.87812 6.51913 6.22742 6.71034 6.49367 6.54002L10.0828 4.24414Z"
										fill="#585B62"
										stroke="#585B62"
										stroke-width="0.3"
									/>
								</svg>
								<span>Relevance</span>
							</div>
							<div class="col-4 py-75">
								<div
									class="p-2 text-center background-heatmap uppercase"
									[ngClass]="
										service.useCaseService.useCaseGetPercentBackground(service.dataValues[2])
									"
								>
									{{ service.useCaseService.useCaseGetPercentLabel(service.dataValues[2]) }}
								</div>
							</div>
						</div>
						<div class="grid">
							<div class="col-8 flex justify-content-start align-items-center py-75 gap-3">
								<svg
									width="18"
									height="18"
									viewBox="0 0 18 18"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M12.1226 11.3224C14.1849 12.0549 15.9476 13.5101 17.105 15.5104C17.1557 15.5936 17.1894 15.686 17.2041 15.7824C17.2188 15.8792 17.214 15.9779 17.1899 16.0729C17.1658 16.1678 17.123 16.2569 17.0639 16.335C17.0048 16.4131 16.9307 16.4786 16.8459 16.5276C16.7611 16.5765 16.6673 16.608 16.5702 16.6202C16.473 16.6324 16.3744 16.6249 16.2801 16.5984C16.1859 16.5718 16.0979 16.5266 16.0214 16.4655C15.9453 16.4047 15.8821 16.3294 15.8354 16.2439C14.3883 13.7439 11.8331 12.2533 8.99976 12.2533C6.16644 12.2533 3.6112 13.7439 2.16412 16.2439C2.11746 16.3294 2.05423 16.4047 1.97813 16.4655C1.90162 16.5266 1.81364 16.5718 1.71939 16.5984C1.62514 16.6249 1.52652 16.6324 1.42935 16.6202C1.33218 16.608 1.23844 16.5765 1.15364 16.5276C1.06884 16.4786 0.994717 16.4131 0.935632 16.335C0.876547 16.2569 0.833701 16.1678 0.809623 16.0729C0.785544 15.9779 0.780721 15.8792 0.795437 15.7824C0.810079 15.686 0.84377 15.5936 0.894535 15.5104C2.05193 13.5094 3.81456 12.0542 5.8768 11.3223C4.89786 10.6835 4.13665 9.75849 3.69875 8.6706C3.23148 7.50973 3.15819 6.22757 3.49014 5.02102C3.82209 3.81446 4.54093 2.75023 5.53626 1.99176C6.53159 1.23329 7.74837 0.82251 8.99976 0.82251C10.2511 0.82251 11.4679 1.23329 12.4633 1.99176C13.4586 2.75023 14.1774 3.81446 14.5094 5.02102C14.8413 6.22757 14.768 7.50973 14.3008 8.6706C13.8629 9.75852 13.1016 10.6835 12.1226 11.3224ZM4.75206 6.53884C4.75208 5.69876 5.0012 4.87756 5.46793 4.17906C5.93467 3.48053 6.59807 2.93609 7.37423 2.6146C8.1504 2.2931 9.00447 2.20898 9.82844 2.37288C10.6524 2.53678 11.4093 2.94133 12.0033 3.53538C12.5974 4.12943 13.0019 4.8863 13.1658 5.71027C13.3297 6.53425 13.2456 7.38832 12.9241 8.16448C12.6026 8.94065 12.0582 9.60405 11.3597 10.0708C10.6612 10.5375 9.83995 10.7866 8.99988 10.7867C7.87366 10.7855 6.79391 10.3375 5.99755 9.54117C5.20119 8.74481 4.75326 7.66506 4.75206 6.53884Z"
										fill="#585B62"
									/>
								</svg>
								<span>Usability</span>
							</div>
							<div class="col-4 py-75">
								<div
									class="p-2 text-center background-heatmap uppercase"
									[ngClass]="
										service.useCaseService.useCaseGetPercentBackground(service.dataValues[3])
									"
								>
									{{ service.useCaseService.useCaseGetPercentLabel(service.dataValues[3]) }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12 md:col-3 lg:col-3 xl:col-3">
				<div id="readiness-highlights" class="card card-box py-1 px-3 h-full">
					<p class="m-0 ml-1 text-overall-title text-center px-3">Feasibility Highlights</p>
					<div
						class="flex lg:flex-column xl:flex-column justify-content-between lg:justify-content-center xl:justify-content-center gap-3"
					>
						<ng-container *ngFor="let dataChart of service.dataChartsResult; let i = index">
							<div
								*ngIf="
									dataChart.label === 'Security / Governance' ||
									dataChart.label === 'Infrastructure' ||
									dataChart.label === 'AI Talent'
								"
								class="inner-box"
							>
								<div class="p-0">
									<div
										class="justify-content-center align-content-center"
										(click)="service.changeStep(i + 1)"
									>
										<p class="mb-0 text-center">{{ dataChart.label }}</p>
										<div class="chart-result">
											<p-chart
												type="doughnut"
												[id]="'chart-' + dataChart.label.toLowerCase()"
												[data]="dataChart.data"
												[options]="dataChart.options"
											></p-chart>
											<p *ngIf="dataChart.value" class="percent">{{ dataChart.value }}%</p>
											<p-skeleton
												*ngIf="!dataChart.value"
												styleClass="percent-loading"
											></p-skeleton>
										</div>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card card-box p-0">
					<div class="flex justify-content-start p-3">
						<p class="mx-0 mt-2 mb-0 text-overall-title">Top Benefits</p>
					</div>
					<div class="flex flex-wrap pb-3">
						<ng-template
							ngFor
							let-benefit
							[ngForOf]="service.useCaseService.currentUseCase.calculatedData.topBenefits"
						>
							<div class="col p-0" *ngIf="benefit?.label !== 'Process Productivity'">
								<div class="flex flex-column gap-0">
									<div
										class="header-benefits flex justify-content-center align-items-center h-3rem font-bold"
									>
										<p>{{ benefit.label }}</p>
									</div>
									<div class="flex justify-content-center align-items-center h-5rem">
										<div
											class="p-3 text-center background-heatmap h-3rem"
											[ngClass]="benefit.class_value"
										>
											{{ benefit.label_value }}
										</div>
									</div>
								</div>
							</div>
						</ng-template>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
