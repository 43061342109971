import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UseCase } from '../../../../api/use-case.api';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Options as OptionsSlider } from 'ngx-slider-v2/options';
import { combineLatest, of, startWith } from 'rxjs';
import { AIOpportunity, PocInput } from '../../types';
import { DesignNexusService } from '../../design-nexus.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpClient } from '@angular/common/http';
import { SimulationService } from '../../../simulation/simulation.service';
import showdown from 'showdown';
import { ApiService } from '../../../../service/api.service';
import { LoadingService } from '../../../common/loading/loading.service';

@Component({
	selector: 'app-design-nexus-modal',
	templateUrl: './design-nexus-modal.component.html',
	styleUrls: ['./design-nexus-modal.component.scss'],
	providers: [MessageService, ConfirmationService],
})
export class DesignNexusModalComponent implements OnInit, AfterViewInit {
	@ViewChild('tableInput') tableInput: ElementRef<HTMLTableElement>;

	constructor(
		public config: DynamicDialogConfig<{ pocInput: PocInput; opportunity: AIOpportunity }>,
		public ref: DynamicDialogRef,
		private formBuilder: FormBuilder,
		private messageService: MessageService,
		private simulationService: SimulationService,
		public service: DesignNexusService,
		private http: HttpClient,
		private apiService: ApiService,
		private confirmationService: ConfirmationService,
		private loading: LoadingService,
	) {
	}
	pocInput: PocInput;
	opportunity: AIOpportunity;
	@Input() resetStepperEvent!: EventEmitter<void>;
	@Input() current_use_case: UseCase | undefined;
	@Input() can_edit: boolean = false;
	@Output() closeModal = new EventEmitter<void>();

	form: FormGroup = this.formBuilder.group({
		title: [''],
		description: [''],
		generalInformation: this.formBuilder.group({
			typeImpact: [''],
			complexity: [''],
			opportunitySource: [''],
			source: this.formBuilder.group({
				'ui-ux': [0],
				impact: [0],
				confidence: [0],
				ease: [0],
				model: [0],
				integrations: [0],
				dataSources: [0],
			}),
		}),
		functionalRequirements: this.formBuilder.array([]),
		noFunctionalRequirements: this.formBuilder.array([]),
		pocRequirements: this.formBuilder.array([]),
		integratedRequirements: this.formBuilder.array([]),
	});

	functionalities: { type: string; description: string }[] = [
		// { type: '', description: '' }
	];

	loadingApprove: boolean = false;

	toggleSectionFields(
		sections: {
			title: string;
			required: boolean;
			fields: { text: string; disabled: boolean }[];
			isEditingTitle: boolean;
		}[],
		sectionIndex: number,
	) {
		const section = sections[sectionIndex];
		section.fields.forEach((field) => {
			field.disabled = !section.required;
		});
	}

	editSectionTitle(
		sections: {
			title: string;
			required: boolean;
			fields: { text: string; disabled: boolean }[];
			isEditingTitle: boolean;
		}[],
		index: number,
	) {
		sections[index].isEditingTitle = true;
	}

	addFunctionality() {
		this.functionalities.push({ type: '', description: '' });
	}

	removeFunctionality(index: number) {
		if (this.functionalities.length > 0) {
			this.functionalities.splice(index, 1);
		}
	}

	iceScoreChartData: any;
	iceScoreChartOptions: any;

	activeTabIndex: number = 0;
	tabHeaders: string[] = [
		'General Information',
		'Functional Requirements',
		'Non-Functional Requirements',
		'Inputs and Data Sources',
		'PoC Requirements',
		'Integrated and Scaled Requirements',
		'Deployment Handshake',
		'Effort & Cost Estimation',
	];

	activeStep: number = 0;
	loadingDesignNexusContent: boolean = false;

	getActiveClass(i: number) {
		let cls = this.activeTabIndex === i ? 'active' : '';
		if (this.loadingDesignNexusContent) {
			cls = [cls, 'cursor-wait'].join(' ');
		} else {
			cls = [cls, 'hoverable'].join(' ');
		}
		return cls;
	}

	completedTabs: boolean[] = Array(this.tabHeaders.length).fill(false);

	toggleCompleted(tabIndex: number, event: any): void {
		this.completedTabs[tabIndex] = event.checked;
	}

	async changeActiveStep(i: number) {
		if (!this.loadingDesignNexusContent) {
			if (i < 0) {
				i = 0;
			}
			if (i > this.tabHeaders.length - 1) {
				i = this.tabHeaders.length - 1;
			}
			if (this.activeStep !== i) {
				this.activeStep = i;
				this.activeTabIndex = i;
			}
		}
	}

	impactOptions = [
		{ label: 'Revenue Growth Analysis', value: 'Revenue Growth Analysis' },
		{ label: 'Customer Experience', value: 'Customer Experience' },
		{ label: 'Process Productivity', value: 'Process Productivity' },
		{ label: 'Employee Productivity', value: 'Employee Productivity' },
		{ label: 'Cost Savings', value: 'Cost Savings' },
	];

	complexityOptions = [
		{ label: 'Low', value: 'Low' },
		{ label: 'Medium', value: 'Medium' },
		{ label: 'High', value: 'High' },
	];

	sourceOptions = [
		{ label: 'ZBrain', value: 'ZBrain' },
		{ label: 'Custom', value: 'Custom' },
		{ label: 'Embedded', value: 'Embedded' },
	];

	vendorOptions = [
		{ label: 'Microsoft Power BI', value: 'Microsoft Power BI' },
		{ label: 'Tableau', value: 'Tableau' },
		{ label: 'Google Data Studio', value: 'Google Data Studio' },
		{ label: 'QlikView', value: 'QlikView' },
		{ label: 'SAP Analytics Cloud', value: 'SAP Analytics Cloud' },
	];

	selectedSource: string = '';

	getFormSliderOptions(): OptionsSlider {
		return {
			floor: 0,
			ceil: 10,
			step: 1,
			showTicks: true,
			showTicksValues: true,
			getPointerColor: (value: number): string => {
				if (value === 0) {
					return '#CC3333';
				} else if (value === 1) {
					return '#D1493D';
				} else if (value === 2) {
					return '#D86047';
				} else if (value === 3) {
					return '#DE7751';
				} else if (value === 4) {
					return '#E58D5B';
				} else if (value === 5) {
					return '#EBA366';
				} else if (value === 6) {
					return '#F2B971';
				} else if (value === 7) {
					return '#F8CF7B';
				} else if (value === 8) {
					return '#D4D88D';
				} else if (value === 9) {
					return '#A6C867';
				} else if (value === 10) {
					return '#6B9E00';
				} else {
					return '#C1C6C8';
				}
			},
			translate: (value: number): string => {
				return '';
			},
			getLegend: (value: number): string => {
				return `<span class="text-class">${value}</span>`;
			},
		};
	}

	resetStepper(): void {
		this.activeTabIndex = 0;
		this.activeStep = 0;
	}

	ngOnInit(): void {
		if (this.resetStepperEvent) {
			this.resetStepperEvent.subscribe(() => {
				this.resetStepper();
			});
		}
		this.setComponentData();
		this.setComponentEvents();
		this.initializeIceScoreChart();
		this.initializeForm();
	}

	displaySlider: boolean = true;

	ngAfterViewInit() {
		this.displaySlider = false;
		setTimeout(() => {
			this.displaySlider = true;
		}, 200);
	}

	previousTab(): void {
		if (this.activeTabIndex > 0) {
			this.activeTabIndex--;
		}
		this.savePoc();
	}

	nextTab(): void {
		if (this.activeTabIndex < this.tabHeaders.length - 1) {
			this.activeTabIndex++;
		}
		this.savePoc();
	}

	selectTab(index: number): void {
		this.activeTabIndex = index;
		this.activeStep = index;
	}

	saveUseCase(): void {
		this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Use Case saved successfully!' });
	}

	private calculatePercentage(...values: number[]): number {
		const sum = values.reduce((acc, value) => acc + value, 0);
		const maxSum = values.length * 10;
		return Math.round((sum / maxSum) * 100);
	}

	private updateIceChart(...values: number[]) {
		const totalICEScorePercentage = this.calculatePercentage(...values);

		this.iceScoreChartData = {
			labels: ['', ''],
			datasets: [
				{
					label: '',
					data: [totalICEScorePercentage, 100 - totalICEScorePercentage],
					backgroundColor: ['#7FAA3A', '#C1C6C8'],
					borderColor: ['rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)'],
					borderWidth: 5,
				},
			],
		};
	}

	async savePoc(approved: boolean = false) {
		this.loadingPoc = true;
		if (approved) {
			this.loadingApprove = true;
		}

		const { generalInformation } = this.form.value;
		const { source } = generalInformation;

		const iceScore = this.calculatePercentage(source.impact, source.confidence, source.ease);

		const pocInput: PocInput = {
			...this.pocInput,
			...this.form.value,
			generalInformation: {
				...this.pocInput.generalInformation,
				...generalInformation,
				source: {
					...this.pocInput.generalInformation?.source,
					...source,
					'ice-score': iceScore,
				},
			},
			approved,
		} as any;

		await this.service.savePoc(pocInput);

		this.pocInput.approved = approved;
		if (approved) {
			this.loadingApprove = false;
		}

		this.loadingPoc = false;
	}

	private setComponentData() {
		this.pocInput = this.config?.data?.pocInput as PocInput;
		this.opportunity = this.config?.data?.opportunity as AIOpportunity;

		if (typeof (this.opportunity.data as any).knowledgeData === 'string') {
			this.opportunity.data.knowledgeData = this.getKnowledgeData(this.opportunity.data.knowledgeData as any);
		}

		if (!Array.isArray(this.pocInput.inputsDataSource)) {
			this.pocInput.inputsDataSource = [];
		}

		if (this.pocInput.effortAndCostEstimation) {
			this.setEffortAndCostEstimation(this.pocInput.effortAndCostEstimation);
		}

		console.log('DesignNexusModalComponent.setComponentData', this.pocInput, this.opportunity);
	}

	getKnowledgeData(knowledgeData: string = '') {
		return knowledgeData
			.replace(/(\r\n|\n|\r)/gm, '')
			.replace('.', '')
			.split(',')
			.map((d) => d.trim())
			.map((d) => d.charAt(0).toUpperCase() + d.slice(1));
	}

	private setComponentEvents() {
		if (this.pocInput) {
			this.ref.onClose.pipe().subscribe(() => this.savePoc());
		}
	}

	private initializeIceScoreChart() {
		this.iceScoreChartData = {
			labels: ['', ''],
			datasets: [
				{
					label: '',
					data: [0, 100],
					backgroundColor: ['#7FAA3A', '#C1C6C8'],
					borderColor: ['rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)'],
					borderWidth: 5,
				},
			],
		};

		this.iceScoreChartOptions = {
			cutout: '60%',
			rotation: 270,
			circumference: 180,
			responsive: true,
			maintainAspectRatio: true,
			aspectRatio: 2,
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					enabled: false,
				},
				datalabels: {
					display: false,
				},
			},
		};
	}

	private getControlValueChanges(controlName: string, parentControl?: AbstractControl<any, any> | null) {
		if (!parentControl) {
			parentControl = this.form;
		}

		const control = parentControl.get(controlName);
		if (!control) {
			console.warn(`Control ${controlName} is not defined in the form.`);
			return of(null);
		}
		return control.valueChanges.pipe(startWith(control.value));
	}

	private initializeForm(): void {
		const { description, title } = this.pocInput;
		const { typeImpact, complexity, opportunitySource } = this.pocInput.generalInformation;
		const {
			'ui-ux': uxUI,
			impact,
			confidence,
			ease,
			model,
			integrations,
			dataSources,
		} = this.pocInput.generalInformation.source;

		if (!this.pocInput?.functionalRequirements?.length) {
			this.pocInput.functionalRequirements = [
				{
					title: 'User Stories',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: '',
							disabled: false,
						},
					],
				},
				{
					title: 'UX/UI Requirements',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: '',
							disabled: false,
						},
					],
				},
				{
					title: 'Business Rules',
					required: true,
					editable: false,
					items: [{ title: '', text: '', disabled: false }],
				},
				{
					title: 'Outputs and Reports',
					required: true,
					editable: false,
					items: [{ title: '', text: '', disabled: false }],
				},
			];
		}

		if (!this.pocInput?.noFunctionalRequirements?.length) {
			this.pocInput.noFunctionalRequirements = [
				{
					title: 'Current Technology Stack',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: '',
							disabled: false,
						},
					],
				},
				{
					title: 'Data Security',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: '',
							disabled: false,
						},
					],
				},
				{
					title: 'Network and Infrastructure',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: '',
							disabled: false,
						},
					],
				},
				{
					title: 'Usage Requirements',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: '',
							disabled: false,
						},
					],
				},
			];
		}

		if (!this.pocInput?.pocRequirements?.length) {
			this.pocInput.pocRequirements = [
				{
					title: 'POC Scope Of Work',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: '',
							disabled: false,
						},
					],
				},
				{
					title: 'PoC Data Requirements',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: '',
							disabled: false,
						},
					],
				},
				{
					title: 'Deliverables',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: '',
							disabled: false,
						},
					],
				},
				{
					title: 'Acceptance Criteria',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: '',
							disabled: false,
						},
					],
				},
			];
		}

		if (!this.pocInput?.integratedRequirements?.length) {
			this.pocInput.integratedRequirements = [
				{
					title: 'Statement of Work',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: '',
							disabled: false,
						},
					],
				},
				{
					title: 'Deliverables',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: '',
							disabled: false,
						},
					],
				},
				{
					title: 'Acceptance Criteria',
					required: true,
					editable: false,
					items: [
						{
							title: '',
							text: '',
							disabled: false,
						},
					],
				},
			];
		}

		const functionalRequirementsArray = this.form.get('functionalRequirements') as FormArray;
		this.pocInput.functionalRequirements.forEach((item: any) => {
			functionalRequirementsArray.push(this.createSection(item.items));
		});

		const noFunctionalRequirementsArray = this.form.get('noFunctionalRequirements') as FormArray;
		this.pocInput.noFunctionalRequirements.forEach((item: any) => {
			noFunctionalRequirementsArray.push(this.createSection(item.items));
		});

		const pocRequirementsArray = this.form.get('pocRequirements') as FormArray;
		this.pocInput.pocRequirements.forEach((item: any) => {
			pocRequirementsArray.push(this.createSection(item.items));
		});

		const integratedRequirementsArray = this.form.get('integratedRequirements') as FormArray;
		this.pocInput.integratedRequirements.forEach((item: any) => {
			integratedRequirementsArray.push(this.createSection(item.items));
		});

		this.form.patchValue({
			...this.pocInput,
			title,
			description,
			generalInformation: {
				typeImpact,
				complexity,
				opportunitySource,
				source: {
					'ui-ux': uxUI,
					impact,
					confidence,
					ease,
					model,
					integrations,
					dataSources,
				},
			},
		});

		const impact$ = this.getControlValueChanges('impact', this.form.get('generalInformation')?.get('source'));
		const confidence$ = this.getControlValueChanges(
			'confidence',
			this.form.get('generalInformation')?.get('source'),
		);
		const ease$ = this.getControlValueChanges('ease', this.form.get('generalInformation')?.get('source'));
		const opportunitySource$ = this.getControlValueChanges(
			'opportunitySource',
			this.form.get('generalInformation'),
		);

		combineLatest([impact$, confidence$, ease$]).subscribe(([impactValue, confidenceValue, easeValue]) => {
			this.updateIceChart(impactValue, confidenceValue, easeValue);
		});

		opportunitySource$.subscribe((opportunitySource) => {
			console.log(opportunitySource);
			this.selectedSource = opportunitySource;
		});
	}

	private createSection(items: string[] = []): FormGroup {
		const itemsFormArray = this.formBuilder.array(
			items.length ? items.map(() => this.createItem()) : [this.createItem()],
		);

		return this.formBuilder.group({
			attachments: [],
			title: ['new title', Validators.required],
			required: [false],
			items: itemsFormArray,
		});
	}

	private createItem(): FormGroup {
		return this.formBuilder.group({
			title: '',
			text: ['', Validators.required],
			disabled: [false],
		});
	}

	getFormArray(arrayName: string): FormArray<FormGroup> {
		return this.form.get(arrayName) as FormArray<FormGroup>;
	}

	addSection(arrayName: string) {
		this.getFormArray(arrayName).push(this.createSection());
	}

	removeSection(arrayName: string, sectionIndex: number) {
		this.getFormArray(arrayName).removeAt(sectionIndex);
	}

	contentEffortAndCostEstimation: any;
	loadingEffort: boolean = false;
	loadingPoc: boolean = false;

	async sendPocData() {
		this.pocInput.approved = false;
		await this.simulationService.getEnterpriseContext();

		const data = {
			poc: this.pocInput,
			enterpriseContext: this.simulationService.enterpriseContext?.enterpriseContext,
		};

		this.loadingEffort = true;

		this.http
			.post<{ callback: any }>('https://app.zbrain.ai:8080/api/v1/webhooks/aJhsHz21rG5PgiZU296YH/sync', data, {
				headers: {
					Authorization:
						'Bearer za-U2FsdGVkX1+3BvfuEnJ9qqQxfacnsO7q7d8D/Wk+U8eik9+NY4JEIB6xCmh6EqGh+8oNYjdU+mC1JD/rov9E5GU1xFLDhtLzykHtwAEWBiVh5Cn7UKjph++Wlah0Sg93',
				},
			})
			.subscribe((response) => {
				this.setEffortAndCostEstimation(response);
				this.pocInput.approved = false;
				this.loadingApprove = false;
				this.loadingEffort = false;
			});
	}

	loadingAI: boolean = false;
	aiAssistants = {
		integratedReqAIAgent: 'asst_cnUqcgs1u9XkZtaft9Q7kHrA',
		pocReqAIAgent: 'asst_QRcAg6PWJKBazBZLnLYnorDY',
		nonFunctionalReqAIAgent: 'asst_RogLQ0HE8yNUyuKmTBur6Wya',
		functionalReqAIAgent: 'asst_2tdEjbf8EbA6eBV6Y2N6o9kA',
	};

	confirmAndGenerate(agentKey: keyof typeof this.aiAssistants, formArrayName: string): void {
		const agent = this.aiAssistants[agentKey];
		if (!agent) {
			console.warn(`No AI assistant found for key: ${agentKey}`);
			return;
		}
		this.confirmationService.confirm({
			message: 'This action will generate content using AI. Do you want to proceed?',
			header: 'Generate AI',
			icon: 'pi pi-info-circle',
			accept: () => {
				this.loadingAI = true;
				this.loading.show();
				this.apiService.getAssistant(agent, JSON.stringify(this.jsonTemp), (response: any) => {
					this.loading.hide();
					this.loadingAI = false;
					console.log(`AI Response for ${agent}:`, response);
					const titleMaps = {
						functionalRequirements: {
							user_stories: 'User Stories',
							ux_ui_requirements: 'UX/UI Requirements',
							business_rules: 'Business Rules',
							outputs_and_reports: 'Outputs and Reports',
						},
						noFunctionalRequirements: {
							current_technology_stack: 'Current Technology Stack',
							data_security: 'Data Security',
							network_and_infrastructure: 'Network and Infrastructure',
							usage_requirements: 'Usage Requirements',
						},
						pocRequirements: {
							poc_scope_of_work: 'PoC Scope Of Work',
							poc_data_requirements: 'PoC Data Requirements',
							deliverables: 'Deliverables',
							acceptance_criteria: 'Acceptance Criteria',
						},
						integratedRequirements: {
							statement_of_work: 'Statement of Work',
							deliverables: 'Deliverables',
							acceptance_criteria: 'Acceptance Criteria',
						},
					};
					if (response?.func_req && formArrayName === 'functionalRequirements') {
						this.populateRequirements(response.func_req, formArrayName, titleMaps.functionalRequirements);
					}
					if (response?.non_func_req && formArrayName === 'noFunctionalRequirements') {
						this.populateRequirements(response.non_func_req, formArrayName, titleMaps.noFunctionalRequirements);
					}
					if (response?.poc_req && formArrayName === 'pocRequirements') {
						this.populateRequirements(response.poc_req, formArrayName, titleMaps.pocRequirements);
					}
					if (response?.integrated_scaled_req && formArrayName === 'integratedRequirements') {
						this.populateRequirements(response.integrated_scaled_req, formArrayName, titleMaps.integratedRequirements);
					}
				}, (error) => {
					console.error('Error fetching AI response:', error);
					this.loadingAI = false;
					this.loading.hide();
				});
			},
		});
	}

	private populateRequirements(data: any, formArrayName: string, titleMap: { [key: string]: string }): void {
		const formArray = this.form.get(formArrayName) as FormArray;

		// Remove sections with empty items
		for (let i = formArray.length - 1; i >= 0; i--) {
			const section = formArray.at(i) as FormGroup;
			const itemsArray = section.get('items') as FormArray;

			const isSectionEmpty = itemsArray.controls.every((control) => !control.get('text')?.value.trim());
			if (isSectionEmpty) {
				formArray.removeAt(i);
			}
		}

		// Map existing sections
		const existingSections = new Map(
			formArray.controls.map((control) => [control.get('title')?.value.toLowerCase(), control]),
		);

		// Populate sections
		data.forEach((req: any) => {
			const readableTitle = titleMap[req.name] || this.getReadableTitle(req.name);
			if (existingSections.has(readableTitle.toLowerCase())) {
				const existingSection = existingSections.get(readableTitle.toLowerCase());
				const itemsArray = existingSection?.get('items') as FormArray;
				req.items.forEach((item: any) => {
					const isDuplicate = itemsArray.controls.some(
						(control) => control.get('text')?.value === item.description,
					);
					if (!isDuplicate) {
						itemsArray.push(
							this.formBuilder.group({
								title: '',
								text: item.description,
								disabled: false,
							}),
						);
					}
				});
			} else {
				const newSection = this.formBuilder.group({
					title: readableTitle,
					required: true,
					items: this.formBuilder.array(
						req.items.map((item: any) =>
							this.formBuilder.group({
								title: '',
								text: item.description,
								disabled: false,
							}),
						),
					),
				});
				formArray.push(newSection);
			}
		});
	}

	private getReadableTitle(snakeCaseTitle: string): string {
		const titlesMap: { [key: string]: string } = {
			user_stories: 'User Stories',
			ux_ui_requirements: 'UX/UI Requirements',
			business_rules: 'Business Rules',
			outputs_and_reports: 'Outputs and Reports',
			current_technology_stack: 'Current Technology Stack',
			data_security: 'Data Security',
			network_and_infrastructure: 'Network and Infrastructure',
			usage_requirements: 'Usage Requirements',
			poc_scope_of_work: 'PoC Scope Of Work',
			poc_data_requirements: 'PoC Data Requirements',
			deliverables: 'Deliverables',
			acceptance_criteria: 'Acceptance Criteria',
			statement_of_work: 'Statement of Work',
		};
		return (
			titlesMap[snakeCaseTitle] ||
			snakeCaseTitle.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())
		);
	}

	jsonTemp = {
		id: '5d37b7eb-0f55-41ad-ae16-a1cc6669d40f',
		name: 'Dynamic KPI Visualization',
		description:
			'Use Content AI to generate dynamic and interactive visualizations of KPIs. This will enhance the understanding and communication of performance metrics.',
		data: {
			complexity: 2,
			knowledgeData: ['KPI data', 'Visualization templates', 'And user interaction data'],
			integrationRequirement: 'Integration with KPI databases and visualization tools.',
			assumptionsPocValidation:
				'Assume access to accurate KPI data and effective visualization tools. Validate the clarity and usefulness of the visualizations.',
		},
		technologies: [
			{
				id: 'f7a2c5f9-54ba-4732-942f-35acff7a73e9',
				name: 'Content AI',
			},
		],
	};

	getContentAI(agent: string) {
		console.log('getContentAI', agent);
		const formData = this.form.value;
		const dynamicData = {
			id: this.pocInput?.id || 'default-id',
			name: formData.title || 'Untitled',
			description: formData.description || this.pocInput?.description || 'No description provided',
			data: {
				complexity:
					formData.generalInformation?.complexity || this.pocInput?.generalInformation?.complexity || 0,
				knowledgeData: this.opportunity?.data?.knowledgeData || [],
				integrationRequirement: this.pocInput?.integratedRequirements || 'N/A',
			},
			technologies: this.opportunity?.technologies || [],
		};

		this.apiService.getAssistant(
			agent,
			JSON.stringify(dynamicData),
			(response) => {
				console.log(`AI Response for ${agent}:`, response);
			},
			(error) => {
				console.error(`Error occurred for ${agent}:`, error);
			},
		);
	}

	private parseJsonString(jsonString: string): any {
		const cleanedString = jsonString
			.split('```json\n')
			.join('')
			.split('```')
			.join('')
			.split('\\n')
			.join('')
			.split('\n')
			.join('');

		if (!cleanedString.trim()) {
			return undefined;
		}

		try {
			return JSON.parse(cleanedString);
		} catch (error) {
			return undefined;
		}
	}

	private setEffortAndCostEstimation(jsonContent: any) {
		if (jsonContent && Object.keys(jsonContent).length > 0) {
			const converter = new showdown.Converter();
			if (typeof jsonContent.Cost === 'string') {
				const cost = this.parseJsonString(jsonContent.Cost);
				if (cost) {
					jsonContent.Cost = cost;
				}
			}
			this.pocInput.effortAndCostEstimation = jsonContent;
			this.contentEffortAndCostEstimation = Object.entries(this.pocInput.effortAndCostEstimation).map(
				([key, value]) => {
					if (typeof value === 'object') {
						const valueString = Object.entries(value as any)
							.map(([title, text]) => {
								let valText = text;
								if (Array.isArray(text)) {
									valText = (text as string[]).map((item: any) => `- ${item}`).join('\n\n');
								}
								return `##${title}\n\n${valText}`;
							})
							.join('\n');
						return [key, converter.makeHtml(valueString)];
					} else if (typeof value === 'string') {
						return [key, converter.makeHtml(value)];
					}
					return [key, ''];
				},
			);
		}
	}
}
