import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CountUp } from 'countup.js';
import { SimulationService } from '../simulation.service';
import { isNumber } from 'mathjs';

@Component({
	selector: 'app-taxonomy-context',
	templateUrl: './taxonomy.component.html',
	styleUrls: ['./taxonomy.component.scss'],
})
export class TaxonomyComponent implements OnInit {
	@ViewChild('all_countUp', { static: true }) all_countUpElement!: ElementRef;
	@ViewChild('aiOpportunitiesCountUp', { static: true }) aiOpportunitiesElement!: ElementRef;
	// @ViewChild('genAiOpportunitiesCountUp', { static: true }) genAiOpportunitiesElement!: ElementRef;
	@ViewChild('embeddedOpportunitiesCountUp', { static: true }) embeddedOpportunitiesElement!: ElementRef;
	@ViewChild('genAiUseCasesCountUp', { static: true }) genAiUseCasesElement!: ElementRef;
	@ViewChild('e2eCountUp', { static: true }) e2eElement!: ElementRef;
	@ViewChild('processCountUp', { static: true }) processElement!: ElementRef;
	@ViewChild('subProcessCountUp', { static: true }) subProcessElement!: ElementRef;
	@ViewChild('e2eSourceRelCountUp', { static: true }) e2eSourceRelElement!: ElementRef;
	@ViewChild('processSourceRelCountUp', { static: true }) processSourceRelElement!: ElementRef;
	@ViewChild('subProcessSourceRelCountUp', { static: true }) subProcessSourceRelElement!: ElementRef;
	@ViewChild('breakthroughCountUp', { static: true }) breakthroughElement!: ElementRef;
	@ViewChild('transformativeCountUp', { static: true }) transformativeElement!: ElementRef;
	@ViewChild('incrementalCountUp', { static: true }) incrementalElement!: ElementRef;

	private aiOpportunitiesCountUp!: CountUp;
	// private genAiOpportunitiesCountUp!: CountUp;
	private embeddedOpportunitiesCountUp!: CountUp;
	private genAiUseCasesCountUp!: CountUp;
	private e2eCountUp!: CountUp;
	private processCountUp!: CountUp;
	private subProcessCountUp!: CountUp;
	private e2eSourceRelCountUp!: CountUp;
	private processSourceRelCountUp!: CountUp;
	private subProcessSourceRelCountUp!: CountUp;
	private breakthroughCountUp!: CountUp;
	private transformativeCountUp!: CountUp;
	private incrementalCountUp!: CountUp;

	constructor(public simulationService: SimulationService) {
		this.simulationService.counterGenAiObservable.subscribe(() => {
			this.startCountUps();
		});
	}

	ngOnInit() {
		this.startCountUps();
	}

	startCountUps() {
		const options = { startVal: 0, duration: 2 };

		this.aiOpportunitiesCountUp = new CountUp(
			this.aiOpportunitiesElement.nativeElement,
			this.simulationService.counters.aiOpportunities,
			options,
		);
		// this.genAiOpportunitiesCountUp = new CountUp(
		// 	this.genAiOpportunitiesElement.nativeElement,
		// 	this.simulationService.counters.genAiOpportunities,
		// 	options,
		// );

		// this.genAiOpportunitiesCountUp = new CountUp(
		// 	this.genAiOpportunitiesElement.nativeElement,
		// 	this.simulationService.counters.genAiOpportunities,
		// 	options,
		// );

		this.embeddedOpportunitiesCountUp = new CountUp(
			this.embeddedOpportunitiesElement.nativeElement,
			this.simulationService.counters.embeddedAI,
			options,
		);

		this.genAiUseCasesCountUp = new CountUp(
			this.genAiUseCasesElement.nativeElement,
			this.simulationService.counters.genAiUseCases,
			options,
		);
		this.e2eCountUp = new CountUp(this.e2eElement.nativeElement, this.simulationService.counters.e2e, options);
		this.processCountUp = new CountUp(
			this.processElement.nativeElement,
			this.simulationService.counters.process,
			options,
		);
		this.subProcessCountUp = new CountUp(
			this.subProcessElement.nativeElement,
			this.simulationService.counters.subProcess,
			options,
		);
		this.e2eSourceRelCountUp = new CountUp(
			this.e2eSourceRelElement.nativeElement,
			this.simulationService.counters.e2eSourceRel,
			options,
		);
		this.processSourceRelCountUp = new CountUp(
			this.processSourceRelElement.nativeElement,
			this.simulationService.counters.processSourceRel,
			options,
		);
		this.subProcessSourceRelCountUp = new CountUp(
			this.subProcessSourceRelElement.nativeElement,
			this.simulationService.counters.subProcessSourceRel,
			options,
		);

		this.breakthroughCountUp = new CountUp(
			this.breakthroughElement.nativeElement,
			this.simulationService.counters.breakthrough,
			options,
		);

		this.transformativeCountUp = new CountUp(
			this.transformativeElement.nativeElement,
			this.simulationService.counters.transformative,
			options,
		);

		this.incrementalCountUp = new CountUp(
			this.incrementalElement.nativeElement,
			this.simulationService.counters.incremental,
			options,
		);

		this.aiOpportunitiesCountUp.start();
		// this.genAiOpportunitiesCountUp.start();
		this.embeddedOpportunitiesCountUp.start();
		this.genAiUseCasesCountUp.start();
		this.e2eCountUp.start();
		this.processCountUp.start();
		this.subProcessCountUp.start();
		this.e2eSourceRelCountUp.start();
		this.processSourceRelCountUp.start();
		this.subProcessSourceRelCountUp.start();
		this.breakthroughCountUp.start();
		this.transformativeCountUp.start();
		this.incrementalCountUp.start();
	}

	// getDataChartValue(value: number) {
	// 	const documentStyle = getComputedStyle(document.documentElement);
	// 	let color = '#1B54F8';

	// 	return {
	// 		labels: ['', ''],
	// 		datasets: [
	// 			{
	// 				label: '',
	// 				data: [value, 100 - value],
	// 				backgroundColor: [color, documentStyle.getPropertyValue('--surface-300')],
	// 				borderColor: ['rgba(255, 255, 255 ,1)', 'rgba(255, 255, 255 ,1)'],
	// 				borderWidth: 5,
	// 			},
	// 		],
	// 	};
	// }
}
