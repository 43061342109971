import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-must-have-icon',
	template: `
		<ng-template [ngIf]="value === 'GOOD TO HAVE'">
			<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M13.5768 5.0384C13.2352 4.6328 12.7336 4.4 12.2008 4.4H8.9736V2.2C8.9736 0.9864 7.9896 0 6.7072 0C6.1496 0 5.6448 0.334399 5.4224 0.851999L3.3936 5.6H1.9C0.852 5.6 0 6.4504 0 7.496V12.112C0 13.1536 0.852 14 1.9 14H3.1808H4.3904H11.1496C12.0216 14 12.7656 13.3752 12.92 12.512L13.9728 6.512L13.9736 6.5104C14.0648 5.9824 13.92 5.4464 13.5768 5.0384ZM1.9 12.8008C1.5144 12.8008 1.2 12.492 1.2 12.1128V7.4968C1.2 7.1136 1.5144 6.8008 1.9 6.8008H3.1888L3.1816 12.8008H1.9ZM11.7376 12.3024C11.6864 12.5904 11.4384 12.8 11.1488 12.8H4.3896V6.324L6.5256 1.3256C6.5576 1.2496 6.6296 1.2008 6.7792 1.2008C7.328 1.2008 7.7736 1.6496 7.7736 2.2008V5.6008H12.2008C12.3816 5.6008 12.544 5.676 12.6584 5.812C12.7744 5.9504 12.8208 6.1256 12.7896 6.3056L11.7376 12.3024Z"
					fill="#1B54F8"
				/>
			</svg>
		</ng-template>
		<ng-template [ngIf]="value === 'MUST HAVE'">
			<svg width="17" height="17" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M13.6102 4.10992C13.3002 3.74992 12.8202 3.53992 12.3802 3.54992L9.67023 3.57992V1.79992C9.67023 1.03992 9.16023 0.389924 8.42023 0.219924L8.07023 0.139924C7.56023 0.0199244 7.04023 0.279924 6.83023 0.759924L5.76023 3.19992C5.64023 3.13992 5.53023 3.07992 5.39023 3.03992L5.04023 2.95992C4.53023 2.83992 4.01023 3.09992 3.80023 3.57992L2.27023 7.06992H1.37023C0.610234 7.06992 -0.00976562 7.68992 -0.00976562 8.44992V12.5299C-0.00976562 13.2899 0.610234 13.9099 1.37023 13.9099H8.73023C9.48023 13.9099 10.1102 13.3699 10.2302 12.6399L10.4802 11.0999H11.7502C12.5002 11.0999 13.1302 10.5599 13.2502 9.82992L13.9702 5.41992C14.0502 4.94992 13.9102 4.47992 13.6102 4.11992V4.10992ZM1.18023 12.5199V8.43992C1.18023 8.32992 1.27023 8.23992 1.38023 8.23992H2.08023V12.7299H1.38023C1.27023 12.7299 1.18023 12.6399 1.18023 12.5299V12.5199ZM9.08023 12.4399C9.05023 12.5999 8.91023 12.7199 8.75023 12.7199H3.27023V7.75992L4.87023 4.10992L5.13023 4.16992C5.33023 4.21992 5.47023 4.39992 5.47023 4.59992V7.56992L9.37023 7.52992C9.57023 7.52992 9.64023 7.61992 9.69023 7.67992C9.74023 7.73992 9.81024 7.84992 9.79023 8.01992L9.07023 12.4299L9.08023 12.4399ZM12.0902 9.61992C12.0602 9.77992 11.9202 9.89992 11.7602 9.89992H10.6802L10.9502 8.21992C11.0302 7.74993 10.8902 7.27992 10.5902 6.91992C10.2902 6.55992 9.81023 6.36992 9.36023 6.35992L6.65023 6.38992V4.60992C6.65023 4.47992 6.62023 4.35992 6.59023 4.23992L7.88023 1.28992L8.14023 1.34992C8.34023 1.39992 8.48023 1.57992 8.48023 1.77992V4.74992L12.3802 4.70992C12.5502 4.70992 12.6502 4.79992 12.7002 4.85992C12.7502 4.91992 12.8202 5.02992 12.8002 5.19992L12.0802 9.60992L12.0902 9.61992Z"
					fill="#1B54F8"
				/>
			</svg>
		</ng-template>
	`,
	styles: [],
})
export class MustHaveIconComponent {
	@Input() value: 'MUST HAVE' | 'GOOD TO HAVE' | undefined;
}
