<div class="flex justify-content-between align-items-center gap-3 my-2">
    <span class="m-0 font-bold text-lg">Inputs and Data Sources</span>
    <div class="flex align-items-center gap-2">
        <button class="p-button p-button-icon-only p-button-link toggle-parent-row text-primary" type="button"
                (click)="addRow()">
            <span class="pi pi-plus"></span>
        </button>
    </div>
</div>
<ng-template [ngIf]="!rows || rows.length === 0">
	<div class="w-full custom-box py-5" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
		<i class="pi pi-arrow-right-arrow-left text-primary" style="font-size: 2.5rem;"></i>
		<span class="font-bold text-lg pt-5 pb-3">No Input or Data Source created yet</span>
		<span class="pb-3">Create an Input or Data Source by clicking on the + button.</span>
        <p-button
            label="Add Input or Data Source"
            icon="pi pi-plus"
            (onClick)="addRow()"
		></p-button>
	</div>
</ng-template>
<div class="w-full" *ngIf="rows && rows.length > 0">
    <p-table
        [value]="rows" dataKey="id"
        columnResizeMode="expand"
        class="hubble-design-data-sources-table"
        [tableStyle]="{'min-width': '50rem'}">
        <ng-template pTemplate="header">
            <tr>
                <th *ngFor="let col of columns" [style.width]="col.width">
                    {{ col.header }}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
            <tr>
                <td *ngFor="let col of columns" [pEditableColumn]="row[col.field]" [pEditableColumnField]="col.field">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <ng-template [ngIf]="col.element === 'input'">
                                <input
                                    pInputText
                                    [(ngModel)]="row[col.field]"
                                    placeholder="Insert {{col.header}}"
                                    type="text" />
                            </ng-template>
                            <ng-template [ngIf]="col.element === 'select'">
                                <p-dropdown
                                    [(ngModel)]="row[col.field]"
                                    [options]="options[col.field] ||  []"
                                    placeholder="Select {{col.header}}" />
                            </ng-template>
                            <ng-template [ngIf]="col.element === 'textarea'">
								<textarea
                                    pInputTextarea
                                    placeholder="Insert {{col.header}}"
                                    [(ngModel)]="row[col.field]"
                                    type="text">
								</textarea>
                            </ng-template>
                            <ng-template [ngIf]="col.element === 'file'">
                                <button
                                    class="p-button p-button-icon-only p-button-link toggle-parent-row"
                                    type="button"
                                    (click)="openAttachModal(row.id)"
                                >
                                    <span class="pi pi-paperclip"></span>
                                </button>
                            </ng-template>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <ng-container [ngSwitch]="col.element">
                                <ng-template [ngSwitchCase]="'file'">
                                    <button
                                        class="p-button p-button-icon-only p-button-link toggle-parent-row"
                                        [ngClass]="{
                                        'text-primary': row[col.field]?.length
                                        }"
                                        type="button"
                                        (click)="openAttachModal(row.id)"
                                    >
                                        <span class="pi pi-paperclip"></span>
                                    </button>
                                </ng-template>

                                <ng-template ngSwitchDefault>
                                    {{ row[col.field] }}
                                </ng-template>
                            </ng-container>
                        </ng-template>
                    </p-cellEditor>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
