import { Injectable } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LoadingComponent } from './loading.component';

@Injectable({
	providedIn: 'root',
})
export class LoadingService {
	private ref: DynamicDialogRef | null;

	constructor(private dialogService: DialogService) {}

	show() {
		this.ref = this.dialogService.open(LoadingComponent, {
			width: '100%',
			height: '100%',
			contentStyle: { overflow: 'auto', background: 'transparent' },
			showHeader: false,
			baseZIndex: 999,
			maximizable: false,
			closeOnEscape: false,
			closable: false,
			draggable: false,
			dismissableMask: false,
		});
	}

	hide() {
		if (this.ref) this.ref.close();
		this.ref = null;
	}
}
