<p-toast></p-toast>
<p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>

<div class="card card-content-simulation">
	<div class="flex flex-column w-full gap-5">
		<div class="flex flex-wrap justify-content-start align-content-start grid-main overflow-auto">
			<div class="flex flex-wrap justify-content-start align-content-start sticky-header w-full">
				<div class="col-12 col-header p-main">
					<div class="flex justify-content-between w-full">
						<div class="flex justify-content-start align-items-center gap-1">
							<p-button
								icon="pi pi-chevron-left"
								styleClass="p-button-text"
								(onClick)="goToSimulationList()"
							></p-button>
							<div *ngIf="loaded && simulation">
								<span class="text-primary">{{ simulation.parent_label }} / </span>
								<span class="font-bold">{{ simulation.label }}</span>
							</div>
							<div *ngIf="loaded && !simulation">
								<span class="font-bold">Empty</span>
							</div>
							<div *ngIf="!loaded">
								<i class="pi pi-spin pi-spinner text-primary" style="font-size: 1rem"></i>
							</div>
						</div>
						<div class="flex justify-content-end gap-3 align-items-center">
							<p-button
								styleClass="button-header"
								icon="pi pi-file-edit"
								(onClick)="showDialog = true"
							></p-button>
							<p-button
								[styleClass]="'button-header' + (filterIndex === 1 ? ' active' : '')"
								icon="pi pi-building"
								(onClick)="changeFilter(1)"
							></p-button>
							<p-button
								label="Run simulation"
								icon="pi pi-play-circle"
								(onClick)="runSimulation()"
								[loading]="hasRunSimulation"
								[disabled]="addToMyUseCaseLoading"
							></p-button>
							<p-button
								label="Save"
								icon="pi pi-save"
								[disabled]="hasRunSimulation || addToMyUseCaseLoading"
								(onClick)="saveSimulation()"
							></p-button>
						</div>
					</div>
				</div>
				<app-simulation-enterprise-context *ngIf="filterIndex === 1"></app-simulation-enterprise-context>
			</div>
			<div *ngIf="loaded && simulation" class="col-12 col-content">
				<div class="box-simulation-item">
					<div class="flex flex-column justify-content-start gap-1">
						<div class="flex justify-content-between align-items-center">
							<span class="text-primary">Set parameters</span>
							<p-button
								[icon]="showParameters ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
								styleClass="p-button-text"
								(onClick)="toggleParameters()"
							></p-button>
						</div>
						<div *ngIf="showParameters" class="flex flex-wrap">
							<div class="col-4 px-1 pt-1">
								<div class="flex flex-column gap-3">
									<div class="flex flex-column justify-content-start align-items-start gap-2">
										<label for="aiEnablers" class="font-bold label-filter-details"
											>AI Enablers (*)</label
										>
										<p-multiSelect
											class="element-filter"
											styleClass="filter-content min-token"
											[(ngModel)]="aiEnablersTreeSelections"
											[options]="simulationService.aiEnablersTreeOptions"
											display="chip"
											id="aiEnablers"
											inputId="aiEnablers"
											placeholder="Select AI Enabler"
											appendTo="body"
											[filter]="false"
											[showHeader]="false"
										></p-multiSelect>
									</div>
									<div class="flex flex-column justify-content-start align-items-start gap-2">
										<label for="benefits" class="font-bold label-filter-details"
											>Business Value Drivers (*)</label
										>
										<p-multiSelect
											class="element-filter"
											styleClass="filter-content min-token"
											[(ngModel)]="businessValueDriversTreeSelections"
											[options]="simulationService.businessValueDriversTreeOptions"
											display="chip"
											id="benefits"
											inputId="benefits"
											placeholder="Select benefit"
											appendTo="body"
											[filter]="false"
											[showHeader]="false"
										></p-multiSelect>
									</div>
								</div>
							</div>
							<div class="col-4 px-3 pt-1">
								<div class="flex flex-column gap-3">
									<div class="flex flex-column justify-content-start align-items-start gap-2">
										<label for="technology" class="font-bold label-filter-details"
											>Technology (*)</label
										>
										<p-autoComplete
											class="element-filter"
											styleClass="filter-content min-token"
											panelStyleClass="w-full"
											inputStyleClass="w-full"
											[(ngModel)]="technologyTreeSelections"
											[suggestions]="simulationService.suggestionsTechnology"
											(completeMethod)="simulationService.searchTechnology($event)"
											field="label"
											placeholder="Select technology"
											appendTo="body"
											id="technology"
											inputId="technology"
											[multiple]="true"
										></p-autoComplete>
									</div>
									<div class="flex flex-column justify-content-start align-items-start gap-2">
										<label for="impact" class="font-bold label-filter-details">Scope (*)</label>
										<p-treeSelect
											class="element-filter"
											containerStyleClass="filter-content min-token"
											[(ngModel)]="scopeTreeSelections"
											[options]="simulationService.scopeTreeOptions"
											id="impact"
											inputId="impact"
											placeholder="Select scope"
											[metaKeySelection]="false"
											appendTo="body"
										></p-treeSelect>
									</div>
								</div>
							</div>
							<div class="col-4 px-1 pt-1">
								<span class="font-bold label-filter-details">
									Number of Use Cases by Impact Category
								</span>
								<div class="flex flex-column gap-3 pt-4">
									<div class="flex gap-2">
										<p-checkbox
											[binary]="true"
											[(ngModel)]="impact.breakthrough"
											inputId="breakthrough"
											id="breakthrough"
										></p-checkbox>
										<label for="breakthrough">Breakthrough</label>
									</div>
									<div
										*ngIf="impact.breakthrough"
										class="flex justify-content-between align-items-end gap-3"
									>
										<ngx-slider
											[(ngModel)]="simulation.simulationContext.impact.breakthrough"
											[options]="simulationService.impactOptions"
										></ngx-slider>
										<span class="font-bold">
											{{ simulation.simulationContext.impact.breakthrough }}
										</span>
									</div>
								</div>
								<div class="flex flex-column gap-3 pt-4">
									<div class="flex gap-2">
										<p-checkbox
											[binary]="true"
											[(ngModel)]="impact.transformative"
											inputId="transformative"
											id="transformative"
										></p-checkbox>
										<label for="transformative">Transformative</label>
									</div>
									<div
										*ngIf="impact.transformative"
										class="flex justify-content-between align-items-end gap-3"
									>
										<ngx-slider
											[(ngModel)]="simulation.simulationContext.impact.transformative"
											[options]="simulationService.impactOptions"
										></ngx-slider>
										<span class="font-bold">
											{{ simulation.simulationContext.impact.transformative }}
										</span>
									</div>
								</div>
								<div class="flex flex-column gap-3 pt-4">
									<div class="flex gap-2">
										<p-checkbox
											[binary]="true"
											[(ngModel)]="impact.incremental"
											inputId="incremental"
											id="incremental"
										></p-checkbox>
										<label for="incremental">Incremental</label>
									</div>
									<div
										*ngIf="impact.incremental"
										class="flex justify-content-between align-items-end gap-3"
									>
										<ngx-slider
											[(ngModel)]="simulation.simulationContext.impact.incremental"
											[options]="simulationService.impactOptions"
										></ngx-slider>
										<span class="font-bold">
											{{ simulation.simulationContext.impact.incremental }}
										</span>
									</div>
								</div>
							</div>
							<div class="col-6 p-1">
								<div class="box-simulation-item-content">
									<div class="flex justify-content-between align-content-center">
										<p class="font-bold mb-1">Description</p>
										<div class="flex justify-content-end">
											<p-button
												*ngIf="!contentEditable[0]"
												icon="pi pi-pencil"
												styleClass="p-button-text"
												(onClick)="editContentEditable(0)"
											></p-button>
											<p-button
												*ngIf="contentEditable[0]"
												icon="pi pi-save"
												styleClass="p-button-text"
												(onClick)="saveContentEditable(0)"
											></p-button>
											<p-button
												*ngIf="contentEditable[0]"
												icon="pi pi-times"
												styleClass="p-button-text"
												(onClick)="closeContentEditable(0)"
											></p-button>
										</div>
									</div>
									<div
										class="content-editable ml-1"
										[attr.id]="'content-' + 0"
										[innerHTML]="content[0]"
									></div>
								</div>
							</div>
							<div class="col-6 p-1">
								<div class="box-simulation-item-content">
									<div class="flex justify-content-between align-content-center">
										<p class="font-bold mb-1">Additional Notes</p>
										<div class="flex justify-content-end">
											<p-button
												*ngIf="!contentEditable[1]"
												icon="pi pi-pencil"
												styleClass="p-button-text"
												(onClick)="editContentEditable(1)"
											></p-button>
											<p-button
												*ngIf="contentEditable[1]"
												icon="pi pi-save"
												styleClass="p-button-text"
												(onClick)="saveContentEditable(1)"
											></p-button>
											<p-button
												*ngIf="contentEditable[1]"
												icon="pi pi-times"
												styleClass="p-button-text"
												(onClick)="closeContentEditable(1)"
											></p-button>
										</div>
									</div>
									<div
										class="content-editable ml-1"
										[attr.id]="'content-' + 1"
										[innerHTML]="content[1]"
									></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="!hasSimulation" class="box-simulation-item mt-3">
					<div class="flex flex-column justify-content-start gap-1">
						<div class="flex justify-content-between align-items-center">
							<span class="text-primary">Use Cases detail/overview</span>
							<div class="flex justify-content-end gap-2">
								<p-button
									label="Add to my use cases"
									icon="pi pi-plus-circle"
									styleClass="disabled"
								></p-button>

								<div class="w-2rem"></div>
							</div>
						</div>
						<div
							*ngIf="showUseCases"
							class="flex justify-content-center align-items-center h-15rem text-color-normal"
						>
							<span class="text-empty">Set the parameters and run a simulation to see the use cases</span>
						</div>
					</div>
				</div>
				<div *ngIf="hasSimulation" class="box-simulation-item mt-3">
					<div class="flex flex-column justify-content-start gap-1">
						<div class="flex justify-content-between align-items-center">
							<span class="text-primary">Use Cases detail/overview</span>
							<div class="flex justify-content-end gap-2">
								<p-button
									*ngIf="currentUseCase && !currentUseCase.deployedId"
									label="Add to my use cases"
									icon="pi pi-plus-circle"
									(onClick)="addToMyUseCase()"
									[loading]="addToMyUseCaseLoading"
								></p-button>

								<p-button
									*ngIf="currentUseCase && currentUseCase.deployedId"
									label="Go to my use cases"
									icon="pi pi-file"
									(onClick)="goToMyUseCase()"
								></p-button>

								<p-button
									[icon]="showUseCases ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
									styleClass="p-button-text"
									(onClick)="toggleUseCases()"
								></p-button>
							</div>
						</div>
						<div *ngIf="showUseCases" class="flex flex-wrap">
							<div class="col-3 px-1 text-size-normal">
								<div class="box-simulation-item-user-case-list overflow-auto">
									<div class="box-simulation-item-user-case-list-item header">AI Use Case Name</div>
									<ng-template ngFor let-useCase let-i="index" [ngForOf]="simulation.use_cases">
										<div
											class="box-simulation-item-user-case-list-item cursor-pointer"
											[ngClass]="{ active: i === currentUseCaseIndex }"
											(click)="selectUseCase(i)"
										>
											{{ useCase.name }}
										</div>
									</ng-template>
								</div>
							</div>
							<div class="col-9">
								<div class="flex flex-wrap">
									<div class="col-5 box-simulation-item-use-case-text">
										<p class="text-title font-semibold">{{ currentUseCase?.name }}</p>
										<p class="text-color-normal">
											{{ currentUseCase?.description }}
										</p>
									</div>
									<div class="col-7 box-simulation-item-use-case-text">
										<div class="flex flex-wrap">
											<ng-template
												ngFor
												let-keyFeature
												[ngForOf]="currentUseCase?.keyFeatures || []"
											>
												<div class="col-4 py-0">
													<p class="font-bold text-title mb-2">{{ keyFeature.name }}</p>
													<p class="font-bold text-primary mb-2">
														{{ keyFeature.help }}
													</p>
													<p class="text-color-normal">
														{{ keyFeature.description }}
													</p>
												</div>
											</ng-template>
										</div>
									</div>
									<div class="col-5">
										<div class="flex flex-column justify-content-between gap-3">
											<div class="flex flex-wrap gap-1">
												<ng-template
													ngFor
													let-impact
													[ngForOf]="currentUseCase?.context?.impact || []"
												>
													<div class="chip">#{{ impact }}</div>
												</ng-template>
											</div>
											<div class="flex flex-column justify-content-between gap-2">
												<span class="font-bold">Tools</span>
												<div class="flex flex-wrap justify-content-start gap-1">
													<ng-template
														ngFor
														let-tool
														[ngForOf]="currentUseCase?.context?.tools || []"
													>
														<div class="chip">{{ tool }}</div>
													</ng-template>
												</div>
											</div>
										</div>
									</div>
									<div class="col-7 px-5">
										<div class="flex flex-column justify-content-end gap-2 h-full w-fit">
											<span class="font-bold">AI Enablers</span>
											<div
												class="flex justify-content-start gap-4 mt-1 box-simulation-item-use-case-ai-enablers"
											>
												<ng-template
													ngFor
													let-aiEnabler
													let-i="index"
													[ngForOf]="simulationService.aiEnablers"
												>
													<div
														class="flex flex-column align-content-end justify-content-center text-center"
													>
														<i
															*ngIf="aiEnabler.svg"
															class="icon-tooltip technology"
															style="width: 100%"
															[ngClass]="{
															active: [2,3,5].includes(i),
														}"
															[innerHTML]="aiEnabler.svg"
														></i>
														<span class="text-sm">{{ aiEnabler.short }}</span>
													</div>
												</ng-template>
												<div *ngIf="!simulationService.aiEnablers.length">
													<span> --- </span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="loaded && !simulation" class="col-12 col-content">
				<div class="flex justify-content-center align-items-center h-25rem text-color-normal">
					<div class="flex flex-column gap-3 align-content-center justify-content-center text-center">
						<div class="w-full text-center">
							<img src="assets/rtp/img/simulations-empty.png" alt="" class="w-5rem" />
						</div>
						<span class="text-lg text-primary">No process selected</span>
						<span>Select your first process to start a simulation</span>
					</div>
				</div>
			</div>
			<div *ngIf="!loaded" class="col-12 col-content">
				<div class="flex justify-content-center align-items-center h-25rem text-color-normal">
					<i class="pi pi-spin pi-spinner text-primary" style="font-size: 3.5rem"></i>
				</div>
			</div>
		</div>
	</div>
</div>

<p-dialog
	header="Hackett SME Prompt"
	[(visible)]="showDialog"
	[style]="{ width: '50vw' }"
	[modal]="true"
	[draggable]="false"
	[resizable]="false"
	styleClass="simulation-sme-prompt-modal"
>
	<p>
		You're a market research analyst and an AI expert, looking information from potential customers to provide
		insights during pre-sales cycle, related to recommending relevant AI Use Cases in accordance to a previously
		conducted market research given a company name.
	</p>
	<p>
		Given a company name, a scope defined as a list of end-to-end processes or functions, and a set of additional
		instructions, generate a list of relevant AI use cases.
	</p>
	<p>
		Identify the industry and company size based on the given company name. Search for recent news, press releases,
		and earnings call transcripts for the company, focusing on information from the last 3 months with respect to
		the date you were trained for the last time. Include KPIs such as total employees, annual revenue, YoY growth.
		Summarize the gathered public information into a concise paragraph highlighting key points. Include footnotes
		and links to external references to the information obtained.
	</p>
	<p>
		Browse the company's website and identify the key product and services within their offering. Additionally,
		identify key segments and channels related to each offering (product or service). Rank product and services in
		terms of descending revenue.
	</p>
	<p>
		Conduct a market analysis to list the competitive landscape. Compare the company with its competitors in terms
		of market share (by segment/channel), innovation, financial performance, and other relevant competencies.
		Summarize the findings into a concise overview. Include footnotes and links to external references to the
		information obtained.
	</p>

	<p>
		Identify AI Use Cases relevant to the company grounded in specific product/services, or segments/channels, or
		backoffice operations. Avoid recommending use cases related to R&D. When listing AI Use Cases for Supply Chain,
		Marketing, Sales, and Operations, be as specific as possible in terms which product or services, or channel /
		segment would the AI Use Case related to when applicable. When listing AI Use Case related to backoffice
		functions such as Finance, HR, IT, and Procurement focus on the value to the business as a whole. or each
		identified use case, determine which AI Enablers apply from the following categories: Cognitive AI, Conversation AI,
		Predictive AI, Workflow AI, Content AI, Insight AI. Use the provided AI Enablers
		Definitions to ensure accurate classification. Assess the impact based on the provided impact definitions for
		"Incremental", "Transformative", "Breakthrough". Identify the benefits according to the provided business value
		drivers. Provide a benefits assessment justification for each use case. Do not list the definitions of the AI
		Enablers, Impacts nor Benefits.
	</p>

	<p>Follow these steps:</p>
	<ol>
		<li>Identify AI Opportunities</li>
		<li>Identify AI Use Cases</li>
		<li>Refine list of AI Use Cases by avoiding overlaps or too similar AI Use Cases</li>
	</ol>
	<p>
		Output a table with the following columns: AI Opportunity Name, AI Opportunity Description, AI Use Case Name, AI
		Use Case Description, AI Enablers, Impact Category, Industry Specific commentary, Benefits assessment
		justification, (Optional) Vendor specific comments (tools/technology mapping if a specific Technology Vendor is
		specified).
	</p>
</p-dialog>
