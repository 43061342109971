<div class="grid">

	<!-- Hackett Taxonomy and Use Cases by Process -->
	<div class="col-12 md:col-12 flex flex-column gap-2">
		<div class="flex justify-content-between">
			<!-- Opportunities -->
			<div class="col-12 md:col-2 py-0">
				<div class="taxonomy-title d-flex align-items-center">
					<span>Opportunities</span>
				</div>
				<div class="grid card-grid">
					<div class="col-6 pb-0 pr-0">
						<mat-card class="small-card" title="Gen AI Opportunities">
							<div class="card-header-blue"></div>
							<span class="text-primary title-opportunities pt-2 pl-2">Gen AI</span>
							<span class="title-opportunities text-gray-600 pl-2">Opps</span>
							<span #aiOpportunitiesCountUp
								class="justify-content-center align-items-center text-center number-impact pt-2">
								{{ simulationService.counters.genAiOpportunities }}
							</span>
						</mat-card>
					</div>
					<div class="col-6 pb-0 pr-0">
						<mat-card class="small-card" title="Embedded Opportunities">
							<div class="card-header-blue"></div>
							<span class="text-primary title-opportunities pt-2 pl-2">Embedded</span>
							<span class="title-opportunities text-gray-600 pl-2">Opps</span>
							<span #embeddedOpportunitiesCountUp
								class="justify-content-center align-items-center text-center number-impact pt-2">
								{{ simulationService.counters.embeddedAI }}
							</span>
						</mat-card>
					</div>
				</div>
			</div>

			<!-- Hackett Taxonomy -->
			<div class="col-12 md:col-5 py-0 pl-5 px-5">
				<div class="taxonomy-title d-flex align-items-center pl-4">
					<app-hackett-logo></app-hackett-logo>
					<span class="taxonomy-company ms-2">Hackett Digital World Class®</span>
					<span> Taxonomy</span>
				</div>
				<div class="grid text-center card-grid">
					<div class="col-4 pb-0 pr-0 pl-5">
						<mat-card class="small-card" title="# of E2E">
							<div class="card-header-blue"></div>
							<mat-card-header class="text-center justify-content-center">
								<mat-card-title class="mat-card-title"># of E2E</mat-card-title>
							</mat-card-header>
							<mat-card-content class="mat-card-content">
								<span #e2eCountUp>{{ simulationService.counters.e2e }}</span>
							</mat-card-content>
						</mat-card>
					</div>
					<div class="col-4 pb-0 pr-0 pl-3">
						<mat-card class="small-card" title="# of Process">
							<div class="card-header-blue"></div>
							<mat-card-header class="text-center justify-content-center">
								<mat-card-title class="mat-card-title"># of Process</mat-card-title>
							</mat-card-header>
							<mat-card-content class="mat-card-content">
								<span #processCountUp>{{ simulationService.counters.process }}</span>
							</mat-card-content>
						</mat-card>
					</div>
					<div class="col-4 pb-0 pl-3 divider">
						<mat-card class="small-card" title="# of Subprocess">
							<div class="card-header-blue"></div>
							<mat-card-header class="text-center justify-content-center">
								<mat-card-title class="mat-card-title"># of Subprocess</mat-card-title>
							</mat-card-header>
							<mat-card-content class="mat-card-content">
								<span #subProcessCountUp>{{ simulationService.counters.subProcess }}</span>
							</mat-card-content>
						</mat-card>
					</div>
				</div>
			</div>

			<!-- Use Cases by Process -->
			<div class="col-12 md:col-5 py-0 pl-5">
				<div class="taxonomy-title pl-5">Use Cases by Process</div>
				<div class="grid text-center card-grid pl-5">
					<div class="col-4 pb-0 pr-0">
						<mat-card class="small-card" title="# E2E">
							<div class="card-header-blue"></div>
							<mat-card-header class="text-center justify-content-center">
								<mat-card-title class="mat-card-title"># of E2E</mat-card-title>
							</mat-card-header>
							<mat-card-content class="mat-card-content">
								<span #e2eSourceRelCountUp>{{ simulationService.counters.e2eSourceRel }}</span>
							</mat-card-content>
						</mat-card>
					</div>
					<div class="col-4 pb-0">
						<mat-card class="small-card" title="# Process">
							<div class="card-header-blue"></div>
							<mat-card-header class="text-center justify-content-center">
								<mat-card-title class="mat-card-title"># of Process</mat-card-title>
							</mat-card-header>
							<mat-card-content class="mat-card-content">
								<span #processSourceRelCountUp>{{ simulationService.counters.processSourceRel }}</span>
							</mat-card-content>
						</mat-card>
					</div>
					<div class="col-4 pb-0 pl-0">
						<mat-card class="small-card" title="# of Subprocess">
							<div class="card-header-blue"></div>
							<mat-card-header class="text-center justify-content-center">
								<mat-card-title class="mat-card-title"># of Subprocess</mat-card-title>
							</mat-card-header>
							<mat-card-content class="mat-card-content">
								<span #subProcessSourceRelCountUp>{{
									simulationService.counters.subProcessSourceRel
								}}</span>
							</mat-card-content>
						</mat-card>
					</div>
				</div>
			</div>
		</div>

		<!-- Breakthrough, Transformative, Incremental -->
		<div class="flex justify-content-between">
			<!-- First Card: Breakthrough (Compact Version) -->
			<div class="col-12 md:col-2 pb-0 pr-0">
				<mat-card class="small-card breakthrough-card" title="Gen AI Use Cases Total">
					<div class="card-header-blue"></div>
					<span class="text-primary title-genai pt-3 pl-2">Gen AI</span>
					<span class="title-genai text-gray-600 pl-2">Use Cases </span>
					<span class="title-genai text-gray-600 pl-2">Total</span>
					<span #genAiUseCasesCountUp
						class="justify-content-center align-items-center text-center number-impact pt-5">
						{{ simulationService.counters.genAiUseCases }}
					</span>
				</mat-card>
			</div>

			<!-- Remaining Cards -->
			<div class="col-12 md:col-10 pb-0 pl-5 pr-2 pt-0">
				<div class="flex justify-content-between gap-0 pl-2">
					<!-- Second Card: Breakthrough (Full Version) -->
					<div class="col-12 md:col-4 pb-0 px-2">
						<mat-card class="small-card breakthrough-card" title="Breakthrough">
							<div class="card-header-blue"></div>
							<div class="px-3 pt-3 pb-3">
								<div class="text-number-row">
									<span class="text-primary text-impact">Breakthrough</span>
									<span class="number-impact"
										#breakthroughCountUp>{{ simulationService.counters.breakthrough }}</span>
								</div>
								<p class="description pt-3">
									Exponential advancements that result in new business models, revenue streams, and
									innovation.
								</p>
							</div>

						</mat-card>
					</div>

					<!-- Third Card: Transformative -->
					<div class="col-12 md:col-4 pb-0 px-2">
						<mat-card class="small-card transformative-card" title="Transformative">
							<div class="card-header-blue"></div>
							<div class="px-3 pt-3 pb-3">
								<div class="text-number-row">
									<span class="text-primary text-impact">Transformative</span>
									<span class="font-bold number-impact"
										#transformativeCountUp>{{ simulationService.counters.transformative }}</span>
								</div>
								<p class="description pt-3">
									Ability to drive profound organizational changes which drive operational excellence.
								</p>
							</div>
						</mat-card>
					</div>

					<!-- Fourth Card: Incremental -->
					<div class="col-12 md:col-4 pb-0 px-2">
						<mat-card class="small-card incremental-card" title="Incremental">
							<div class="card-header-blue"></div>
							<div class="px-3 pt-3 pb-3">
								<div class="text-number-row">
									<span class="text-primary text-impact">Incremental</span>
									<span class="font-bold number-impact"
										#incrementalCountUp>{{ simulationService.counters.incremental }}</span>
								</div>
								<p class="description pt-3">
									Innovations that involve targeted enhancements to existing product, processes, and
									services.
								</p>
							</div>
						</mat-card>
					</div>
				</div>
			</div>

		</div>

	</div>
</div>
