<p-toast></p-toast>
<ng-template [ngIf]="!showOnlyFileInput">
	<div class="p-field">
		<label for="name">Attachment Name</label>
		<div class="w-full pt-2">
			<input
				class="w-full"
				id="name"
				pInputText
				[formControl]="name"
				placeholder="Enter attachment name"
			/>
		</div>
	</div>
	<div class="p-field pt-3">
		<label for="description">Description</label>
		<div class="w-full pt-2">
        <textarea
			id="description"
			pInputTextarea
			[formControl]="description"
			placeholder="Enter description"
			class="w-full"
		></textarea>
		</div>
	</div>
</ng-template>
<div class="p-field pt-3">
	<label for="description">Attachment</label>
	<div class="w-full pt-2">
		<p-fileUpload
			class="w-full attachment-file-upload"
			[multiple]="true"
			[showCancelButton]="false"
			[showUploadButton]="false"
			(onSelect)="onFileSelect($event)"
			chooseLabel="Select Files"
		>
			<ng-template pTemplate="content">
				<ng-template [ngIf]="files.length" [ngIfElse]="empty">
					<ng-container *ngFor="let file of files; let i = index">
						<div class="card m-0 mt-1 p-1 flex flex-row justify-content-between gap-1">
							<span>
								<div class="text-sm">{{ file.name }}</div>
								<div class="text-xs text-600">{{ formatSize(file.size) }}</div>
							</span>
							<p-button icon="pi pi-times" styleClass="danger" />
						</div>
					</ng-container>
				</ng-template>
				<ng-template #empty>
					<div class="flex align-items-center justify-content-center flex-column">
						<p class="mt-4 mb-4">Drag and drop files here to upload.</p>
					</div>
				</ng-template>
			</ng-template>
			<ng-template pTemplate="file">
			</ng-template>
		</p-fileUpload>
	</div>
</div>

<ng-template [ngIf]="previousFiles.length">
	<ng-container *ngFor="let file of previousFiles; let i = index">
		<div class="card m-0 mt-1 p-1 flex flex-row justify-content-between gap-1">
			<span>
				<a class="text-sm text-primary" [href]="file?.url" target="_blank">{{ file?.name }}</a>
				<div class="text-xs text-600">{{ file?.size }}</div>
			</span>
			<p-button icon="pi pi-times" styleClass="danger" (click)="removeFile(i)" />
		</div>
	</ng-container>
</ng-template>

<div class="p-field pt-3 text-right">
	<p-button
		*ngIf="files.length > 0 || previousFiles.length > 0"
		label="Save Attachment"
		icon="pi pi-save"
		(click)="submitAttachment()"
		[loading]="isSaving"
	></p-button>
  <p-button
	  *ngIf="files.length === 0 && previousFiles.length === 0"
	  label="Close"
	  icon="pi pi-times"
	  (click)="closeModal()"
  ></p-button>
</div>
