<p-accordion [activeIndex]="isOpen ? 0 : -1" class="accordion mt-2 background-color-gris">
	<p-accordionTab header="Filter Options">
		<div class="grid grid pb-2 background-color-gris">
			<!-- Functions Section -->
			<div class="col-4 lg:col-4 xl:col-4 pb-3">
				<div class="flex flex-column gap-2">
					<div class="flex flex-column justify-content-start align-items-start w-full">
						<label class="font-bold label-filter w-full pb-1">Functions</label>
						<div class="flex gap-1 justify-content-start w-full">
							<p-dropdown
								class="w-full"
								styleClass="filter-content w-full"
								[options]="simulationService.addProcessTaxonomyTreeSelect"
								[(ngModel)]="selectedTaxonomy"
								optionLabel="label"
								optionValue="key"
								id="taxonomy"
								inputId="taxonomy"
								placeholder="Select Functions"
								appendTo="body"
								(onChange)="updateSelectedFilters()"
							></p-dropdown>

							<p-button
								*ngIf="selectedTaxonomy"
								icon="pi pi-times"
								styleClass="p-button-rounded p-button-text"
								(onClick)="removeFilter()"
							></p-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</p-accordionTab>
</p-accordion>
