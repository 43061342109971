import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-complexity-icon',
	template: `
		<ng-template [ngIf]="complexity === 3">
			<svg width="16" height="18" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g id="icon/high">
					<path
						id="Vector (Stroke)"
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M1 9C1.55228 9 2 9.52233 2 10.1667V14.8333C2 15.4777 1.55228 16 1 16C0.447715 16 0 15.4777 0 14.8333V10.1667C0 9.52233 0.447715 9 1 9Z"
						fill="#CC3333"
					/>
					<path
						id="Vector (Stroke)_2"
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M7 5C7.55228 5 8 5.49249 8 6.1V14.9C8 15.5075 7.55228 16 7 16C6.44771 16 6 15.5075 6 14.9V6.1C6 5.49249 6.44771 5 7 5Z"
						fill="#CC3333"
					/>
					<path
						id="Vector (Stroke)_3"
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M13 0C13.5523 0 14 0.511675 14 1.14286V14.8571C14 15.4883 13.5523 16 13 16C12.4477 16 12 15.4883 12 14.8571V1.14286C12 0.511675 12.4477 0 13 0Z"
						fill="#CC3333"
					/>
				</g>
			</svg>
		</ng-template>
		<ng-template [ngIf]="complexity === 2">
			<svg width="16" height="18" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g id="icon/medium">
					<path
						id="Vector (Stroke)"
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M1.45801 9C2.01029 9 2.45801 9.52233 2.45801 10.1667V14.8333C2.45801 15.4777 2.01029 16 1.45801 16C0.905723 16 0.458008 15.4777 0.458008 14.8333V10.1667C0.458008 9.52233 0.905723 9 1.45801 9Z"
						fill="#FF9900"
					/>
					<path
						id="Vector (Stroke)_2"
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M7.45801 5C8.01029 5 8.45801 5.49249 8.45801 6.1V14.9C8.45801 15.5075 8.01029 16 7.45801 16C6.90572 16 6.45801 15.5075 6.45801 14.9V6.1C6.45801 5.49249 6.90572 5 7.45801 5Z"
						fill="#FF9900"
					/>
					<path
						id="Vector (Stroke)_3"
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M13.458 0C14.0103 0 14.458 0.511675 14.458 1.14286V14.8571C14.458 15.4883 14.0103 16 13.458 16C12.9057 16 12.458 15.4883 12.458 14.8571V1.14286C12.458 0.511675 12.9057 0 13.458 0Z"
						fill="#CECFD3"
					/>
				</g>
			</svg>
		</ng-template>
		<ng-template [ngIf]="complexity === 1 || complexity === undefined">
			<svg width="16" height="18" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g id="icon/low">
					<path
						id="Vector (Stroke)"
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M1.45801 9C2.01029 9 2.45801 9.52233 2.45801 10.1667V14.8333C2.45801 15.4777 2.01029 16 1.45801 16C0.905723 16 0.458008 15.4777 0.458008 14.8333V10.1667C0.458008 9.52233 0.905723 9 1.45801 9Z"
						fill="#7FAA3A"
					/>
					<path
						id="Vector (Stroke)_2"
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M7.45801 5C8.01029 5 8.45801 5.49249 8.45801 6.1V14.9C8.45801 15.5075 8.01029 16 7.45801 16C6.90572 16 6.45801 15.5075 6.45801 14.9V6.1C6.45801 5.49249 6.90572 5 7.45801 5Z"
						fill="#CECFD3"
					/>
					<path
						id="Vector (Stroke)_3"
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M13.458 0C14.0103 0 14.458 0.511675 14.458 1.14286V14.8571C14.458 15.4883 14.0103 16 13.458 16C12.9057 16 12.458 15.4883 12.458 14.8571V1.14286C12.458 0.511675 12.9057 0 13.458 0Z"
						fill="#CECFD3"
					/>
				</g>
			</svg>
		</ng-template>
	`,
	styles: [],
})
export class ComplexityIconComponent {
	@Input() complexity: number | undefined;
}
