import { Injectable } from '@angular/core';
import PizZipUtils from 'pizzip/utils';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { saveAs } from 'file-saver';

@Injectable({
	providedIn: 'root',
})
export class PdfService {

	constructor(private http: HttpClient) {
	}

	private loadFile(url: string): Promise<string> {
		return new Promise((resolve, reject) => {
			PizZipUtils.getBinaryContent(url, (err, data) => {
				if (err) {
					reject(err);  // Reject the promise if there's an error
				} else {
					resolve(data);  // Resolve the promise with the data
				}
			});
		});
	}

	public async generateWordBlob(docxTemplateUrl: string, docxData: Record<string, unknown>): Promise<Blob> {
		const content = await this.loadFile(docxTemplateUrl);

		const zip = new PizZip(content);
		const doc = new Docxtemplater(zip, {
			delimiters: { start: '[[', end: ']]' },
			paragraphLoop: true,
			linebreaks: true,
		});

		doc.render(docxData);

		return doc.getZip().generate({
			type: 'blob',
			mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		});
	}

	async downLoadWord(docxTemplateUrl: string, docxData: Record<string, unknown>) {
		const blobWord = await this.generateWordBlob(docxTemplateUrl, docxData);
		const now = new Date();
		const timestamp = `${(now.getMonth() + 1).toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}${now.getFullYear()}T${now.getHours().toString().padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}`;
		const fileName = `${docxData['useCaseDescription']}_Scope_of_POC_Customer_Profile_Adherence_LWH_reviewed_${timestamp}.docx`;

		saveAs(blobWord, fileName);
	}

	async generatePdfBased64(docxTemplateUrl: string, docxData: Record<string, unknown>): Promise<string | undefined> {
		try {
			const blobWord = await this.generateWordBlob(docxTemplateUrl, docxData);
			const formData = new FormData();
			formData.append('file', blobWord, 'generated.docx');

			const response = await lastValueFrom(this.http.post<{ pdf_base64: string }>('@api/word-to-pdf/', formData));

			return response?.pdf_base64;
		} catch (error) {
			console.error('Error generating PDF:', error);
			return Promise.reject(error);
		}
	}

}
